import newApiClientController from "./newApiClientModal.controller";
import swal from "sweetalert2";

export default /*@ngInject*/ function ($http, $uibModal, $scope, dataService, apiClientDataService, helperService) {
    const vm = this;

    vm.openApiClientModal = openApiClientModal;

    vm.apiClients = [];
    vm.loadRoles = loadRoles;
    vm.changeApiClientRowSelected = changeApiClientRowSelected;
    vm.generateSecret = generateSecret;
    vm.changeInputType = changeInputType;
    vm.listApiClients = listApiClients;
    vm.deleteApiClient = deleteApiClient;
    vm.updateSecretStatus = updateSecretStatus;
    vm.deleteSecret = deleteSecret;
    vm.copyTextToClipboard = copyTextToClipboard;

    vm.apiClientMenuOptions = [
        {
            text: 'Edit',
            click: function ($itemScope, $event, modelValue) {
                const apiClient = modelValue;
                vm.openApiClientModal(apiClient, false);
            },
            displayed: function (modelValue) {
                return true;
            }
        },
        {
            text: '<span class="text-danger">Delete</span>',
            click: function ($itemScope, $event, modelValue) {
                const apiClient = modelValue;
                vm.deleteApiClient(apiClient);
            },
            displayed: function (modelValue) {
                return true;
            }
        }
    ];

    activate();

    async function activate() {
        loadRoles();
        await listApiClients();
    }

    async function getApiClient() {
        vm.loadingApiClient = true;
        let response = await apiClientDataService.getApiClient(vm.selectedApiClient.AdmUserId);

        vm.selectedApiClient = response.data;
        vm.selectedApiClient.SelectedRoles = response.data.Roles;

        for (var i = 0; i < vm.selectedApiClient.Secrets.length; i++) {
            const dateString = Date.now().toString(36);
            const randomNumber = Math.random().toString(36).substr(2);
            const inputTag = "inp" + dateString + randomNumber;
            vm.selectedApiClient.Secrets[i].InputTag = inputTag;
        }

        vm.originalClientStatusIsActive = response.data.Active;

        vm.loadingApiClient = false;
    }

    async function deleteApiClient(apiClient) {
        try {
            await swal(helperService.areYouSureParams('Delete API Client', 'Are you sure you want to delete ' + apiClient.Name + '?', 'Delete'));
            let response = await apiClientDataService.deleteApiClient(apiClient.AdmUserId);
            vm.selectedApiClient = null;
            listApiClients();
        } catch (response) {
            helperService.showErrorMessage(response.data);
        }
    }

    function changeInputType(dataItem) {
        let inputControl = document.getElementById(dataItem.InputTag);
        let imageControl = document.getElementById("img" + dataItem.InputTag);
        if (inputControl.type === "text") {
            inputControl.setAttribute("type", "password");
            imageControl.className = "fa fa-eye"
        }

        else {
            inputControl.setAttribute("type", "text");
            imageControl.className = "fa fa-eye-slash"
        }
    }

    async function listApiClients(mostRecentApiClientId) {
        if (vm.apiClients) { vm.apiClients = null; }
        vm.loadingApiClients = true;
        try {
            let response = await apiClientDataService.listApiClients();
            if (mostRecentApiClientId) {
                let mostRecentApiClient = response.data.find(r => r.AdmUserId === mostRecentApiClientId);
                response.data = response.data.filter(i => i.AdmUserId !== mostRecentApiClient.AdmUserId);
                response.data.unshift(mostRecentApiClient);
                vm.changeApiClientRowSelected(mostRecentApiClient);
            }
            vm.apiClients = response.data;
            vm.loadingApiClients = false;
        } catch (_) {
            vm.loadingApiClients = false;
        }
    }

    async function loadRoles() {
        let response;

        try {
            response = await dataService.getRoles();
            vm.availableRoles = response.data;

        } catch (response) {
            helperService.showErrorMessage(response.data);
        }
    }
    async function updateSecretStatus(apiClientId, secretId, activate) {
        try {
            vm.loadingApiClient = true;
            await apiClientDataService.updateSecretStatus(apiClientId, secretId, activate);
            await getApiClient();
            vm.clientSecretsGridDataSource.read();
            vm.loadingApiClient = false;
        }
        catch (response) {
            helperService.showErrorMessage(response.data);
        }
    }

    async function generateSecret() {
        try {
            vm.loadingApiClient = true;
            await apiClientDataService.generateSecret(vm.selectedApiClient.AdmUserId);
            await getApiClient();
            vm.clientSecretsGridDataSource.read();
            vm.loadingApiClient = false;
        }
        catch (response) {
            helperService.showErrorMessage(response.data);
        }
    }

    async function deleteSecret(apiClientId, secretId) {
        try {
            vm.loadingApiClient = true;
            await apiClientDataService.deleteSecret(apiClientId, secretId);
            await getApiClient();
            vm.clientSecretsGridDataSource.read();
            vm.loadingApiClient = false;
        }
        catch (response) {
            helperService.showErrorMessage(response.data);
        }
    }


    function changeApiClientRowSelected(apiClient) {
        if (!vm.selectedApiClient || vm.selectedApiClient.AdmUserId !== apiClient.AdmUserId) {
            vm.selectedApiClient = apiClient;
            changeApiClientSelected(apiClient);
        }
    }

    async function changeApiClientSelected() {
        await getApiClient();
        loadSecretsGrid();
    }

    function copyTextToClipboard(dataItem) {
        let inputControl = document.getElementById(dataItem.InputTag);
        try {
            const el = document.createElement('input');
            el.value = inputControl.value;
            el.setAttribute('readOnly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        }
        catch (ex) {
            swal({ title: 'Error copying to clipboard', type: 'warning', showCancelButton: false });
        }
    }

    async function openApiClientModal(apiClient) {
        let apiClientModal = $uibModal.open({
            templateUrl: '/App/Shared/Partials/NewApiClientModal.html',
            controller: newApiClientController,
            controllerAs: 'apicvm',
            backdrop: 'static',
            resolve: {
                apiClient: () => apiClient || null,
            }
        });

        try {
            let result = await apiClientModal.result;
            listApiClients(result);
        } catch (_) {
            return;
        }
    }

    async function loadSecretsGrid() {
        var clientSecretsColumns = [
            { field: "CreatedDate", title: "Created Date", template: "{{ dataItem.CreatedDate | date:'short'}}", width: 125 },
            { field: "ClientSecret", title: "Secret", template: `<input id="{{dataItem.InputTag}}" class="form-control input-sm no-borders white-bg" readonly type="password" ng-model="dataItem.Secret">`, width: 300 },
            { title: "Active", template: `<div style="text-align: center;"><label class="switch"><input type="checkbox" ng-true-value="'ACTIVE'" ng-false-value="'INACTIVE'" ng-model="dataItem.Status" ng-click="apicvm.updateSecretStatus(apicvm.selectedApiClient.AdmUserId, dataItem.Id, dataItem.Status ==='ACTIVE' ? false : true)"><i></i></label></div>`, width: 90 },
            { template: `<div style="text-align: center;"><a ng-click="apicvm.changeInputType(dataItem)"><i id="img{{dataItem.InputTag}}" class="fa fa-eye"></i></a></div>`, width: 50 },
            { template: `<div style="text-align: center;"><a text-align: center ng-click="apicvm.copyTextToClipboard(dataItem)"><i class="fa fa-clone"></i></a></div>`, width: 50 },
            { template: `<a ng-click="apicvm.deleteSecret(apicvm.selectedApiClient.AdmUserId, dataItem.Id)"><i class="glyphicon glyphicon-trash text-danger m-r-xs"></i>Delete</a>`, width: 70 }
        ];

        vm.clientSecretsGridOptions = helperService.setNonPageableGridOptions(clientSecretsColumns);
        vm.clientSecretsGridOptions.autoBind = true;
        vm.clientSecretsGridOptions.noRecords = {
            template: "<div class='no-data-to-display-message'>No items to display</div>"
        };

        vm.clientSecretsGridDataSource = new kendo.data.DataSource({
            schema: {
                model: {
                    fields: {
                        CreatedDate: { type: "date" }
                    }
                }
            },
            transport: {
                read: async options => {
                    try {
                        for (var i = 0; i < vm.selectedApiClient.Secrets.length; i++) {
                            const dateString = Date.now().toString(36);
                            const randomNumber = Math.random().toString(36).substr(2);
                            const inputTag = "inp" + dateString + randomNumber;
                            vm.selectedApiClient.Secrets[i].InputTag = inputTag;
                        }
                        options.success(vm.selectedApiClient.Secrets);
                    } catch {
                        options.error();
                    }
                }
            }
        });
    }
}