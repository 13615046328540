import { customAutoCompleteFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";
import ReportSchedulerModalController from "../../Shared/Controllers/ReportSchedulerModalController";
import swal from "sweetalert2";

export default /*@ngInject*/ function ($scope, $uibModal, $rootScope, $filter, schedulesDataService, controlLibraryDataService, helperService, FileSaver) {
    const vm = this;

    vm.$onInit = activate;

    vm.openNewReportScheduleModal = openNewReportScheduleModal;
    vm.openEditScheduleModal = openEditScheduleModal;
    vm.changeScheduleRowSelected = changeScheduleRowSelected;
    vm.deleteScheduleOutput = deleteScheduleOutput;
    vm.deleteSchedule = deleteSchedule;
    vm.downloadFile = downloadFile;
    vm.checkForFileType = checkForFileType;
    vm.getFileTypeImage = getFileTypeImage;
    vm.downloadAttachment = downloadAttachment;
    vm.runSchedule = runSchedule;
    vm.securityActions = {
        newSchedule: helperService.objectExists("1402"),
        editSchedule: helperService.objectExists("1403"),
        deleteSchedule: helperService.objectExists("1405"),
        runSchedule: helperService.objectExists("1406")
    }

    function activate() {
        //Define columns for main grid
        const schedulesGridColumns = [
            { field: "SavedReportName", title: "Report Name", filterable: customAutoCompleteFilter },
            { field: "OwnerLogonName", title: "Created By", filterable: customAutoCompleteFilter },
            { field: "Frequency", title: "Occurs", filterable: customAutoCompleteFilter },
            { field: "StartTime", title: "Start", template: "{{ dataItem.StartTime | date:'short'}}", filterable: false },
            { field: "NextExecutionTime", title: "Next Execution", template: "{{ dataItem.NextExecutionTime | date:'short'}}", filterable: false },
            { field: "EndTime", title: "End", template: "{{ dataItem.EndTime | date:'short'}}", filterable: false },
            { template: `<a ng-click="svm.runSchedule(dataItem)">Run Now</a>`, "title": "Run Now", hidden: !vm.securityActions['runSchedule'] },
            { template: `<a ng-click="svm.openEditScheduleModal(dataItem.ID)"><i class="fa fa-edit text-warning m-r-xs"></i>Edit</a>`, "title": "Edit", width: 90, hidden: !vm.securityActions['editSchedule'] },
            { template: `<a ng-click="svm.deleteSchedule(dataItem.ID)"><i class="fa fa-trash-o text-danger m-r-xs"></i>Delete</a>`, "title": "Delete", width: 90, hidden: !vm.securityActions['deleteSchedule'] }
        ],
            deliveryGridColumns = [
                { field: "UserLogonName", title: "User Logon Name", filterable: customAutoCompleteFilter },
                { field: "DeliveryType", title: "Delivery Type", filterable: customAutoCompleteFilter },
                { field: "Format", filterable: customAutoCompleteFilter },
                { template: `<a ng-click="svm.deleteScheduleOutput(dataItem.ID, dataItem.ScheduleID)"><i class="fa fa-trash-o text-danger m-r-xs"></i>Delete</a>`, "title": "Delete", width: 90, hidden: !vm.securityActions['deleteSchedule'] }
            ],
            executionsGridColumns = [
                { field: "ExecutionDate", title: "Execution Date", template: "{{ dataItem.ExecutionDate | date:'short'}}", filterable: false },
                {
                    template: `<button class="btn btn-secondary m-r-xs" ng-show="svm.checkForFileType('Excel', dataItem.Files)" ng-click="svm.downloadFile('Excel', dataItem.Id)"><span class="contain-background-icon height-width-16 excel-file-icon-16 vertical-align-middle"></span></button><button class="btn btn-secondary" ng-show="svm.checkForFileType('PDF', dataItem.Files)" ng-click="svm.downloadFile('PDF', dataItem.Id)"><span class="contain-background-icon height-width-16 pdf-file-icon-16 vertical-align-middle"></span></button><button class="btn btn-secondary m-r-xs" ng-show="svm.checkForFileType('Csv', dataItem.Files)" ng-click="svm.downloadFile('Csv', dataItem.Id)"><span class="contain-background-icon height-width-16 csv-file-icon-16 vertical-align-middle"></span></button>`, width: 200, "title": "Download"
                },
                { template: `<button class="btn btn-secondary m-r-xs" ng-if="dataItem.SignatureAttachments.length" ng-repeat="attachment in dataItem.SignatureAttachments" ng-click="svm.downloadAttachment(attachment)" title="{{ attachment.FileName }}"><span class="contain-background-icon height-width-16 vertical-align-middle" ng-class="svm.getFileTypeImage(attachment.FileExtension)"></span></button>`, width: 200, "title": "Attachments" }
            ];

        vm.schedulesGridOptions = helperService.setMainGridOptions(schedulesGridColumns, 611);
        vm.deliveryMethodGridOptions = helperService.setNonPageableGridOptions(deliveryGridColumns, 200);
        vm.deliveryMethodGridOptions.selectable = false;
        vm.executionsGridOptions = helperService.setNonPageableGridOptions(executionsGridColumns, 300);
    }

    async function openNewReportScheduleModal() {
        let newScheduleModal = $uibModal.open({
            templateUrl: '/App/Shared/Controllers/ReportSchedulerModal.html',
            controller: ReportSchedulerModalController,
            backdrop: 'static',
            size: 'md',
            scope: $scope
        });

        try {
            await newScheduleModal.result;
            vm.schedulesGridDataSource.read()
        } catch {
            return;
        }
    }

    function openEditScheduleModal(selectedID) {
        $rootScope.scheduleToEdit = selectedID;

        $uibModal.open({
            templateUrl: '/App/Shared/Controllers/ReportSchedulerModal.html',
            controller: ReportSchedulerModalController,
            backdrop: 'static',
            size: 'md',
            scope: $scope
        });
    }

    async function changeScheduleRowSelected(dataItem) {
        let executions = await schedulesDataService.getReportScheduleExecutions(dataItem.ID);
        if (executions.data.length) {
            executions = $filter('orderBy')(executions.data, '-ExecutionDate');
        }

        vm.deliveryGridDataSource = new kendo.data.DataSource({
            data: dataItem.Outputs
        });

        vm.executionsGridDataSource = new kendo.data.DataSource({
            data: executions
        });
    }

    vm.schedulesGridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        transport: {
            read: async function (options) {
                let response = await schedulesDataService.getReportSchedules();
                response.data = $filter('orderBy')(response.data, 'SavedReportName');
                options.success(response.data);
            }
        }
    });

    //Delete based on ID of Schedule
    function deleteScheduleOutput(id, ScheduleID) {
        let rows = vm.deliveryGridDataSource.data();

        rows.forEach(async row => {
            if (row.ID.toString() === id.toString()) {
                if (row.ScheduleID.toString() === ScheduleID.toString()) {
                    try {
                        await schedulesDataService.deleteScheduleOutput(id, ScheduleID)
                        rows.remove(row);
                    } catch {
                        return;
                    }
                }
            }
        });
    }

    //Delete based on ID of Schedule
    async function deleteSchedule(id) {
        vm.deliveryGridDataSource = null;
        vm.executionsGridDataSource = null;
        try {
            await schedulesDataService.deleteSchedule(id);
            vm.schedulesGridDataSource.read();
        } catch {
            return;
        }
    }

    function checkForFileType(fileType, files) {
        return files.find(file => file.FileFormat === fileType);
    }

    function getFileTypeImage(fileExtension) {
        switch (fileExtension) {
            case 'xlsx': //Excel
                return 'excel-file-icon-16';
                break;
            case 'pdf': //PDF 
                return 'pdf-file-icon-16';
                break;
            case 'csv': //Csv
                return 'csv-file-icon-16';
                break;
            default: 
                return 'default-file-icon-16';
                break;
        }
    }

    async function downloadFile(fileType, executionId) {
        let response,
            fileExtension;

        vm.downloadFilePending = true;

        try {
            response = await schedulesDataService.downloadExecutionFile(fileType, executionId);

            fileExtension = fileType.toLowerCase();

            if (fileExtension === 'excel') {
                fileExtension = 'xlsx';
            }
            FileSaver.saveAs(response.data, 'Executions.' + fileExtension);
            vm.downloadFilePending = false;
        } catch {
            vm.downloadFilePending = false;
        }
    }

    async function downloadAttachment(attachment) {
        let response;
        vm.downloadPending = true;

        try {
            response = await controlLibraryDataService.downloadAttachment(attachment.Identifier);

            FileSaver.saveAs(response.data, attachment.FileName);
            vm.downloadPending = false;
        } catch {
            vm.downloadPending = false;
        }
    }

    async function runSchedule(schedule) {
        try {
            await schedulesDataService.runReportSchedule(schedule.ID);
            swal("", `The schedule for report ${schedule.SavedReportName} has been initiated. Recipients will be receiving the report soon.`, 'success');
        } catch {
            return;
        }
    }

}
