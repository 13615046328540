export default /*@ngInject*/ function ($http, $injector, errorsDataService) {
    const factory = {
        createApiClient,
        updateApiClient,
        getApiClient,
        deleteApiClient,
        listApiClients,
        updateSecretStatus,
        generateSecret,
        deleteSecret
    };

    return factory;

    async function listApiClients() {
        return await $http.get(apiUrl + 'api/core/api-clients')
            .then(workComplete)
            .catch(workFailed);   
    }

    async function getApiClient(apiClientId) {

        return await $http.get(apiUrl + 'api/core/api-clients/' + apiClientId)
            .then(workComplete)
            .catch(workFailed);
        
    }

    async function createApiClient(apiClient) {

        return await $http.post(`${apiUrl}api/core/api-clients`, apiClient)
            .then(workComplete)
            .catch(workFailed);
        
    }

    async function updateApiClient(id, apiClient) {
        return await $http.put(`${apiUrl}api/core/api-clients/` + id, apiClient)
            .then(workComplete)
            .catch(workFailed);
        
    }
    async function deleteApiClient(id) {
        return await $http.delete(apiUrl + 'api/core/api-clients/' + id)
            .then(workComplete)
            .catch(workFailed);
        
    }

    async function updateSecretStatus(apiClientId, secretId, active) {
        let routeStatus = active ? 'activate' : 'deactivate';
        return await $http.put(`${apiUrl}api/core/api-clients/${apiClientId}/secrets/${secretId}/${routeStatus}`)
            .then(workComplete)
            .catch(workFailed);        
    }

    async function generateSecret(apiClientId) {
        let payload = {};
        let response = await $http.post(apiUrl + 'api/core/api-clients/' + apiClientId + '/secrets', payload)
            .then(workComplete)
            .catch(workFailed);
    }

    async function deleteSecret(apiClientId, secretId) {
        let payload = {};
        let response = await $http.delete(apiUrl + 'api/core/api-clients/' + apiClientId + '/secrets/' + secretId, payload)
            .then(workComplete)
            .catch(workFailed);
    }

    function workComplete(response) {
        return response;
    }

    function workFailed(response) {
        $injector.get('errorsDataService').catch(response);
    }
}