import { customAutoCompleteFilter, standardGridPageSize, customDatePickerFilter } from "../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../Shared/kendo.module";

export default /*@ngInject*/ function ($scope, $rootScope, $filter, $location, SavedReportSelectorService, dataService, buildUserBPPostBody, objectIdDataService, CustomReportServices, $http, helperService, reportsDataService, reportsService, risksDataService, businessProcessDataService) {

    var vm = this;

    vm.helperService = helperService;
    vm.roleSelectedIds = [];

    // --- FOR ADVANCED FILTERING ---
    vm.filterCriteria = {};
    vm.filterCriteria.query = [];
    // ---

    vm.selectedCompanies = [];
    vm.selectedProducts = [];

    vm.populateCriteriaModel = populateCriteriaModel;
    vm.generateGridWithSelectedValues = generateGridWithSelectedValues;

    vm.companyDropdownChange = companyDropdownChange;
    vm.businessProcessDropdownChange = businessProcessDropdownChange;
    vm.productDropdownChange = productDropdownChange;
    vm.rulesetDropdownChange = rulesetDropdownChange;
    vm.updateUserDataSource = updateUserDataSource;
    vm.runSavedReport = runSavedReport;

    $scope.deleteFilter = deleteFilter;
    $scope.clearAllFilters = clearAllFilters;
    $scope.saveReportLayoutButton = saveReportLayoutButton;
    $scope.applySelectedFilter = applySelectedFilter;
    $scope.populateCriteriaModel = populateCriteriaModel;

    $scope.$on('kendoWidgetCreated', function (event, widget) {
        if (widget === $scope.grid) {
            $scope.gridReady = true;
            runSavedReport();
        }
    });

    // --- Grid initialization ---
    vm.mainGridOptions = helperService.setMainGridOptions(null, 600);
    vm.mainGridOptions.autoBind = false;
    $scope.filterManagerGridOptions = CustomReportServices.setfilterManagerGridOptions();
    $scope.filterDropdownOptions = helperService.getFilterDropdownOptions();

    activate();

    async function activate() {

        $scope.ObjectId = await objectIdDataService.getObjectID();
        await aLAPluginActive();
        $scope.filtersDataSource = CustomReportServices.setfiltersDataSource($scope.ObjectId);
        const securityObject = helperService.getSecurityObjectDetailsFromObjectId($scope.ObjectId);
        $scope.pageInfo = securityObject;
        $scope.pageDescription = securityObject.HelpLink;
        $scope.reportName = securityObject.Name;

        if ($location.path().has('Detailed')) vm.isDetailed = true;
        else vm.isDetailed = false;

        let products = await dataService.getProducts();
        products.data = products.data.filter(p => p.UserHasAccess === true);
        vm.productsDataSource = products.data;
        let defaultProduct = vm.productsDataSource.find(obj => obj.Name === $rootScope.UserPreferences.PreferredProduct);
        let defaultProductSelected = vm.productsDataSource.indexOf(defaultProduct);
        if (vm.isDetailed) {
            if ($rootScope.UserPreferences.PreferredProduct !== null) {
                vm.selectedProducts = [vm.productsDataSource[defaultProductSelected]];
            } else {
                vm.selectedProducts = [vm.productsDataSource[0]];
            }
        } else {
            if ($rootScope.UserPreferences.PreferredProduct !== null) {
                vm.selectedProducts = [vm.productsDataSource[defaultProductSelected]];
            } else {
                vm.selectedProducts = angular.copy(vm.productsDataSource);
            }
        }

        vm.selectedProducts = SavedReportSelectorService.checkSavedReportForProducts($rootScope.loadedSavedReport, products.data, vm.selectedProducts);

        if (vm.isDetailed) {
            if (vm.selectedProducts[0].IsCustom) {
                $scope.reportId = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, "customProduct").ReportID;
            } else {
                $scope.reportId = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, vm.selectedProducts[0].Type).ReportID;
            }
        } else {
            $scope.reportId = securityObject.ReportID;
        }

        await setColumns();
        await getCompanies();
        await getRulesets();
        await getBusinessProcesses();

        // --- FOR ADVANCED FILTERING ---
        await getReportMetadata();
        // ---

        runSavedReport();
    }

    function runSavedReport() {
        if ($scope.gridReady && $rootScope.loadedSavedReport && vm.productsDataSource && vm.objectDataSource && vm.companyDataSource && vm.rulesetDataSource && vm.businessProcessDataSource && vm.filterCriteria.fields) {
            vm.filterCriteria.query = reportsService.getSavedReportFilters();
            vm.filterCriteria.needsUpdate = true;
            generateGridWithSelectedValues();
        } else if ($scope.gridReady && !$rootScope.loadedSavedReport && vm.productsDataSource && vm.objectDataSource && vm.companyDataSource && vm.rulesetDataSource && vm.businessProcessDataSource && vm.filterCriteria.fields) {
            // We want the necessary report details available to be able to use the report toolbar without running the report
            $scope.populateCriteriaModel();
        }
    }

    async function aLAPluginActive() {
        let response = await $http.get(`${apiUrl}/api/core/plugins`);
        vm.aLAPluginActive = response.data.some(plugin => plugin.Id === 9 && plugin.Active);
    }

    function applySelectedFilter() {
        helperService.applyFilterCatchError($scope);
    }

    $scope.gridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        schema: angular.extend(halSchemaKendoGrid, {
            model: {
                fields: {
                    LastRun: { type: "date" },
                    UseCount: { type: "number" }
                }
            }
        }),
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {
            read: function (options) {
                delete $rootScope.loadedSavedReport;

                var data = buildUserBPPostBody.generateBody(vm.selectedCompanies, vm.selectedRuleset, vm.selectedBusinessProcesses, $scope.ObjectId, vm.selectedProducts, vm.selectedObject, vm.selectedUserIds, $scope.reportId);
                data['QueryInput'] = helperService.prepareSortsAndFilters(options);

                // --- FOR ADVANCED FILTERING ---
                if (vm.filterCriteria.query) {
                    data['QueryInput'].Filters = data['QueryInput'].Filters.concat(vm.filterCriteria.query);
                }
                // ---

                // if tabs are on, save report info for the tab
                if ($rootScope.tabs.length > 0) {
                    reportsService.saveTabReportInfo($scope);
                }

                $http({
                    method: 'POST',
                    url: vm.urlString,
                    data: data,
                    params: { 'pagesize': options.data.pageSize, 'page': options.data.page }
                }).then(response => {
                    var data = response.data._embedded.reportData;

                    options.success(response.data);
                    vm.enableGrid = true;

                    if (data.length > 0) {
                        vm.dynamicSideGridDataSource = null;
                    }
                    if (vm.isDetailed) {
                        let securityObject;
                        if (vm.selectedProducts[0].IsCustom) {
                            // the security object for custom products has null for product type so we can't send the real product type
                            securityObject = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, "customProduct");
                        } else {
                            securityObject = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, vm.selectedProducts[0].Type);
                        }
                        if (securityObject) {
                            $scope.reportId = securityObject.ReportID;
                        }
                    }

                }).catch(response => {
                    const timeout = 408;

                    if (response.status !== timeout) {
                        helperService.showErrorMessage(response.data);
                    }
                });
            }
        }
    });

    vm.dynamicSideGridOptions = helperService.setNonPageableGridOptions([]);

    function updateUserDataSource(selectedUsers, selectedUserIds, userDataSource) {
        vm.users = selectedUsers;
        vm.selectedUserIds = selectedUserIds;
        vm.userDataSource = userDataSource;
        $scope.populateCriteriaModel();
    }

    function continuePopulateCriteriaModel() {
        return vm.selectedProducts && vm.selectedUserIds && vm.selectedCompanies && vm.selectedRuleset && vm.selectedBusinessProcesses && vm.selectedObject;
    }

    function populateCriteriaModel() {
        // only continue if the proper criteria are loaded
        if (continuePopulateCriteriaModel()) {

            vm.products = vm.selectedProducts;
            vm.ruleset = vm.selectedRuleset;
            vm.users = vm.users?.length ? vm.users : [{ "Name": "All Users", "ID": null }];
            vm.businessProcesses = vm.selectedBusinessProcesses;
            vm.securityObject = vm.selectedObject;

            var criteria = [];
            criteria.push(CustomReportServices.CreateCriteria("SecurityObject", 'SecurityObject', vm.securityObject.AltName, { "Id": vm.selectedObject.Identifier }));

            vm.selectedBusinessProcesses.forEach(bp => {
                criteria.push(CustomReportServices.CreateCriteria("ProcessId", 'BusinessProcessId', bp.Name, bp.Id));
            });

            vm.products.forEach(function (row) {
                criteria.push(CustomReportServices.CreateCriteria("ProductId", 'Product', row.Name, row.ID));
            });

            criteria.push(CustomReportServices.CreateCriteria("RulesetId", 'Ruleset', vm.ruleset.Name, vm.ruleset.RulesetId));

            vm.users.forEach(function (user) {
                criteria.push(CustomReportServices.CreateCriteria("UserMasterId", 'User', user.Name, user.ID));
            });

            vm.selectedCompanies.forEach(function (row) {
                criteria.push(CustomReportServices.CreateCriteria("CompanyMasterId", 'Company', row.Name, row.ID));
            });

            $scope.reportDetailsModel = criteria;

            vm.urlString = apiUrl + 'api/arm/places/userbusinessprocessaccess';

            // if tabs are on, save report info for the tab
            if ($rootScope.tabs.length > 0) {
                reportsService.saveTabReportInfo($scope);
            }
        }
    }

    function generateGridWithSelectedValues() {

        if (!$rootScope.loadedSavedReport) {
            $scope.grid.dataSource._filter = null;
            $scope.grid.dataSource._sort = null;
        }

        populateCriteriaModel();
        vm.hideExportOptionsFromSubmit = true;

        // Add back the sorting and filtering options if we are running the report for the first time
        if (!$rootScope.loadedSavedReport) {
            $scope.grid.setOptions({
                filterable: {
                    mode: "row"
                },
                sortable: true
            });
        }

        SavedReportSelectorService.queryGridWithOptions($rootScope.loadedSavedReport, $scope.grid, true);
    }

    function setColumns() {
        if (vm.selectedProducts) {

            if (vm.selectedProducts.length === 1 && vm.isDetailed === true) {
                if (vm.selectedProducts[0].Type === 'OR') {
                    vm.mainGridColumns = [
                        { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "UserDescription", title: "User Description", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "UserMenuName", title: "User Menu Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MenuName", title: "Menu Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "UserSubMenuName", title: "User Sub Menu Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SubMenuName", title: "Sub Menu Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "SecurityObjectID", title: "Object ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Prompt", title: "Prompt", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Path", title: "Path", width: 180, filterable: customAutoCompleteFilter }
                    ];

                    if (!helperService.convertConfigValueToTrueFalse('OR_RBACEnabled')) {
                        vm.mainGridColumns.splice(6, 0, { field: "Role", title: "Role", width: 180, filterable: customAutoCompleteFilter }, { field: "RoleCode", title: "Role Code", width: 180, filterable: customAutoCompleteFilter }, { field: "RoleType", title: "Role Type", width: 180, filterable: customAutoCompleteFilter });
                    } else {
                        vm.mainGridColumns.splice(6, 0, { field: "Responsibility", title: "Responsibility", width: 180, filterable: customAutoCompleteFilter }, { field: "ResponsibilityID", title: "Responsibility ID", width: 180, filterable: customAutoCompleteFilter }, { field: "ResponsibilityKey", title: "Responsibility Key", width: 180, filterable: customAutoCompleteFilter });
                    }

                } else if (vm.selectedProducts[0].Type === 'INT') {
                    vm.mainGridColumns = [
                        { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Module", title: "Module", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectID", title: "Object ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter }
                    ];

                } else if (vm.selectedProducts[0].Type === 'NS') {
                    vm.mainGridColumns = [
                        { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectID", title: "Object ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'NAV') {
                    vm.mainGridColumns = [
                        { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "FullName", title: "Full Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RoleId", title: "Role Id", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectID", title: "Object ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessLevelName", title: "Access Level", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'D365Bc') {
                    vm.mainGridColumns = [
                        { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RoleId", title: "Role Id", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectID", title: "Object ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessLevelName", title: "Access Level", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'Zuo' || vm.selectedProducts[0].IsCustom) {
                    vm.mainGridColumns = [
                        { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RoleId", title: "Role Id", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectID", title: "Object ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessLevelName", title: "Access Level", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'CPA') {
                    vm.mainGridColumns = [
                        { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RoleId", title: "Role Id", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectID", title: "Object ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'AX7') {
                    vm.mainGridColumns = [
                        { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RoleSystemName", title: "Role System Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SubRoleSystemName", title: "Sub Role System Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "SubRoleName", title: "Sub Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "DutySystemName", title: "Duty System Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "DutyName", title: "Duty Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "PrivilegeSystemName", title: "Privilege System Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "PrivilegeName", title: "Privilege Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectID", title: "Object ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessLevelName", title: "Access Level", width: 180, filterable: customAutoCompleteFilter },
                        { field: "LicenseType", title: "License Type", width: 180, filterable: customAutoCompleteFilter }
                    ];

                    if (vm.aLAPluginActive) {
                        const lastUsedCol = { field: "LastUsed", title: "Last Used", template: "{{ dataItem.LastUsed | date:'short'}}", width: 200, filterable: customDatePickerFilter },
                            viewCountCol = { field: "ViewCount", title: "View Count", width: 130, filterable: customAutoCompleteFilter };

                        vm.mainGridColumns.push(lastUsedCol);
                        vm.mainGridColumns.push(viewCountCol);
                    }
                } else if (vm.selectedProducts[0].Type === 'AX') {
                    vm.mainGridColumns = [
                        { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RolePhysicalName", title: "Role Physical Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "SubRoleName", title: "Sub Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SubRolePhysicalName", title: "Sub Role Physical Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "DutyName", title: "Duty Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "DutyPhysicalName", title: "Duty Physical Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "PrivilegeName", title: "Privilege Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "PrivilegePhysicalName", title: "Privilege Physical Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "EntryPointName", title: "Entry Point Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "EntryPointPhysicalName", title: "Entry Point Physical Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "EntryPointAccessName", title: "Entry Point Access", width: 180, filterable: customAutoCompleteFilter },
                        { field: "EntryPointTypeName", title: "Entry Point Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectID", title: "Object ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectHelpText", title: "Object Help Text", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'SAP') {
                    vm.mainGridColumns = [
                        { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "UserGroup", title: "User Group", width: 180, filterable: customAutoCompleteFilter },
                        { field: "UserType", title: "User Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Type", title: "Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "Name", title: "Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectID", title: "Object ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Authorization", title: "Authorization", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Field", title: "Field", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RulesetLow", title: "Ruleset Low", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RulesetHigh", title: "Ruleset High", width: 130, filterable: customAutoCompleteFilter },
                        { field: "TechnicalLow", title: "Technical Low", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Low", title: "Low", width: 130, filterable: customAutoCompleteFilter },
                        { field: "TechnicalHigh", title: "Technical High", width: 130, filterable: customAutoCompleteFilter },
                        { field: "High", title: "High", width: 130, filterable: customAutoCompleteFilter },
                        { field: "UseCount", title: "Use Count", width: 130, filterable: customAutoCompleteFilter },
                        { field: "LastRun", title: "Last Run Date", width: 200, filterable: customDatePickerFilter },
                        { field: "BusinessUnit", title: "Business Unit", width: 200, filterable: customAutoCompleteFilter },
                        { field: "Department", title: "Department", width: 200, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 200, filterable: customAutoCompleteFilter },
                        { field: "Location", title: "Location", width: 200, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'SAPB1') {
                    vm.mainGridColumns = [
                        { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AuthorizationSource", title: "Authorization Source", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'SF') {
                    vm.mainGridColumns = [
                        { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "UserFullName", title: "User Full Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AssignedByType", title: "Assigned by Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AssignedByName", title: "Assigned by Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Module", title: "Module", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectID", title: "Object ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'GP') {
                    vm.mainGridColumns = [
                        { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "TaskName", title: "Task Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Series", title: "Series", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectID", title: "Object ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'AC') {
                    vm.mainGridColumns = [
                        { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectID", title: "Object ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Path", title: "Path", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'PS') {
                    vm.mainGridColumns = [
                        { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "PermissionListId", title: "Permission List", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MenuName", title: "Menu", width: 180, filterable: customAutoCompleteFilter },
                        { field: "BarName", title: "Bar Name", width: 100, filterable: customAutoCompleteFilter },
                        { field: "ComponentName", title: "Component", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MarketName", title: "Market", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectID", title: "Object ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessLevelName", title: "Access Level", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'JDE') {
                    vm.mainGridColumns = [
                        { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Application", title: "Application", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Version", title: "Version", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessSource", title: "Access Source", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectID", title: "Object ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'ORFC') {
                    vm.mainGridColumns = [
                        { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RolePhysicalName", title: "Role Physical Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "SubRoleName", title: "Sub Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SubRolePhysicalName", title: "Sub Role Physical Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "PrivilegeName", title: "Privilege Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "PrivilegePhysicalName", title: "Privilege Physical Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "SecurityObjectID", title: "Object ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RolePath", title: "Role Path", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'AX5') {
                    vm.mainGridColumns = [
                        { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "DomainId", title: "Domain", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AXGroup", title: "Group", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectID", title: "Object ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'SL') {
                    vm.mainGridColumns = [
                        { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessSource", title: "Access Source", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectID", title: "Object ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'WD') {
                    vm.mainGridColumns = [
                        { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityGroup", title: "Security Group", width: 180, filterable: customAutoCompleteFilter },
                        { field: "BusinessProcessType", title: "Business Process Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "BusinessProcessDefinition", title: "Business Process Definition", width: 180, filterable: customAutoCompleteFilter },
                        { field: "StepOrder", title: "Step Order", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RoutingRestrictions", title: "Routing Restrictions", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Condition", title: "Condition", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'D365S') {
                    vm.mainGridColumns = [
                        { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "TeamName", title: "Team Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "PrivilegeName", title: "Privilege Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectID", title: "Object ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessLevelName", title: "Access Level", width: 180, filterable: customAutoCompleteFilter }
                    ];
                }
                else if (vm.selectedProducts[0].Type === 'AD') {
                    vm.mainGridColumns = [
                        { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserId", title: "User Id", width: 180, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SubGroupName", title: "Sub-Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Path", title: "Path", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessType", title: "Access Type", width: 180, filterable: customAutoCompleteFilter }
                    ];
                }
                else if (vm.selectedProducts[0].Type === 'AAD') {
                    vm.mainGridColumns = [
                        { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserId", title: "User Id", width: 180, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SubGroupName", title: "Sub-Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Path", title: "Path", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'OREPM') {
                    vm.mainGridColumns = [
                        { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserID", title: "User Id", width: 180, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Enabled", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Service", title: "Service", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GrantedThroughGroup", title: "Granted Through Group", width: 180, filterable: customAutoCompleteFilter }
                    ];
                }
            } else {
                vm.mainGridColumns = [
                    { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                    { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                    { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                    { field: "UserDescription", title: "Description", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "CompanyName", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                    { field: "ProductName", title: "Product", width: 180, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectID", title: "Object ID", width: 180, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                    { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter },
                    { field: "AccessLevelName", title: "Access Level", width: 180, filterable: customAutoCompleteFilter, hidden: true }
                ];
            }

            // Don't allow filtering and sorting yet because they will force the report to run
            if (!$rootScope.loadedSavedReport) {
                vm.mainGridOptions.sortable = false;

                vm.mainGridOptions.filterable = false;
            }


            setLayout();
        }
    }

    function setLayout() {
        if (!$rootScope.loadedSavedReport) {
            CustomReportServices.loadSavedGridLayout($scope, null);
        }
    }

    vm.changeRowSelected = function (kendoEvent) {

        vm.sideGridIsNew = false;
        vm.sideGridLoading = true;
        vm.dynamicSideGridDataSource = [];

        vm.dynamicSideGridOptions = helperService.setNonPageableGridOptions([]);
        vm.sideGridIsNew = true;
        var grid = kendoEvent.sender;
        vm.sideGridHeader = grid.dataItem(grid.select()).ProductName;
        CustomReportServices.getAccessDetailsForRow($scope, grid).then(response => {
            if (response.data.length > 0) {
                vm.dynamicSideGridColumns = Object.keys(response.data[0]).map(f => {
                    var column = {};
                    column.field = f;
                    column.title = helperService.headerStringTransformer(f);
                    if (f === 'LastUsed') {
                        column.template = "{{ dataItem.LastUsed | date:'short'}}";
                    }
                    return column;
                });
                vm.dynamicSideGridDataSource = new kendo.data.DataSource({
                    data: response.data
                });
            }
        });
        vm.sideGridLoading = false;

    };

    function deleteFilter(id) {
        CustomReportServices.deleteFilterFilterManager($scope, id);
    }

    function clearAllFilters() {
        $scope.gridDataSource.filter({});
    }

    function saveReportLayoutButton() {
        CustomReportServices.saveGridLayout($scope, null);
    }

    function productDropdownChange() {
        if (vm.isDetailed) {
            if (vm.selectedProducts && vm.selectedProducts.length > 1) {
                vm.selectedProducts.remove(vm.selectedProducts[0]);
            }
            if (vm.selectedProducts) {
                if (vm.selectedProducts[0].IsCustom) {
                    $scope.reportId = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, "customProduct").ReportID;
                } else {
                    $scope.reportId = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, vm.selectedProducts[0].Type).ReportID;
                }
                getReportMetadata();
            }
        }
        setColumns();
    }

    function rolesDropdownChange() {
        vm.roleSelectedIds = helperService.deselectAllObjectsIfOtherIsClicked(vm.roleSelectedIds, vm.allRolesDropdownObject);
    }

    function rulesetDropdownChange() {
        getBusinessProcesses();
        if (vm.filterCriteria.query && vm.filterCriteria.query.some(criteria => criteria.CriteriaDisplayName.includes("Business Process"))) {
            vm.filterCriteria.query = vm.filterCriteria.query.filter(criteria => {
                if (criteria.CriteriaDisplayName) {
                    return !criteria.CriteriaDisplayName.includes("Business Process");
                } else {
                    return criteria;
                }
            });
            vm.filterCriteria.needsUpdate = true;
        }
    }

    function getCompanies() {
        risksDataService.getCompanies().then(function (response) {
            response.data = $filter('orderBy')(response.data, 'Name');
            vm.allCompaniesDropdownObject = { "Name": "All Companies", "ID": "AllObjectsInMultiSelect" };
            response.data = response.data.filter(c => c.UserHasAccess === true);
            response.data.unshift(vm.allCompaniesDropdownObject);
            vm.companyDataSource = response.data;
            let defaultCompany = response.data.find(obj => obj.Name === $rootScope.UserPreferences.PreferredCompany);
            let defaultCompanySelected = vm.companyDataSource.indexOf(defaultCompany);
            if ($rootScope.UserPreferences.PreferredCompany !== null) {
                vm.selectedCompanies = [vm.companyDataSource[defaultCompanySelected]];
            } else {
                vm.selectedCompanies = [vm.allCompaniesDropdownObject];
            }
            vm.selectedCompanies = SavedReportSelectorService.checkSavedReportForCompanies($rootScope.loadedSavedReport, response.data, vm.selectedCompanies);
            companyDropdownChange();
            runSavedReport();
        });
    }

    function getRulesets() {
        return risksDataService.getRulesets()
            .then(response => {
                response.data = $filter('filter')(response.data, { IsEnabled: true });
                vm.rulesetDataSource = response.data;
                vm.rulesetDataSource = $filter('orderBy')(response.data, 'Name');
                vm.selectedRuleset = SavedReportSelectorService.checkSavedReportForRuleset($rootScope.loadedSavedReport, response.data, vm.rulesetDataSource);
                runSavedReport();
            });
    }

    async function getBusinessProcesses() {
        if (vm.selectedBusinessProcesses) {
            vm.selectedBusinessProcesses = null;
        }
        let response = await businessProcessDataService.getBusinessProcessesByRiskRuleset(vm.selectedRuleset.RulesetId);
        let businessProcesses = response.data;
        businessProcesses = $filter('orderBy')(response.data, 'Name');

        if (businessProcesses.length > 0) {
            vm.invalidRuleset = false;
            vm.allBusinessProcessesDropdownObject = { "Name": "All Business Processes", "Id": "AllObjectsInMultiSelect", "Type": "BP", "Objects": [], "DisplayName": "All Business Processes" };
            businessProcesses.unshift(vm.allBusinessProcessesDropdownObject); vm.businessProcessDataSource = businessProcesses;
            vm.selectedBusinessProcesses = [vm.allBusinessProcessesDropdownObject];
            vm.selectedBusinessProcesses = SavedReportSelectorService.checkSavedReportForBusinessProcesses($rootScope.loadedSavedReport, response.data, vm.selectedBusinessProcesses);
            businessProcessDropdownChange();
            runSavedReport();
        } else {
            vm.invalidRuleset = true;
        }
    }

    function businessProcessDropdownChange() {
        vm.selectedBusinessProcesses = helperService.deselectAllObjectsIfOtherIsClicked(vm.selectedBusinessProcesses, vm.allBusinessProcessesDropdownObject);

        if (vm.selectedBusinessProcesses) {

            vm.objectDataSource = [];

            if (vm.selectedBusinessProcesses.some(bp => bp.Id === "AllObjectsInMultiSelect")) {
                vm.businessProcessDataSource.forEach(function (row) {
                    row.Objects.forEach(function (row) {
                        vm.objectDataSource.push(row.SecurityObject);
                    });
                });

                vm.objectDataSource = vm.objectDataSource.unique();
            } else {
                vm.selectedBusinessProcesses.forEach(bp => {
                    bp.Objects.forEach(object => vm.objectDataSource.push(object.SecurityObject));
                });
                vm.objectDataSource = vm.objectDataSource.unique();
            }

            vm.objectDataSource = $filter('orderBy')(vm.objectDataSource, 'AltName');
            vm.objectDataSource.unshift({ "Id": null, "AltName": "All Objects" });
            vm.selectedObject = vm.objectDataSource[0];
            vm.selectedObject = SavedReportSelectorService.checkSavedReportForObjects($rootScope.loadedSavedReport, vm.objectDataSource, vm.selectedObject);
            runSavedReport();
        }
    }

    function companyDropdownChange(companies) {
        if (companies) {
            vm.selectedCompanies = helperService.deselectAllObjectsIfOtherIsClicked(companies, vm.allCompaniesDropdownObject);
        } else {
            vm.selectedCompanies = helperService.deselectAllObjectsIfOtherIsClicked(vm.selectedCompanies, vm.allCompaniesDropdownObject);
        }
    }

    // --- FOR ADVANCED FILTERING ---
    function getReportMetadata() {
        return reportsDataService.getReportMetadata($scope.reportId)
            .then(data => {

                vm.reportMetadata = data.data._embedded.reportData;
                if (vm.selectedProducts.some(product => product.Type === 'OR')) {
                    if (helperService.convertConfigValueToTrueFalse('OR_RBACEnabled')) {
                        vm.reportMetadata.Columns = vm.reportMetadata.Columns.filter(column => column.FieldName !== "Role" && column.FieldName !== "RoleCode" && column.FieldName !== "RoleType");
                    } else {
                        vm.reportMetadata.Columns = vm.reportMetadata.Columns.filter(column => column.FieldName !== "Responsibility" && column.FieldName !== "ResponsibilityID" && column.FieldName !== "ResponsibilityKey");
                    }
                }
                vm.reportMetadata.Columns = $filter('orderBy')(vm.reportMetadata.Columns, 'DisplayName');
                vm.filterCriteria.fields = reportsService.getFields(vm.reportMetadata.Columns);
                return vm.reportMetadata;
            });
    }
    // ---

}