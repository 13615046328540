import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, $filter, helperService, riskRulesetService, riskRulesetMapper, riskRuleset, risk) {
    const vm = this;

    vm.cancel = cancel;
    vm.addBusinessProcessToSelectedGrid = addBusinessProcessToSelectedGrid;
    vm.risk = risk;

    activate();

    function activate() {
        setUpObjectsGrid();
    }

    function cancel() {
        $uibModalInstance.close();
    }

    function setUpObjectsGrid() {
        const availableObjectsGridColumns = [
            { title: "Name", field: "BusinessProcessName", filterable: customAutoCompleteFilter },
            { title: "Description", field: "BusinessProcessDescription", filterable: customAutoCompleteFilter },
            { template: "<a ng-click='aocvm.addBusinessProcessToSelectedGrid(dataItem)' ng-disabled='aocvm.processingBPAdd'><i class='fa fa-plus pull-right'></i></a>", title: " ", width: 20 }
        ];

        vm.availableObjectsGridOptions = helperService.setMainGridOptions(availableObjectsGridColumns, null);
        vm.availableObjectsGridOptions.selectable = false;

        vm.availableObjectsGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    let response = await riskRulesetService.getBusinessProcesses(riskRuleset.RulesetId);
                    vm.originalObjects = riskRulesetMapper.mapBusinessProcesses(response.data);
                    vm.availableObjects = vm.originalObjects.slice();
                    vm.showBpGrid = true;

                    risk.Objects.forEach(function (row, i) {
                        const Id = row.BusinessProcessId;
                        vm.availableObjects.forEach(el => {
                            if (Id.toString() === el.BusinessProcessId) {
                                vm.availableObjects.remove(el);
                            }
                        });

                    });
                    vm.availableObjects = $filter('orderBy')(vm.availableObjects, 'BusinessProcessName');
                    options.success(vm.availableObjects);
                }
            }
        });


    }

    async function addBusinessProcessToSelectedGrid(dataItem) {
        vm.processingBPAdd = true;

        const data = {
            "RiskId": risk.Id,
            "BusinessProcessId": dataItem.BusinessProcessId
        };

        try {
            const response = await riskRulesetService.addNewRiskObject(risk.Id, data);
            dataItem.Id = response.data;
            dataItem.ConditionList = [];
            risk.Objects.push(dataItem);
            vm.availableObjectsGridDataSource.read();
            vm.processingBPAdd = false;
        } catch (_) {
            vm.processingBPAdd = false;
        }
    }
}