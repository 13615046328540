import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, $filter, selectedUsers, requesterGroup, helperService, requesterGroupsDataService, dataService) {
    const vm = this;

    vm.addUser = addUser;
    vm.close = close;

    selectedUsers = [...selectedUsers];

    activate();

    function activate() {
        setupAvailableUsersGrid();
    }

    function setupAvailableUsersGrid() {
        let availableUsersGridColumns = [
            { title: "Name", field: "UserLogonName", filterable: customAutoCompleteFilter },
            { title: "Email", field: "Email", filterable: customAutoCompleteFilter },
            { template: "<a ng-disabled='rgumvm.addUserProcessing' ng-click='rgumvm.addUser(dataItem)'><i class='fa fa-plus pull-right'></i></a>", title: " ", width: 20 }
        ];

        vm.availableUsersGridOptions = helperService.setMainGridOptions(availableUsersGridColumns, null);

        vm.availableUsersGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    let response = await dataService.getUsers();
                    response.data = response.data.filter(availableUser => {
                        return !selectedUsers.some(user => user.UserId === availableUser.UserID);
                    });
                    response.data = $filter('orderBy')(response.data, "UserLogonName");
                    options.success(response.data);
                }
            }
        });
    }

    async function addUser(user) {
        vm.addUserProcessing = true;
        kendo.ui.progress(vm.availableUsersGrid.element, true);
        let data = {
            RequesterGroupId: requesterGroup.Id,
            UserId: user.UserID
        }
        try {
            await requesterGroupsDataService.addUserToGroup(data);
            let processedUser = JSON.parse(JSON.stringify(user));
            processedUser.UserId = processedUser.UserID;
            delete processedUser.UserID;
            selectedUsers.push(processedUser);
            kendo.ui.progress(vm.availableUsersGrid.element, false);
            vm.availableUsersGridDataSource.read();
            vm.addUserProcessing = false;
        } catch {
            vm.addUserProcessing = false;
            kendo.ui.progress(vm.availableUsersGrid.element, false);
        }
    }

    function close() {
        $uibModalInstance.close();
    }
}