import { customAutoCompleteFilter, standardGridPageSize, customBooleanFilter } from "../../Shared/GlobalVariables/filterVariables";
import createSpinner from "../../Shared/Functions/createSpinner";
import importControlModalController from "./importControlModal.controller";
import newEditControlModalController from "./newEditControlModal.controller";

export default /*@ngInject*/ function ($scope, FileSaver, $uibModal, helperService, controlLibraryDataService, configurationDataService) {

    const vm = this;
    vm.deleteControl = deleteControl;
    vm.exportControls = exportControls;
    vm.openNewEditModal = openNewEditModal;
    vm.openImportModal = openImportModal;
    vm.helperService = helperService;

    $scope.$on('ImportControlsLibrary', (event, args) => vm.gridDataSource.read());

    activate();

    async function activate() {
        vm.activeControlPlugins = await getActiveControlPlugins();
        let columnsFromVM = [
            { field: "Id", title: "Control ID", filterable: customAutoCompleteFilter },
            { field: "Name", title: "Control Name", filterable: customAutoCompleteFilter },
            { field: "PerformerName", title: "Control Performer", filterable: customAutoCompleteFilter },
            { field: "OwnerName", title: "Control Owner", filterable: customAutoCompleteFilter },
            { template: `<a ng-click="clvm.openNewEditModal(dataItem)"><i class="fa fa-edit text-warning m-r-xs"></i>Edit</a>`, "title": "Edit", width: 90 },
            { template: `<a ng-click="clvm.deleteControl(dataItem.Identifier)"><i class="fa fa-trash-o text-danger m-r-xs"></i>Delete</a>`, "title": "Delete", width: 90 }
        ];

        if (vm.activeControlPlugins.length > 0) {
            vm.activeControlPlugins.forEach(plugin => {
                columnsFromVM.splice(3, 0, { field: `Is${plugin}Control`, title: `Imported from ${plugin}`, filterable: customBooleanFilter, width: 150 });
            });
        }

        vm.mainGridOptions = helperService.setMainGridOptions(columnsFromVM, 600);
        vm.mainGridOptions.selectable = false;
    }

    async function getActiveControlPlugins() {
        let pluginsArray = [];
        // individually get config values for specific plugins until we have a better way to accomplish this
        const workivaActive = await configurationDataService.getConfigurationValueByName("WORKIVA_Active");
        const auditBoardActive = await configurationDataService.getConfigurationValueByName("AUDITBOARD_Active");
        if (workivaActive.data) {
            pluginsArray.push('Workiva');
        }
        if (auditBoardActive.data) {
            pluginsArray.push('AuditBoard');
        }
        return pluginsArray;
    }

    function deleteControl(control) {
        controlLibraryDataService.deleteControl(control)
            .then(response => {
                vm.gridDataSource.read();
            }).catch(errorCallback);
    }

    function errorCallback(response) {
        helperService.showErrorMessage(response.data);
    }

    function exportControls() {

        vm.exportUpdatePending = new createSpinner();

        controlLibraryDataService.exportControls()
            .then(response => {
                helperService.successfulSaveButton(vm.exportUpdatePending)
                    .then(result => {
                        FileSaver.saveAs(response.data, 'Controls.xlsx');
                        vm.exportUpdatePending.loadingValue = false;
                    });
            }).catch(response => {
                helperService.showErrorMessage(response.data);
                vm.exportUpdatePending.loadingValue = false;
            });
    }

    async function openNewEditModal(item) {
        let newEditModal = $uibModal.open({
            templateUrl: '/App/Components/controlLibrary/newEditControlModal.html',
            controller: newEditControlModalController,
            controllerAs: 'necvm',
            backdrop: 'static',
            scope: $scope,
            resolve: {
                item: () => item || {}
            }
        });

        try {
            await newEditModal.result;
            vm.gridDataSource.read();
        } catch {
            return;
        }
    }

    function openImportModal() {
        $uibModal.open({
            templateUrl: '/App/Components/controlLibrary/importControlModal.html',
            controller: importControlModalController,
            controllerAs: 'icmvm',
            backdrop: 'static',
            scope: $scope,
            resolve: {
                activeControlPlugins: () => vm.activeControlPlugins
            }
        });
    }

    vm.gridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        schema: {
            model: {
                fields: {
                    IsWorkivaControl: { type: "boolean" },
                    IsAuditBoardControl: { type: "boolean" }
                }
            }
        },
        transport: {
            read: async options => {
                try {
                    let response = await controlLibraryDataService.getControls();
                    options.success(response.data);
                } catch (error) {
                    options.error(error);
                }
            }
        }
    });

}