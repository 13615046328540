import { standardGridPageSize, customAutoCompleteFilter, customBooleanFilter } from "../../Shared/GlobalVariables/filterVariables";
import ruleTypePriorityModalController from "./RuleTypePriorityModal/ruleTypePriorityModal.controller";
import swal from "sweetalert2";
import newEditApprovalRuleModalController from "./NewEditApprovalRuleModal/newEditApprovalRuleModal.controller";

export default /*@ngInject*/ function (approvalRulesDataService, $filter, helperService, $uibModal) {

    const vm = this;

    vm.helperService = helperService;
    vm.openNewEditModal = openNewEditModal;
    vm.openRuleTypePriorityModal = openRuleTypePriorityModal;
    vm.deleteRule = deleteRule;

    async function activate() {
        let data = await helperService.getConfigurationValue('IM_SODThresholdNewOnly');
        vm.IM_SODThresholdNewOnly = data;

        const approvalRulesGridColumns = [
            { field: "TypeName", title: "Approval Rule Type", filterable: customAutoCompleteFilter, width: 125 },
            { field: "RequesterType", title: "Requested Type", filterable: customAutoCompleteFilter },
            { field: "ProductName", title: "Product", filterable: customAutoCompleteFilter },
            { field: "RequesterName", title: "Requested", filterable: customAutoCompleteFilter, width: 100 },
            { field: "ApproverType", title: "Approver Type", filterable: customAutoCompleteFilter },
            { field: "ApproverName", title: "Approver", filterable: customAutoCompleteFilter },
            { field: "AutoApproveUser", title: "Auto Approve User", filterable: customBooleanFilter, width: 150 },
            { template: `<a ng-if="dataItem.TypeName !== 'Supervisor'" ng-click="arvm.openNewEditModal(dataItem, 'Edit')"><i class="fa fa-edit text-warning m-r-xs"></i>Edit</a>`, "title": "Edit", width: 65 },
            { template: `<a ng-click="arvm.deleteRule(dataItem.Id)"><i class="fa fa-trash-o text-danger m-r-xs"></i>Delete</a>`, "title": "Delete", width: 75 }
        ];

        vm.approvalRulesGridOptions = helperService.setMainGridOptions(approvalRulesGridColumns, 600);
        vm.approvalRulesGridOptions.selectable = false;

        vm.approvalRulesGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: {
                model: {
                    fields: {
                        AutoApproveUser: { type: "boolean" }
                    }
                }
            },
            transport: {

                read: async options => {
                    try {
                        let response = await approvalRulesDataService.getApprovalRules();
                        response.data = $filter('orderBy')(response.data, 'Name');
                        response.data.forEach(rule => {
                            rule.RequesterType = $filter('insertSpaceBetweenCapitalWordsAndAcronyms')(rule.RequesterType);
                        });
                        options.success(response.data);
                    } catch {
                        options.error();
                    }
                }
            }
        });

    }

    activate();



    async function openNewEditModal(approvalRule, modalType) {
        const supervisorRuleExists = vm.approvalRulesGridDataSource._data.some(rule => rule.TypeName === 'Supervisor');
        const allCompaniesRuleExists = vm.approvalRulesGridDataSource._data.some(rule => rule.RequesterName === 'All Companies');

        let openModal = $uibModal.open({
            templateUrl: '/App/Components/ApprovalRules/NewEditApprovalRuleModal/newEditApprovalRuleModal.html',
            controller: newEditApprovalRuleModalController,
            controllerAs: 'nearmvm',
            backdrop: 'static',
            resolve: {
                supervisorRuleDisabled: () => supervisorRuleExists,
                allCompaniesRuleDisabled: () => allCompaniesRuleExists,
                modalType: () => modalType,
                approvalRule: () => approvalRule
            }
        });

        try {
            await openModal.result;
            vm.approvalRulesGridDataSource.read();
        } catch {
            return;
        }
    }

    async function openRuleTypePriorityModal() {
        vm.configDropdownOpen = false;
        let rulePriorityModal = $uibModal.open({
            templateUrl: '/App/Components/ApprovalRules/RuleTypePriorityModal/ruleTypePriorityModal.html',
            controller: ruleTypePriorityModalController,
            controllerAs: 'rtpmvm',
            backdrop: 'static'
        });

        try {
            await rulePriorityModal.result;
            return;
        } catch {
            return;
        }
    }

    async function deleteRule(approvalRuleId) {
        try {
            await swal(helperService.areYouSureParams('Delete Rule', 'Are you sure you want to this rule?', 'Delete'));
            await approvalRulesDataService.removeApprovalRule(approvalRuleId);
            vm.approvalRulesGridDataSource.read();
        } catch {
            return;
        }

    }
}