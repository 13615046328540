import { customAutoCompleteFilter, standardGridPageSize, customBooleanFilter, customDatePickerFilter } from "../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../Shared/kendo.module";

export default /*@ngInject*/ function ($scope, $rootScope, SavedReportSelectorService, $filter, dataService, objectIdDataService, CustomReportServices, $http, $location, helperService, reportsDataService, reportsService) {

    $scope.$on('kendoWidgetCreated', function () {
        if ($scope.grid) {
            $scope.gridReady = true;
            $scope.runSavedReport();
        }
    });

    var vm = $scope.vm = {};

    vm.allRolesOption = false;

    $scope.helperService = helperService;

    vm.updateRoleDataSource = updateRoleDataSource;

    // --- FOR ADVANCED FILTERING ---
    vm.filterCriteria = {};
    vm.filterCriteria.query = [];
    // ---

    $scope.$watch('vm.roleSelectedIds', function () {
        $scope.populateCriteriaModel();
    }, true);

    objectIdDataService.getObjectID().then(async function (objectId) {
        $scope.ObjectId = objectId;

        $scope.filtersDataSource = CustomReportServices.setfiltersDataSource($scope.ObjectId);

        var securityObject = helperService.getSecurityObjectDetailsFromObjectId($scope.ObjectId);
        $scope.pageInfo = securityObject;
        $scope.pageDescription = securityObject.HelpLink;
        $scope.reportName = securityObject.Name;

        await aLAPluginActive();

        dataService.getProducts().then(function (response) {
            vm.multiSelect = true;
            response.data = response.data.filter(p => p.UserHasAccess === true);
            vm.productsDataSource = response.data;
            let defaultProduct = vm.productsDataSource.find(obj => obj.Name === $rootScope.UserPreferences.PreferredProduct);
            let defaultProductSelected = vm.productsDataSource.indexOf(defaultProduct);
            if ($scope.isDetailed) {
                vm.multiSelect = false;
                vm.productsDataSource = vm.productsDataSource.filter(product => product.Type === 'AC' || product.Type === 'AX5' || product.Type === "AX" || product.Type === "AX7" || product.Type === "AAD" || product.Type === 'PS' || product.Type === 'GP' || product.Type === 'D365Bc' || product.Type === 'D365S' || product.Type === 'SL' || product.Type === 'NAV' || product.Type === 'SAPB1' || product.Type === 'JDE' || product.Type === 'CPA' || product.Type === 'SF' || product.Type === 'NS' || product.Type === 'WD' || product.Type === 'INT' || product.Type === 'ORFC' || product.Type === 'Zuo' || product.Type === 'SAP');
                let defaultProductExists = vm.productsDataSource.some(obj => obj.Name === $rootScope.UserPreferences.PreferredProduct);
                if (defaultProductExists) {
                    defaultProductSelected = vm.productsDataSource.indexOf(defaultProduct);
                    if ($rootScope.UserPreferences.PreferredProduct !== null) {
                        vm.selectedProduct = vm.productsDataSource[defaultProductSelected];
                    } else {
                        vm.selectedProduct = vm.productsDataSource[0];
                    }
                } else {
                   vm.selectedProduct = vm.productsDataSource[0];
                }
            } else {
                vm.multiSelect = true;
                if ($rootScope.UserPreferences.PreferredProduct !== null) {
                    vm.selectedProduct = vm.productsDataSource[defaultProductSelected];
                } else {
                    vm.selectedProduct = angular.copy(vm.productsDataSource);
                }
            }

            vm.selectedProduct = SavedReportSelectorService.checkSavedReportForSingleProduct($rootScope.loadedSavedReport, response.data, vm.selectedProduct);

            if ($scope.isDetailed) {
                $scope.reportId = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, vm.selectedProduct.Type).ReportID;
            } else {
                $scope.reportId = securityObject.ReportID;
            }
            vm.roleSelectedIds = [];
            vm.setColumns();
            $scope.runSavedReport();

            // --- FOR ADVANCED FILTERING ---
            getReportMetadata().then(_ => {
                vm.filterCriteria.fields = reportsService.getFields(vm.reportMetadata.Columns);
                if ($rootScope.loadedSavedReport) {
                    $scope.runSavedReport();
                }
            });
            // ---

        });
    });

    if ($location.path().has('Detailed')) {
        $scope.isDetailed = true;
    } else {
        $scope.isDetailed = false;
    }

    $scope.runSavedReport = function () {
        if ($scope.gridReady && $rootScope.loadedSavedReport && vm.productsDataSource && vm.riskDataSource && vm.roleDataSource && vm.filterCriteria.fields) {
            vm.filterCriteria.query = reportsService.getSavedReportFilters();
            vm.filterCriteria.needsUpdate = true;
            $scope.generateGridWithSelectedValues();
        } else if ($scope.gridReady && !$rootScope.loadedSavedReport && vm.productsDataSource && vm.riskDataSource && vm.roleDataSource && vm.filterCriteria.fields) {
            // We want the necessary report details available to be able to use the report toolbar without running the report
            $scope.populateCriteriaModel();
        }
    };

    async function aLAPluginActive() {
        let response = await $http.get(`${apiUrl}/api/core/plugins`);
        vm.aLAPluginActive = response.data.some(plugin => plugin.Id === 9 && plugin.Active);
    }

    $scope.productDropdownChange = function () {
        if ($scope.isDetailed) {
            $scope.reportId = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, vm.selectedProduct.Type).ReportID;
            getReportMetadata();
        }

        vm.roleSelectedIds = [];
        vm.setColumns();
        $scope.populateCriteriaModel();
    };

    $scope.mainGridOptions = helperService.setMainGridOptions(null, 600);
    $scope.mainGridOptions.autoBind = false;

    $scope.applySelectedFilter = function () {
        helperService.applyFilterCatchError($scope);
    };

    $scope.gridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        schema: angular.extend(halSchemaKendoGrid, {
            model: {
                fields: {
                    DefaultMitigation: { type: "boolean" },
                    AutoMitigated: { type: "boolean" },
                    RiskId: { type: "number" },
                    IsDefaultRuleset: { type: "boolean" },
                    ProcessID: { type: "number" },
                    RulesetId: { type: "number" },
                    IsAssigned: { type: "boolean" }
                }
            }
        }),
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {

            read: function (options) {
                delete $rootScope.loadedSavedReport;
                var data = { 'RoleIds': $scope.roles, 'RiskIds': $scope.risks, 'RulesetId': vm.selectedRuleset.RulesetId, 'ProductId': $scope.product.ID };
                data['QueryInput'] = helperService.prepareSortsAndFilters(options);

                // --- FOR ADVANCED FILTERING ---
                if (vm.filterCriteria.query) {
                    data['QueryInput'].Filters = data['QueryInput'].Filters.concat(vm.filterCriteria.query);
                }
                // ---

                // if tabs are on, save report info for the tab
                if ($rootScope.tabs.length > 0) {
                    reportsService.saveTabReportInfo($scope);
                }

                $http({
                    method: 'POST',
                    url: $scope.urlString,
                    data: data,
                    params: { 'pagesize': options.data.pageSize, 'page': options.data.page }
                }).then(function (response) {
                    options.success(response.data);
                    vm.enableGrid = true;

                    if ($scope.isDetailed) {
                        var securityObject = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, vm.selectedProduct.Type);

                        $scope.reportId = securityObject.ReportID;
                    }

                }).catch(response => {
                    const timeout = 408;

                    if (response.status !== timeout) {
                        helperService.showErrorMessage(response.data);
                    }
                });
            }
        }
    });

    $scope.populateCriteriaModel = function () {
        // do not continue if the proper criteria are not loaded
        if (!vm.selectedProduct || vm.riskSelectedIds === undefined || vm.roleSelectedIds === undefined || !vm.selectedRuleset) {
            return;
        }
        var criteria = [];

        $scope.product = vm.selectedProduct;
        if (vm.roleSelectedIds && vm.riskSelectedIds) {
            $scope.roles = vm.roleSelectedIds.map(role => role.Identifier);

            $scope.risks = helperService.returnNullIfAllIsFound(vm.riskSelectedIds.map(function (el) { return el.Id; }));

            criteria.push(CustomReportServices.CreateCriteria("ProductId", 'Product', $scope.product.Name, $scope.product.ID));

            vm.roleSelectedIds.forEach(function (row, i) {
                criteria.push(CustomReportServices.CreateCriteria("SecurityRoleIdentifier", 'Role', row.Name, row.Identifier));
            });

            criteria.push(CustomReportServices.CreateCriteria("RulesetId", 'Ruleset', vm.selectedRuleset.Name, vm.selectedRuleset.RulesetId));

            vm.riskSelectedIds.forEach(function (row, i) {
                criteria.push(CustomReportServices.CreateCriteria("RiskId", 'Risk', row.Name, row.Id));
            });
        }

        $scope.reportDetailsModel = criteria;

        if ($scope.isDetailed === true) {
            let productString = vm.selectedProduct.Type === 'SAP' ? 'SAP' : vm.selectedProduct.ID;
            $scope.urlString = apiUrl + 'api/arm/' + productString + '/places/rolerisk';
        } else {
            $scope.urlString = apiUrl + 'api/arm/places/rolerisk';
        }

        // if tabs are on, save report info for the tab
        if ($rootScope.tabs.length > 0) {
            reportsService.saveTabReportInfo($scope);
        }

    };

    $scope.generateGridWithSelectedValues = function () {

        if (!$rootScope.loadedSavedReport) {
            $scope.grid.dataSource._filter = null;
            $scope.grid.dataSource._sort = null;
        }

        $scope.populateCriteriaModel();
        vm.hideExportOptionsFromSubmit = true;

        // Add back the sorting and filtering options if we are running the report for the first time
        if (!$rootScope.loadedSavedReport) {
            $scope.grid.setOptions({
                filterable: {
                    mode: "row"
                },
                sortable: true
            });
        }

        SavedReportSelectorService.queryGridWithOptions($rootScope.loadedSavedReport, $scope.grid, true);
    };

    vm.setColumns = function () {

        if ($scope.isDetailed === true) {
            if (vm.selectedProduct.Type === 'AX' || vm.selectedProduct.Type === 'AX7') {
                $scope.mainGridColumns = [
                    { field: "RulesetId", title: "Ruleset Id", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RulesetName", title: "Ruleset", width: 130, filterable: customAutoCompleteFilter },
                    { field: "IsDefaultRuleset", title: "Default Ruleset", width: 150, filterable: customBooleanFilter },
                    { field: "RiskId", title: "Risk Id", width: 50, filterable: customAutoCompleteFilter },
                    { field: "RiskName", title: "Risk Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                    { field: "BusinessProcessName", title: "Business Process Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "GroupName", title: "Group Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RoleName", title: "Role Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RoleSystemName", title: "Role System Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SubRoleName", title: "Sub Role Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SubRoleSystemName", title: "Sub Role System Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "DutyName", title: "Duty Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "DutySystemName", title: "Duty System Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "PrivilegeName", title: "Privilege Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "PrivilegeSystemName", title: "Privilege System Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectID", title: "Security Object Id", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectName", title: "Security Object Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectType", title: "Security Object Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RiskLevel", title: "Risk Level", width: 80, filterable: customAutoCompleteFilter },
                    { field: "RiskDescription", title: "Risk Description", width: 240, filterable: customAutoCompleteFilter },
                    { field: "DefaultMitigation", title: "Default Mitigation", width: 150, filterable: customBooleanFilter },
                    { field: "BusinessCycleName", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter },
                    { field: "Policy", title: "Policy", width: 130, filterable: customAutoCompleteFilter },
                    { field: "IsAssigned", title: "Is Assigned", width: 120, filterable: customBooleanFilter },
                    { field: "AccessType", title: "Access Type", width: 190, filterable: customAutoCompleteFilter }
                ];  

                if (vm.aLAPluginActive && vm.selectedProduct.Type === 'AX7') {
                    const lastUsedCol = { field: "LastUsed", title: "Last Used", template: "{{ dataItem.LastUsed | date:'short'}}", width: 200, filterable: customDatePickerFilter },
                        viewCountCol = { field: "ViewCount", title: "View Count", width: 130, filterable: customAutoCompleteFilter };

                    $scope.mainGridColumns.push(lastUsedCol);
                    $scope.mainGridColumns.push(viewCountCol);
                }
            } else if (vm.selectedProduct.Type === 'AAD') {
                $scope.mainGridColumns = [
                    { field: "RulesetId", title: "Ruleset Id", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RulesetName", title: "Ruleset", width: 130, filterable: customAutoCompleteFilter },
                    { field: "IsDefaultRuleset", title: "Default Ruleset", width: 150, filterable: customBooleanFilter },
                    { field: "RiskId", title: "Risk Id", width: 50, filterable: customAutoCompleteFilter },
                    { field: "RiskName", title: "Risk Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                    { field: "BusinessProcessName", title: "Business Process Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "GroupName", title: "Group Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "Permission", title: "Permission", width: 120, filterable: customAutoCompleteFilter },        
                    { field: "SecurityObjectName", title: "Security Object Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectType", title: "Security Object Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RiskLevel", title: "Risk Level", width: 80, filterable: customAutoCompleteFilter },
                    { field: "RiskDescription", title: "Risk Description", width: 240, filterable: customAutoCompleteFilter },
                    { field: "DefaultMitigation", title: "Default Mitigation", width: 150, filterable: customBooleanFilter },
                    { field: "BusinessCycleName", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter },
                    { field: "Policy", title: "Policy", width: 130, filterable: customAutoCompleteFilter },
                    { field: "IsAssigned", title: "Is Assigned", width: 120, filterable: customBooleanFilter }
                ];
            } else if (vm.selectedProduct.Type === 'GP') {
                $scope.mainGridColumns = [
                    { field: "RulesetId", title: "Ruleset Id", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RulesetName", title: "Ruleset", width: 130, filterable: customAutoCompleteFilter },
                    { field: "IsDefaultRuleset", title: "Default Ruleset", width: 150, filterable: customBooleanFilter },
                    { field: "RiskId", title: "Risk Id", width: 80, filterable: customAutoCompleteFilter },
                    { field: "RiskName", title: "Risk Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RiskDescription", title: "Risk Description", width: 240, filterable: customAutoCompleteFilter },
                    { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                    { field: "DefaultMitigation", title: "Default Mitigation", width: 150, filterable: customBooleanFilter },
                    { field: "SecurityRoleID", title: "Security Role Id", width: 80, filterable: customAutoCompleteFilter },
                    { field: "SecurityRoleName", title: "Security Role", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityRoleTypeName", title: "Security Role Type", width: 80, filterable: customAutoCompleteFilter },
                    { field: "BusinessProcessName", title: "Business Process", width: 120, filterable: customAutoCompleteFilter },
                    { field: "GroupName", title: "Group", width: 120, filterable: customAutoCompleteFilter },
                    { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "TaskName", title: "Task", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectName", title: "Security Object", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectType", title: "Security Object Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "Series", title: "Series", width: 80, filterable: customAutoCompleteFilter },
                    { field: "RiskLevel", title: "Risk Level", width: 80, filterable: customAutoCompleteFilter },
                    { field: "BusinessCycleName", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter },
                    { field: "Policy", title: "Policy", width: 130, filterable: customAutoCompleteFilter },
                    { field: "IsAssigned", title: "Is Assigned", width: 120, filterable: customBooleanFilter }
                ];
            } else if (vm.selectedProduct.Type === 'AX5') {
                $scope.mainGridColumns = [
                    { field: "RulesetId", title: "Ruleset Id", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RulesetName", title: "Ruleset", width: 130, filterable: customAutoCompleteFilter },
                    { field: "IsDefaultRuleset", title: "Default Ruleset", width: 150, filterable: customBooleanFilter },
                    { field: "RiskId", title: "Risk Id", width: 80, filterable: customAutoCompleteFilter },
                    { field: "RiskName", title: "Risk Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RiskDescription", title: "Risk Description", width: 240, filterable: customAutoCompleteFilter },
                    { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                    { field: "DefaultMitigation", title: "Default Mitigation", width: 150, filterable: customBooleanFilter },
                    { field: "DomainId", title: "Domain Id", width: 120, filterable: customAutoCompleteFilter },
                    { field: "DomainName", title: "Domain", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityRoleID", title: "Security Group Id", width: 80, filterable: customAutoCompleteFilter },
                    { field: "SecurityRoleName", title: "Security Group", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityRoleTypeName", title: "Security Role Type", width: 80, filterable: customAutoCompleteFilter },
                    { field: "ProcessID", title: "Business Process Id", width: 120, filterable: customAutoCompleteFilter },
                    { field: "BusinessProcessName", title: "Business Process", width: 120, filterable: customAutoCompleteFilter },
                    { field: "GroupName", title: "Group", width: 120, filterable: customAutoCompleteFilter },
                    { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectName", title: "Security Object", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectType", title: "Security Object Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RiskLevel", title: "Risk Level", width: 80, filterable: customAutoCompleteFilter },
                    { field: "BusinessCycleName", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter },
                    { field: "Policy", title: "Policy", width: 130, filterable: customAutoCompleteFilter },
                    { field: "IsAssigned", title: "Is Assigned", width: 120, filterable: customBooleanFilter }
                ];
            }
            else if (vm.selectedProduct.Type === 'SAPB1' || vm.selectedProduct.Type === 'AC' || vm.selectedProduct.Type === 'D365Bc' || vm.selectedProduct.Type === 'D365S' || vm.selectedProduct.Type === 'NAV' || vm.selectedProduct.Type === 'SL' || vm.selectedProduct.Type === 'INT' || vm.selectedProduct.Type === 'Zuo') {
                $scope.mainGridColumns = [
                    { field: "RulesetId", title: "Ruleset Id", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RulesetName", title: "Ruleset", width: 130, filterable: customAutoCompleteFilter },
                    { field: "IsDefaultRuleset", title: "Default Ruleset", width: 150, filterable: customBooleanFilter },
                    { field: "RiskId", title: "Risk Id", width: 80, filterable: customAutoCompleteFilter },
                    { field: "RiskName", title: "Risk Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RiskDescription", title: "Risk Description", width: 240, filterable: customAutoCompleteFilter },
                    { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                    { field: "DefaultMitigation", title: "Default Mitigation", width: 150, filterable: customBooleanFilter },
                    { field: "SecurityRoleID", title: "Security Role Id", width: 80, filterable: customAutoCompleteFilter },
                    { field: "SecurityRoleName", title: "Security Role", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityRoleTypeName", title: "Security Role Type", width: 80, filterable: customAutoCompleteFilter },
                    { field: "BusinessProcessName", title: "Business Process", width: 120, filterable: customAutoCompleteFilter },
                    { field: "GroupName", title: "Group", width: 120, filterable: customAutoCompleteFilter },
                    { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectName", title: "Security Object", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectType", title: "Security Object Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "AccessTypeName", title: "Access Type", width: 190, filterable: customAutoCompleteFilter },
                    { field: "RiskLevel", title: "Risk Level", width: 80, filterable: customAutoCompleteFilter },
                    { field: "BusinessCycleName", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter },
                    { field: "Policy", title: "Policy", width: 130, filterable: customAutoCompleteFilter },
                    { field: "IsAssigned", title: "Is Assigned", width: 120, filterable: customBooleanFilter }
                ];
            } else if (vm.selectedProduct.Type === 'SAP') {
                $scope.mainGridColumns = [
                    { field: "RulesetId", title: "Ruleset Id", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RulesetName", title: "Ruleset", width: 130, filterable: customAutoCompleteFilter },
                    { field: "IsDefaultRuleset", title: "Default Ruleset", width: 150, filterable: customBooleanFilter },
                    { field: "RiskId", title: "Risk Id", width: 80, filterable: customAutoCompleteFilter },
                    { field: "RiskName", title: "Risk Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RiskDescription", title: "Risk Description", width: 240, filterable: customAutoCompleteFilter },
                    { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                    { field: "DefaultMitigation", title: "Default Mitigation", width: 150, filterable: customBooleanFilter },
                    { field: "SecurityRoleID", title: "Security Role Id", width: 80, filterable: customAutoCompleteFilter },
                    { field: "SecurityRoleName", title: "Security Role", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityRoleTypeName", title: "Security Role Type", width: 80, filterable: customAutoCompleteFilter },
                    { field: "BusinessProcessName", title: "Business Process", width: 120, filterable: customAutoCompleteFilter },
                    { field: "GroupName", title: "Group", width: 120, filterable: customAutoCompleteFilter },
                    { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectName", title: "Security Object", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectType", title: "Security Object Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "Authorization", title: "Authorization", width: 190, filterable: customAutoCompleteFilter },
                    { field: "Field", title: "Field", width: 190, filterable: customAutoCompleteFilter },
                    { field: "RulesetLow", title: "Ruleset Low", width: 190, filterable: customAutoCompleteFilter },
                    { field: "RulesetHigh", title: "Ruleset High", width: 190, filterable: customAutoCompleteFilter },
                    { field: "TehnicalLow", title: "Technical Low", width: 190, filterable: customAutoCompleteFilter },
                    { field: "Low", title: "Low", width: 190, filterable: customAutoCompleteFilter },
                    { field: "TehnicalHigh", title: "Technical High", width: 190, filterable: customAutoCompleteFilter },
                    { field: "High", title: "High", width: 190, filterable: customAutoCompleteFilter },
                    { field: "RiskLevel", title: "Risk Level", width: 80, filterable: customAutoCompleteFilter },
                    { field: "BusinessCycleName", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter },
                    { field: "Policy", title: "Policy", width: 130, filterable: customAutoCompleteFilter },
                    { field: "IsAssigned", title: "Is Assigned", width: 120, filterable: customBooleanFilter }
                ];
            } else if (vm.selectedProduct.Type === 'PS') {
                $scope.mainGridColumns = [
                    { field: "RiskId", title: "Risk Id", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RiskName", title: "Risk Name", width: 150, filterable: customAutoCompleteFilter },
                    { field: "RiskDescription", title: "Risk Description", width: 240, filterable: customAutoCompleteFilter },
                    { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                    { field: "BusinessProcessName", title: "Business Process Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "GroupName", title: "Group Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RoleName", title: "Role Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "PermissionListId", title: "Permission List", width: 160, filterable: customAutoCompleteFilter },
                    { field: "MenuName", title: "Menu", width: 200, filterable: customAutoCompleteFilter },
                    { field: "BarName", title: "Bar Name", width: 80, filterable: customAutoCompleteFilter },
                    { field: "ComponentName", title: "Component", width: 190, filterable: customAutoCompleteFilter },
                    { field: "MarketName", title: "Market", width: 180, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectID", title: "Security Object Id", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectLabel", title: "Security Object Label", width: 120, filterable: customAutoCompleteFilter },
                    { field: "AutoMitigated", title: "Auto Mitigated", width: 120, filterable: customBooleanFilter, hidden: true },
                    { field: "RiskLevel", title: "Risk Level", width: 80, filterable: customAutoCompleteFilter },
                    { field: "DefaultMitigation", title: "Default Mitigated", width: 150, filterable: customBooleanFilter },
                    { field: "BusinessCycleName", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter },
                    { field: "Policy", title: "Policy", width: 130, filterable: customAutoCompleteFilter },
                    { field: "IsAssigned", title: "Is Assigned", width: 120, filterable: customBooleanFilter }
                ];
            } else if (vm.selectedProduct.Type === 'JDE' || vm.selectedProduct.Type === 'SF' || vm.selectedProduct.Type === 'NS' || vm.selectedProduct.Type === 'WD') {
                $scope.mainGridColumns = [
                    { field: "RiskId", title: "Risk Id", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RiskName", title: "Risk Name", width: 150, filterable: customAutoCompleteFilter },
                    { field: "RiskLevel", title: "Risk Level", width: 80, filterable: customAutoCompleteFilter },
                    { field: "BusinessProcessName", title: "Business Process Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "GroupName", title: "Group Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RiskDescription", title: "Risk Description", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                    { field: "SecurityRoleName", title: "Security Role", width: 200, filterable: customAutoCompleteFilter },
                    { field: "SecurityRoleTypeName", title: "Security Role Type", width: 80, filterable: customAutoCompleteFilter },
                    { field: "AccessTypeName", title: "Access Type", width: 190, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectType", title: "Security Object Type", width: 180, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectName", title: "Security Object Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "DefaultMitigation", title: "Default Mitigation", width: 150, filterable: customBooleanFilter },
                    { field: "BusinessCycleName", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter },
                    { field: "Policy", title: "Policy", width: 130, filterable: customAutoCompleteFilter },
                    { field: "IsAssigned", title: "Is Assigned", width: 120, filterable: customBooleanFilter }
                ];
            } else if (vm.selectedProduct.Type === 'CPA' || vm.selectedProduct.Type === 'ORFC') {
                $scope.mainGridColumns = [
                    { field: "RiskId", title: "Risk Id", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RiskName", title: "Risk Name", width: 150, filterable: customAutoCompleteFilter },
                    { field: "RiskLevel", title: "Risk Level", width: 80, filterable: customAutoCompleteFilter },
                    { field: "BusinessProcessName", title: "Business Process Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "GroupName", title: "Group Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RiskDescription", title: "Risk Description", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                    { field: "SecurityRoleName", title: "Security Role", width: 200, filterable: customAutoCompleteFilter },
                    { field: "SecurityRoleTypeName", title: "Security Role Type", width: 80, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectType", title: "Security Object Type", width: 180, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectName", title: "Security Object Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "DefaultMitigation", title: "Default Mitigation", width: 150, filterable: customBooleanFilter },
                    { field: "BusinessCycleName", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter },
                    { field: "Policy", title: "Policy", width: 130, filterable: customAutoCompleteFilter },
                    { field: "IsAssigned", title: "Is Assigned", width: 120, filterable: customBooleanFilter }
                ];
            }
        } else {
            $scope.mainGridColumns = [
                { field: "RiskId", title: "Risk ID", width: 50, filterable: customAutoCompleteFilter },
                { field: "RiskName", title: "Risk Name", width: 120, filterable: customAutoCompleteFilter },
                { field: "RiskLevel", title: "Risk Level", width: 80, filterable: customAutoCompleteFilter },
                { field: "RiskDescription", title: "Risk Description", width: 240, filterable: customAutoCompleteFilter },
                { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                { field: "DefaultMitigation", title: "Default Mitigation", width: 75, filterable: customBooleanFilter },
                { field: "BusinessCycleName", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter },
                { field: "Policy", title: "Policy", width: 130, filterable: customAutoCompleteFilter }
            ];
        }

        // Don't allow filtering and sorting yet because they will force the report to run
        if (!$rootScope.loadedSavedReport) {
            $scope.mainGridOptions.sortable = false;

            $scope.mainGridOptions.filterable = false;
        }

        vm.setLayout();
    };

    vm.setLayout = function () {
        if (!$rootScope.loadedSavedReport) {
            CustomReportServices.loadSavedGridLayout($scope, vm.selectedProduct.ID);
        }
    };


    $scope.filterManagerGridOptions = CustomReportServices.setfilterManagerGridOptions();

    $scope.filterDropdownOptions = helperService.getFilterDropdownOptions();

    $scope.deleteFilter = function (id) {
        CustomReportServices.deleteFilterFilterManager($scope, id);
    };

    $scope.clearAllFilters = function () {
        $scope.gridDataSource.filter({});
    };

    $scope.saveReportLayoutButton = function () {
        CustomReportServices.saveGridLayout($scope, vm.selectedProduct.ID);
    };

    vm.deselectSelectedRisk = function () {
        vm.riskSelectedIds = helperService.deselectAllObjectsIfOtherIsClicked(vm.riskSelectedIds, vm.allRisksDropdownObject);
        $scope.populateCriteriaModel();
    };

    $http.get(apiUrl + 'api/arm/riskrulesets')
        .then(function (response) {
            response.data = $filter('filter')(response.data, { IsEnabled: true });
            vm.rulesetDataSource = response.data;
            vm.rulesetDataSource = $filter('orderBy')(response.data, 'Name');
            vm.selectedRuleset = SavedReportSelectorService.checkSavedReportForRuleset($rootScope.loadedSavedReport, response.data, vm.rulesetDataSource);
            getRisks();
        });

    $scope.rulesetDropdownChange = function () {
        getRisks();
        if (vm.filterCriteria.query && vm.filterCriteria.query.some(criteria => criteria.CriteriaDisplayName.includes("Business Process"))) {
            vm.filterCriteria.query = vm.filterCriteria.query.filter(criteria => {
                if (criteria.CriteriaDisplayName) {
                    return !criteria.CriteriaDisplayName.includes("Business Process");
                } else {
                    return criteria;
                }
            });
            vm.filterCriteria.needsUpdate = true;
        }
        $scope.populateCriteriaModel();
    };

    function getRisks() {
        $http.get(apiUrl + 'api/arm/risks/ruleset/' + vm.selectedRuleset.RulesetId).then(function (response) {
            response.data = $filter('filter')(response.data, { Enabled: true });
            response.data = $filter('orderBy')(response.data, 'Name');
            vm.allRisksDropdownObject = { "Name": "All Risks", "Id": 'AllObjectsInMultiSelect' };
            response.data.unshift(vm.allRisksDropdownObject);
            vm.riskDataSource = response.data;
            vm.riskSelectedIds = [vm.allRisksDropdownObject];
            vm.riskSelectedIds = SavedReportSelectorService.checkSavedReportForRisks($rootScope.loadedSavedReport, response.data, vm.riskSelectedIds);
            $scope.runSavedReport();
        });
    }

    function updateRoleDataSource(roleDataSource, roleSelectedIds) {
        vm.roleDataSource = roleDataSource;
        vm.roleSelectedIds = roleSelectedIds;
        $scope.populateCriteriaModel();
    }

    // --- FOR ADVANCED FILTERING ---
    function getReportMetadata() {
        return reportsDataService.getReportMetadata($scope.reportId)
            .then(data => {

                vm.reportMetadata = data.data._embedded.reportData;
                vm.reportMetadata.Columns = $filter('orderBy')(vm.reportMetadata.Columns, 'DisplayName');
                vm.filterCriteria.fields = reportsService.getFields(vm.reportMetadata.Columns);
                return vm.reportMetadata;
            });
    }
    // ---

}