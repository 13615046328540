import { customAutoCompleteFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";
import businessCycleModalController from "./businessCycleModal.controller";

export default /*@ngInject*/ function ($scope, $filter, businessCyclesDataService, $uibModal, helperService) {

    const vm = this;

    vm.shouldBeOpen = true;

    vm.deleteItem = deleteItem;
    vm.openModal = openModal;

    activate();

    function activate() {
        createBusinessCycleGrid();
    }

    function createBusinessCycleGrid() {
        const businessCyclesGridColumns =
            [
                { field: "Name", title: "Name", filterable: customAutoCompleteFilter },
                { template: `<a ng-click="bcvm.openModal(dataItem)"><i class="fa fa-edit text-warning m-r-xs"></i>Edit</a>`, "title": "Edit", width: 90 },
                { template: `<a ng-click="bcvm.deleteItem(dataItem.ID)"><i class="fa fa-trash-o text-danger m-r-xs"></i>Delete</a>`, "title": "Delete", width: 90 }

            ];

        vm.businessCyclesGridOptions = helperService.setMainGridOptions(businessCyclesGridColumns, 600);
        vm.businessCyclesGridOptions.selectable = false;

        vm.businessCyclesGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    let { data } = await businessCyclesDataService.getBusinessCycles();
                    data = $filter('orderBy')(data, 'Name');
                    options.success(data);
                }
            }
        });
    }

    async function deleteItem(id) {
        try {
            kendo.ui.progress(vm.businessCyclesGrid.element, true);
            await businessCyclesDataService.removeBusinessCycle(id);
            kendo.ui.progress(vm.businessCyclesGrid.element, false);
            vm.businessCyclesGridDataSource.read();
        } catch {
            kendo.ui.progress(vm.businessCyclesGrid.element, false);
        }
    }

    async function openModal(businessCycle) {
        let openModal = $uibModal.open({
            templateUrl: '/App/Components/maintainBusinessCycles/businessCycleModal.html',
            controller: businessCycleModalController,
            controllerAs: 'bcmvm',
            backdrop: 'static',
            scope: $scope,
            resolve: {
                businessCycle: () => businessCycle || {}
            }
        });

        try {
            await openModal.result;
            vm.businessCyclesGridDataSource.read();
        } catch {
            return;
        }
    }
}