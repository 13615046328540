import swal from "sweetalert2";
import riskLevelsModalController from "../riskLevelsModal/riskLevelsModal.controller";
import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($filter, $uibModal, riskLevelsData, helperService) {

    const vm = this;

    vm.$onInit = createRiskLevelsGrid();

    vm.openModal = openModal;
    vm.deleteRisk = deleteRisk;

    function createRiskLevelsGrid() {
        let riskLevelsGridColumns =
            [
                { field: "Name", title: "Name", filterable: customAutoCompleteFilter },
                { template: `<a ng-click="rlgvm.openModal(dataItem)"><i class="fa fa-edit text-warning m-r-xs"></i>Edit</a>`, "title": "Edit", width: 90 },
                { template: `<a ng-click="rlgvm.deleteRisk(dataItem)"><i class="fa fa-trash-o text-danger m-r-xs"></i>Delete</a>`, "title": "Delete", width: 90 }

            ];

        vm.riskLevelsGridOptions = helperService.setMainGridOptions(riskLevelsGridColumns, 600);
        vm.riskLevelsGridOptions.selectable = false;

        vm.riskLevelsGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    let response = await riskLevelsData.getRiskLevels();
                    response.data = $filter('orderBy')(response.data, 'Name');
                    options.success(response.data);
                }
            }
        });
    }

    async function deleteRisk(riskLevel) {
        try {
            await swal(helperService.areYouSureParamsWithHTML('Delete Risk Level',
                `<div>Are you sure you want to delete risk level
                <div><strong>${riskLevel.Name}</strong>?</div>
            </div>`, 'Delete'));
            await riskLevelsData.deleteRiskLevel(riskLevel.Id);
            vm.riskLevel = null;
            vm.riskLevelsGridDataSource.read();
        } catch {
            return;
        }

    }

    async function openModal(riskLevel) {
        let riskLevelModal = $uibModal.open({
            templateUrl: '/App/Components/maintainRiskLevels/riskLevelsModal/riskLevelsModal.html',
            controller: riskLevelsModalController,
            controllerAs: 'rlmvm',
            backdrop: 'static',
            resolve: {
                riskLevel: () => riskLevel
            }
        });

        try {
            await riskLevelModal.result;
            vm.riskLevelsGridDataSource.read();
        } catch {
            return;
        }
    }

}
