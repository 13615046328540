export default /*@ngInject*/ function ($http, helperService, configurationDataService, connectionsDataService) {
    const vm = this;

    vm.helperService = helperService;
    vm.supportedPlugins = [];
    vm.disabledPlugins = [];
    vm.config = {};
    vm.origConfig = {};

    activate();

    async function activate() {
        await setPlugins();
        await setConfig();
        await setHybridConnections;
    }

    async function setPlugins() {
        const { data } = await $http.get(apiUrl + 'api/core/plugins');

        data.forEach(row => {
            vm.supportedPlugins.push(row);
        });

        vm.disabledPlugins = data.filter(plugin => !plugin.Active);
    }

    async function setConfig() {
        const { data } = await configurationDataService.getConfigurationValues();
        var config = {};

        data.forEach(function (row) {
            config[row.Name] = row.Value;
        });

        //Hard-code encrypted
        config['JIRA_Password'] = 'password';
        config['ZDK_Token'] = 'password';
        config['AAD_ClientSecret'] = 'password';
        config['SNOW_Password'] = 'password';
        config['WORKIVA_ClientSecret'] = 'password';
        config['FRESHSERVICE_Password'] = 'password';
        config['AUDITBOARD_Password'] = 'password';
        config['ALA_ClientSecret'] = 'password';
        vm.config = JSON.parse(JSON.stringify(config));
        vm.origConfig = JSON.parse(JSON.stringify(config));
    }

    async function setHybridConnections() {
        const { data } = await connectionsDataService.getHybridConnections();

        vm.hybridConnectionsArray = data;
    }
}