import { customAutoCompleteFilter, standardGridPageSize } from "../../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function (helperService, sapSdSimAnalysisDataService) {
    const vm = this;

    vm.$onInit = setupGrid;

    function setupGrid() {
        vm.gridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    const response = await sapSdSimAnalysisDataService.getRiskAnalysisReportStatistics(vm.simulation.Id, vm.selectedReportType.toLowerCase())
                    options.success(response.data)
                }
            }
        });

        let columns;
        const statusColumn = { title: "Status", field: "Status", width: 125, filterable: customAutoCompleteFilter, template: '<span set-style="#:Status == \'Added\'? \'bafcd4\': Status == \'Removed\'? \'ffd5d6\': Status == \'Existed\'? \'FCF8E3\': null#">{{dataItem.Status}}</span>' };

        switch (vm.selectedReportType) {
            case 'roleRisk':
                columns = [
                    { title: "Role Name", field: "RoleName", filterable: customAutoCompleteFilter },
                    { title: "Risk ID", field: "RiskId", filterable: customAutoCompleteFilter },
                    { title: "Risk Name", field: "RiskName", filterable: customAutoCompleteFilter },
                    { title: "Risk Type", field: "RiskType", filterable: customAutoCompleteFilter }
                ];
                break;
            case 'roleBpAccess':
                columns = [
                    { title: "Role Name", field: "RoleName", filterable: customAutoCompleteFilter },
                    { title: "Object Name", field: "ObjectName", filterable: customAutoCompleteFilter },
                    { title: "Object Type", field: "ObjectType", filterable: customAutoCompleteFilter }
                ];
                break;
            case 'userRisk':
                columns = [
                    { title: "User Name", field: "UserName", filterable: customAutoCompleteFilter },
                    { title: "Company Name", field: "CompanyName", filterable: customAutoCompleteFilter },
                    { title: "Risk ID", field: "RiskId", filterable: customAutoCompleteFilter },
                    { title: "Risk Name", field: "RiskName", filterable: customAutoCompleteFilter },
                    { title: "Risk Type", field: "RiskType", filterable: customAutoCompleteFilter }
                ];
                break;
            case 'userBpAccess':
                columns = [
                    { title: "User Name", field: "UserName", filterable: customAutoCompleteFilter },
                    { title: "Company Name", field: "CompanyName", filterable: customAutoCompleteFilter },
                    { title: "Object Name", field: "ObjectName", filterable: customAutoCompleteFilter },
                    { title: "Object Type", field: "ObjectType", filterable: customAutoCompleteFilter }
                ];
                break;
        }
        columns.push(statusColumn);
        

        vm.gridOptions = helperService.setMainGridOptions(columns, null);
        vm.gridOptions.selectable = false;
    }
}