import { customAutoCompleteFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";
import swal from "sweetalert2";
import savedReportsEditModalController from "./savedReportsEditModal.controller";

export default /*@ngInject*/ function ($filter, savedReportsDataService, helperService, CustomReportServices, $uibModal) {

    const vm = this;
    vm.deleteSavedReport = deleteSavedReport;
    vm.runReport = runReport;
    vm.changeRowSelected = changeRowSelected;
    vm.getFilterDisplayName = getFilterDisplayName;
    vm.getCriteriaDisplayName = getCriteriaDisplayName;
    vm.openEditSavedReportModal = openEditSavedReportModal;

    function activate() {
        const savedReportsGridColumns = [
            { field: "Id", title: "ID", filterable: customAutoCompleteFilter, hidden: true },
            { field: "Name", title: "Name", filterable: customAutoCompleteFilter },
            { field: "Description", title: "Description", filterable: customAutoCompleteFilter },
            { field: "ReportDefinition.ReportName", title: "Report Name", filterable: customAutoCompleteFilter },
            { field: "Owner.UserLogonName", title: "Creator", filterable: customAutoCompleteFilter },
            { template: '<a ng-click="srvm.runReport(dataItem.Id)">Run</a>', "title": "Run", width: 90 },
            { template: '<a ng-click="srvm.openEditSavedReportModal(dataItem.Id)"><i class="fa fa-edit text-warning m-r-xs"></i>Edit</a>', "title": "Edit", width: 90 },
            { template: '<a ng-click="srvm.deleteSavedReport(dataItem.Id)"><i class="fa fa-trash-o text-danger m-r-xs"></i>Delete</a>', "title": "Delete", width: 90, hidden: !helperService.objectExists('1407') }
        ];

        vm.savedReportsGridOptions = helperService.setMainGridOptions(savedReportsGridColumns, 600);

        vm.savedReportsGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: function (options) {
                    savedReportsDataService.getSavedReports().then(function (response) {
                        response.data = $filter('orderBy')(response.data, 'Name');
                        vm.allSavedReports = response.data;
                        options.success(response.data);
                    });
                }
            }
        });
    }

    activate();


    function deleteSavedReport(reportId) {
        savedReportsDataService.getSavedReportSchedules(reportId).then(function (response) {
            if (response.data.length > 0) {
                swal(helperService.areYouSureParams('Existing Schedule(s)', 'Are you sure you want to delete this saved report? There is one or more associated schedules.', 'Delete')).then(function () {
                    deleteReport(reportId);
                }, function (dismiss) {
                    return;
                });
            } else {
                deleteReport(reportId);
            }
        });

    }

    function openEditSavedReportModal(reportId) {
        vm.reportId = reportId;
        vm.selectedReport = vm.allSavedReports.find(r => r.Id === reportId);
        let editModal = $uibModal.open({
            templateUrl: '/App/Components/SavedReports/savedReportsEditModal.tmpl.html',
            controller: savedReportsEditModalController,
            controllerAs: 'sremvm',
            backdrop: 'static',
            resolve: {
                reportId: () => vm.reportId,
                selectedReport: () => vm.selectedReport
            }
        });

        editModal.result
            .then(_ => {
               vm.savedReportsGridDataSource.read();
            }).catch(_ => {
                return;
            });
    }

    function deleteReport(reportId) {
        savedReportsDataService.removeSavedReport(reportId)
            .then(_ => {
                vm.savedReportsGridDataSource.read();
                vm.currentSelectedSavedReport = null;
            }).catch(response => {
                helperService.showErrorMessage(response.data);
            });
    }

    function runReport(savedReportId) {
        var savedReport = vm.allSavedReports.find(report => report.Id === savedReportId);
        CustomReportServices.loadSavedReport(savedReport);
    }

    function changeRowSelected(dataItem) {

        if (dataItem) {
            vm.currentSelectedSavedReport = vm.allSavedReports.find(e => e.Id === dataItem.Id);
            vm.savedReportCriteriaLength = vm.currentSelectedSavedReport.ReportDefinition.Criteria.filter(criteria => criteria.CriteriaType).length;
            if (vm.currentSelectedSavedReport.ReportDefinition.Criteria.some(criteria => !criteria.CriteriaType)) {
                const criteriaFiltersLength = vm.currentSelectedSavedReport.ReportDefinition.Criteria.filter(criteria => !criteria.CriteriaType).length;
                vm.savedReportFiltersLength = vm.currentSelectedSavedReport.ReportDefinition.Filters.length + criteriaFiltersLength;
            } else {
                vm.savedReportFiltersLength = vm.currentSelectedSavedReport.ReportDefinition.Filters.length;
            }
            savedReportsDataService.getSavedReportSchedules(dataItem.Id).then(response => {
                vm.schedulesForCurrentSelectedSavedReport = response.data;
            });
        } else {
            vm.currentSelectedSavedReport = null;
        }

    }

    function getFilterDisplayName(fieldName) {
        //Find the column Display Name for the filter. This is used for display purposes
        var displayName = "";
        var column = vm.currentSelectedSavedReport.ReportDefinition.Columns.find(column => {
            var columnUpper = column.FieldName.toUpperCase();
            return columnUpper === fieldName.toUpperCase();
        });
        if (column) {
            displayName = column.DisplayName;
        } else {
            displayName = $filter('insertSpaceBetweenCapitalWords')(fieldName);
        }

        return displayName;
    }

    function getCriteriaDisplayName(criteria) {
        //Find the column Display Name for the Criteria. This is used for display purposes
        var displayName = "";
        if (criteria.CriteriaDisplayName) {
            displayName = criteria.CriteriaDisplayName;
        } else {
            displayName = criteria.CriteriaType;
        }

        return displayName;
    }
}
