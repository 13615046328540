export default /*@ngInject*/ function ($routeProvider, $locationProvider) {
    $routeProvider.when('/', { template: '<home></home>', caseInsensitiveMatch: true, data: {} });


    //-- Access Control --

    //-- ARM Places --
    $routeProvider.when('/AccessControl/Place/RoleAccess', { templateUrl: '/App/Components/roleAccess/roleAccess.html', caseInsensitiveMatch: true, data: { IsPlaceReport: true, ViewLocation: 'App/Components/roleAccess/roleAccess.html', PlaceName: 'Role Access' } });
    $routeProvider.when('/AccessControl/Place/RoleAccessDetailed', { templateUrl: '/App/Components/roleAccess/roleAccess.html', caseInsensitiveMatch: true, data: { IsPlaceReport: true, ViewLocation: 'App/Components/roleAccess/roleAccess.html', PlaceName: 'Role Access Detailed' } });
    $routeProvider.when('/AccessControl/Place/ResponsibilityAccess', { templateUrl: '/App/Components/responsibilityAccess/responsibilityAccess.html', caseInsensitiveMatch: true, data: { IsPlaceReport: true, ViewLocation: 'App/Components/responsibilityAccess/responsibilityAccess.html', PlaceName: 'Responsibility Access' } });
    $routeProvider.when('/AccessControl/Place/ResponsibilityAccessDetailed', { templateUrl: '/App/Components/responsibilityAccess/responsibilityAccess.html', caseInsensitiveMatch: true, data: { IsPlaceReport: true, ViewLocation: 'App/Components/responsibilityAccess/responsibilityAccess.html', PlaceName: 'Responsibility Access Detailed' } });
    $routeProvider.when('/AccessControl/Place/UserAccess', { templateUrl: '/App/Components/userAccess/userAccess.html', caseInsensitiveMatch: true, data: { IsPlaceReport: true, ViewLocation: 'App/Components/userAccess/userAccess.html', PlaceName: 'User Access' } });
    $routeProvider.when('/AccessControl/Place/UserAccessDetailed', { templateUrl: '/App/Components/UserAccessDetailed/UserAccessDetailedView.html', caseInsensitiveMatch: true, data: { IsPlaceReport: true, ViewLocation: 'App/Components/UserAccessDetailed/UserAccessDetailedView.html', PlaceName: 'User Access Detailed' } });
    $routeProvider.when('/AccessControl/Place/Dynamics365forFinanceandOperationsQuantification', { templateUrl: '/App/Components/D365ArmQuantification/D365ArmQuantification.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/AccessControl/Place/RoleRisk', { templateUrl: '/App/Components/roleRisks/roleRisks.html', caseInsensitiveMatch: true, data: { IsPlaceReport: true, ViewLocation: 'App/Components/roleRisks/roleRisks.html', PlaceName: 'Role Risk' } });
    $routeProvider.when('/AccessControl/Place/RoleRiskDetailed', { templateUrl: '/App/Components/roleRisks/roleRisks.html', caseInsensitiveMatch: true, data: { IsPlaceReport: true, ViewLocation: 'App/Components/roleRisks/roleRisks.html', PlaceName: 'Role Risk Detailed' } });
    $routeProvider.when('/AccessControl/Place/SAPCodeChecker', { templateUrl: '/App/Components/SAPCodeChecker/SAPCodeCheckerView.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/AccessControl/Place/SAPRiskQuantification', { templateUrl: '/App/Components/SAPRiskQuantification/SAPRiskQuantification.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/AccessControl/Place/NetSuiteRiskQuantification', { templateUrl: '/App/Components/NetSuiteArmQuantification/NSQuantificationSelection.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/AccessControl/Place/NetSuiteRiskQuantification/Details', { templateUrl: '/App/Components/NetSuiteArmQuantification/NetSuiteArmQuantificationView.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/AccessControl/Place/RoleBusinessProcess', { templateUrl: '/App/Components/roleBusinessProcess/roleBusinessProcess.html', caseInsensitiveMatch: true, data: { IsPlaceReport: true, ViewLocation: 'App/Components/roleBusinessProcess/roleBusinessProcess.html', PlaceName: 'Role Business Process' } });
    $routeProvider.when('/AccessControl/Place/RoleBusinessProcessDetailed', { templateUrl: '/App/Components/roleBusinessProcess/roleBusinessProcess.html', caseInsensitiveMatch: true, data: { IsPlaceReport: true, ViewLocation: 'App/Components/roleBusinessProcess/roleBusinessProcess.html', PlaceName: 'Role Business Process Detailed' } });
    $routeProvider.when('/AccessControl/Place/UserBusinessProcess', { templateUrl: '/App/Components/userBusinessProcess/userBusinessProcess.html', caseInsensitiveMatch: true, data: { IsPlaceReport: true, ViewLocation: 'App/Components/userBusinessProcess/userBusinessProcess.html', PlaceName: 'User Business Process' } });
    $routeProvider.when('/AccessControl/Place/UserBusinessProcessDetailed', { templateUrl: '/App/Components/UserBusinessProcessDetailed/userBusinessProcessDetailed.html', caseInsensitiveMatch: true, data: { IsPlaceReport: true, ViewLocation: 'App/Components/UserBusinessProcessDetailed/userBusinessProcessDetailed.html', PlaceName: 'User Business Process Detailed' } });
    $routeProvider.when('/AccessControl/Place/ResponsibilityRisk', { templateUrl: '/App/Components/responsibilityRisk/responsibilityRisk.html', caseInsensitiveMatch: true, data: { IsPlaceReport: true, ViewLocation: 'App/Components/responsibilityRisk/responsibilityRisk.html', PlaceName: 'Responsibility Risk' } });
    $routeProvider.when('/AccessControl/Place/ResponsibilityRiskDetailed', { templateUrl: '/App/Components/responsibilityRisk/responsibilityRisk.html', caseInsensitiveMatch: true, data: { IsPlaceReport: true, ViewLocation: 'App/Components/responsibilityRisk/responsibilityRisk.html', PlaceName: 'Responsibility Risk Detailed' } });
    $routeProvider.when('/AccessControl/Place/UserRisk', { templateUrl: '/App/Components/userRisk/userRisk.html', caseInsensitiveMatch: true, data: { IsPlaceReport: true, ViewLocation: 'App/Components/userRisk/userRisk.html', PlaceName: 'User Risk' } });
    $routeProvider.when('/AccessControl/Place/UserRiskDetailed', { templateUrl: '/App/Components/userRiskDetailed/userRiskDetailed.html', caseInsensitiveMatch: true, data: { IsPlaceReport: true, ViewLocation: 'App/Components/userRiskDetailed/userRiskDetailed.html', PlaceName: 'User Risk Detailed' } });
    $routeProvider.when('/AccessControl/Place/UserRiskRoleDetail', { templateUrl: '/App/Components/userRiskDetailed/userRiskDetailed.html', caseInsensitiveMatch: true, data: { IsPlaceReport: true, ViewLocation: 'App/Components/userRiskDetailed/userRiskDetailed.html', PlaceName: 'User Risk Role Detail' } });
    $routeProvider.when('/AccessControl/Place/NetSuiteSecurityDesigner', { templateUrl: '/App/Components/NetSuiteSecurityDesigner/NetSuiteSecurityDesignerView.html', caseInsensitiveMatch: true, data: {} });

    //-- ARM Setup --
    $routeProvider.when('/AccessControl/Setup/maintainRiskRuleset', { templateUrl: '/App/Components/maintainRiskRuleset/maintainRiskRuleset.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/AccessControl/Setup/maintainMitigations', { template: '<maintain-mitigations></maintain-mitigations>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/AccessControl/Setup/maintainRiskLevels', { templateUrl: '/App/Components/maintainRiskLevels/riskLevels.tmpl.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/AccessControl/Setup/maintainBusinessCycles', { template: '<maintain-business-cycles></maintain-business-cycles>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/AccessControl/Setup/maintainStatuses', { template: '<maintain-statuses></maintain-statuses>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/AccessControl/Setup/assignRisks', { template: '<assign-risks></assign-risks>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/AccessControl/Setup/transferRuleset', { templateUrl: '/App/Components/transferRuleset/transferRuleset.html', caseInsensitiveMatch: true });
    $routeProvider.when('/AccessControl/Setup/manageArchives', { templateUrl: '/App/Components/ManageArchives/ManageArchivesView.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/AccessControl/Setup/startNewAudit', { template: '<start-new-audit></start-new-audit>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/AccessControl/Setup/maintainbusinessprocesses', { templateUrl: '/App/Components/MaintainBusinessProcess/maintainBusinessProcess.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/AccessControl/Setup/controllibrary', { template: '<control-library></control-library>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/AccessControl/Setup/OracleAutoMitigations', { templateUrl: '/App/Components/Oracle/ApplyAutoMitigation/ApplyAutoMitigationView.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/AccessControl/Setup/OracleCloudAutoMitigations', { template: '<auto-mitigations product="$resolve.product"></auto-mitigations>', caseInsensitiveMatch: true, resolve: { product: function () { return 'orfc'; } } });
    $routeProvider.when('/AccessControl/Setup/PeoplesoftAutoMitigations', { template: '<peoplesoft-auto-mitigations></peoplesoft-auto-mitigations>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/AccessControl/Setup/ApplyDefaultMitigations', { template: '<apply-default-mitigations></apply-default-mitigations>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/AccessControl/Setup/SAPMaintainPowerfulProfiles', { template: '<maintain-profiles></maintain-profiles>', caseInsensitiveMatch: true, data: {} });

    //-- Access Certifications --
    $routeProvider.when('/AccessCertifications/Report/:reportName/:objectID', { templateUrl: '/App/Components/DynamicReports/ReportViewer.html', caseInsensitiveMatch: true, data: { IsReport: true } });
    $routeProvider.when('/AccessCertifications/Place/MyReviews', { templateUrl: '/App/Components/Certifications/MyReviews/myReviews.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/AccessCertifications/Place/AllReviews', { templateUrl: '/App/Components/Certifications/AllReviews/certificationsAllReview.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/AccessCertifications/Setup/ReviewSchedules', { template: '<review-schedules></review-schedules>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/AccessCertifications/Setup/NotificationTeams', { template: '<notification-teams></notification-teams>', caseInsensitiveMatch: true, data: {} });

    //-- Transaction Monitoring --
    $routeProvider.when('/TransactionMonitoring/Place/OracleControlManagement', { template: '<control-management></control-management>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/TransactionMonitoring/Report/:reportName/:objectID', { templateUrl: '/App/Components/DynamicReports/ReportViewer.html', caseInsensitiveMatch: true, data: { IsReport: true } });
    $routeProvider.when('/TransactionMonitoring/Place/OracleIncidents', { template: '<transaction-monitoring-incident-management></transaction-monitoring-incident-management>', caseInsensitiveMatch: true, data: { IsPlaceReport: true, isComponent: true, ViewLocation: 'transaction-monitoring-incident-management', PlaceName: 'Oracle Incidents' } });

    //-- Audit Trail --
    $routeProvider.when('/ChangeTracking/Setup/MaintainTrackingTemplates', { template: '<maintain-template></maintain-template>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/ChangeTracking/Setup/WorkdayMaintainTemplates', { template: '<workday-maintain-templates></workday-maintain-templates>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/ChangeTracking/Setup/ApplyTrackingTemplates', { template: '<apply-tracking-templates></apply-tracking-templates>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/ChangeTracking/Setup/TrackingTemplateDetails', { template: '<template-details></template-details>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/ChangeTracking/Setup/ReportGenerator', { template: '<report-generator></report-generator>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/ChangeTracking/Setup/MaintainOptionSets', { template: '<maintain-option-sets></maintain-option-sets>', caseInsensitiveMatch: true });
    $routeProvider.when('/ChangeTracking/Setup/MaintainTemplates', { templateUrl: '/App/Components/NetSuiteAuditTrail/MaintainTemplates/maintainTemplates.html', caseInsensitiveMatch: true });
    $routeProvider.when('/ChangeTracking/Setup/OracleCloudMaintainProducts', { template: '<maintain-templates></maintain-templates>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/ChangeTracking/Setup/OracleCloudMaintainSnapshotReports', { template: '<maintain-snapshots></maintain-snapshots>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/ChangeTracking/Setup/MaintainUserGroups', { template: '<maintain-user-groups></maintain-user-groups>', caseInsensitiveMatch: true });
    $routeProvider.when('/ChangeTracking/Setup/SalesforceMaintainObjectTracking', { template: '<maintain-object-tracking></maintain-object-tracking>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/ChangeTracking/Place/ManageDocumentation', { template: '<manage-documentation></manage-documentation>', caseInsensitiveMatch: true });
    $routeProvider.when('/ChangeTracking/Place/NetSuiteAllChanges', { template: '<all-changes></all-changes>', caseInsensitiveMatch: true, data: { IsPlaceReport: true, isComponent: true, ViewLocation: 'all-changes', PlaceName: 'NetSuite All Changes' } });
    $routeProvider.when('/ChangeTracking/Place/CurrentSnapshot', { template: '<current-snapshot></current-snapshot>', caseInsensitiveMatch: true, data: { IsPlaceReport: true, isComponent: true, ViewLocation: 'current-snapshot', PlaceName: 'Current Snapshot' } });
    $routeProvider.when('/ChangeTracking/Place/DataChanges', { templateUrl: '/App/Components/DataChanges/DataChangesView.html', caseInsensitiveMatch: true, data: { IsPlaceReport: true, ViewLocation: 'App/Components/DataChanges/DataChangesView.html', PlaceName: 'Data Changes' } });
    $routeProvider.when('/ChangeTracking/Place/SalesforceDataChanges', { templateUrl: '/App/Components/DataChanges/DataChangesView.html', caseInsensitiveMatch: true, data: { IsPlaceReport: true, ViewLocation: 'App/Components/DataChanges/DataChangesView.html', PlaceName: 'Data Changes' } });
    $routeProvider.when('/ChangeTracking/Place/WorkdayDataChanges', { templateUrl: '/App/Components/DataChanges/DataChangesView.html', caseInsensitiveMatch: true, data: { IsPlaceReport: true, ViewLocation: 'App/Components/DataChanges/DataChangesView.html', PlaceName: 'Data Changes' } });
    $routeProvider.when('/ChangeTracking/Place/SalesforceSetupDataChanges', { template: '<setup-changes></setup-changes>', caseInsensitiveMatch: true, data: { IsPlaceReport: true, ViewLocation: 'setup-changes', PlaceName: 'Setup Changes', isComponent: true } });
    $routeProvider.when('/ChangeTracking/Place/NetSuiteDataChanges', { template: '<netsuite-data-changes></netsuite-data-changes>', caseInsensitiveMatch: true, data: { IsPlaceReport: true, isComponent: true, ViewLocation: 'netsuite-data-changes', PlaceName: 'NetSuite Data Changes' } });
    $routeProvider.when('/ChangeTracking/Place/OracleCloudDataChanges', { template: '<oracle-cloud-data-changes></oracle-cloud-data-changes>', caseInsensitiveMatch: true, data: { IsPlaceReport: true, isComponent: true, ViewLocation: 'oracle-cloud-data-changes', PlaceName: 'Oracle Cloud Data Changes' } });
    $routeProvider.when('/ChangeTracking/Place/SnapshotChanges', { template: '<snapshot-changes></snapshot-changes>', caseInsensitiveMatch: true, data: { IsPlaceReport: true, isComponent: true, ViewLocation: 'snapshot-changes', PlaceName: 'Snapshot Changes' } });
    $routeProvider.when('/ChangeTracking/Place/SnapshotComparison', { template: '<snapshot-comparison></snapshot-comparison>', caseInsensitiveMatch: true, data: { IsPlaceReport: true, isComponent: true, ViewLocation: 'snapshot-comparison', PlaceName: 'Snapshot Comparison' } });
    $routeProvider.when('/ChangeTracking/Setup/DataChangesConfiguration', { template: '<netsuite-data-changes-configuration></netsuite-data-changes-configuration>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/ChangeTracking/Setup/SnapshotConfiguration', { template: '<snapshot-configuration></snapshot-configuration>', caseInsensitiveMatch: true });
    $routeProvider.when('/ChangeTracking/Setup/ManageData', { template: '<manage-data></manage-data>', caseInsensitiveMatch: true, data: {} });

    //-- Identity Manager --
    $routeProvider.when('/IdentityManager/Setup/ApprovalRules', { template: '<approval-rules></approval-rules>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/IdentityManager/Place/OpenRequests', { template: '<open-requests></open-requests>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/IdentityManager/Place/OutstandingApprovals', { template: '<outstanding-approvals></outstanding-approvals>', caseInsensitiveMatch: true, data: {} });

    $routeProvider.when('/IdentityManager/Place/RequestHistory', { template: '<request-history></request-history>', caseInsensitiveMatch: true, data: { IsPlaceReport: true, isComponent: true, ViewLocation: 'request-history', PlaceName: 'Request History' } });
    $routeProvider.when('/IdentityManager/Place/RequestHistoryDetailed', { template: '<request-history></request-history>', caseInsensitiveMatch: true, data: { IsPlaceReport: true, isComponent: true, ViewLocation: 'request-history', PlaceName: 'Request History Detailed' } });

    $routeProvider.when('/IdentityManager/Place/RequestManagement', { templateUrl: '/App/Components/RequestManagement/RequestManagementSelection.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/IdentityManager/Place/RequestManagement/New', { templateUrl: '/App/Components/RequestManagement/Paths/RequestManagementNewView.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/IdentityManager/Place/RequestManagement/Modify', { templateUrl: '/App/Components/RequestManagement/Paths/RequestManagementModifyView.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/IdentityManager/Place/RequestManagement/Terminate', { templateUrl: '/App/Components/RequestManagement/Paths/RequestManagementTerminateView.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/IdentityManager/RequestDetails', { templateUrl: '/App/Components/IdentityManagerRequestDetails/IdentityManagerRequestDetailsView.html', caseInsensitiveMatch: true });
    $routeProvider.when('/IdentityManager/Review', { templateUrl: '/App/Components/IdentityManagerRequestDetails/IdentityManagerRequestDetailsView.html', caseInsensitiveMatch: true });

    //-- Security Designer --
    $routeProvider.when('/SecurityDesigner/Place/Dynamics365forFinanceandOperationsRoles', { templateUrl: '/App/Components/AXSecurityDesigner/Roles/AXSecurityDesignerRolesView.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/SecurityDesigner/Place/Dynamics365forFinanceandOperationsDuties', { templateUrl: '/App/Components/AXSecurityDesigner/Duties/AXSecurityDesignerDutiesView.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/SecurityDesigner/Place/Dynamics365forFinanceandOperationsPrivileges', { templateUrl: '/App/Components/AXSecurityDesigner/Privileges/AXSecurityDesignerPrivilegesView.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/SecurityDesigner/Place/Dynamics365forFinanceandOperationsSecurityModels', { templateUrl: '/App/Components/AXSecurityDesigner/SecurityModels/AXSecurityDesignerSecurityModelsView.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/SecurityDesigner/Dynamics365forFinanceandOperationsSimulationDetails', { templateUrl: '/App/Components/AXSecurityDesigner/SimulationDetails/AXSecurityDesignerSimulationDetailsView.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/SecurityDesigner/Place/Dynamics365forFinanceandOperationsTaskRecordings', { templateUrl: '/App/Components/AXSecurityDesigner/taskRecordingManager/taskRecordingManager.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/SecurityDesigner/Place/DynamicsAXTaskRecordings', { templateUrl: '/App/Components/AXSecurityDesigner/taskRecordingManager/taskRecordingManager.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/SecurityDesigner/Place/Dynamics365forFinanceandOperationsSecurityMigration', { template: '<ax-security-designer-security-migration></ax-security-designer-security-migration>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/SecurityDesigner/Place/SAPSimulations', { template: '<sap-sd-simulations></sap-sd-simulations>', caseInsensitiveMatch: true, data: {} });

    //-- Report Management --
    $routeProvider.when('/ReportManagement/Place/SavedReports', { template: '<saved-reports></saved-reports>' });
    $routeProvider.when('/ReportManagement/Place/Schedules', { template: '<schedules></schedules>' });

    //-- System Configuration --
    $routeProvider.when('/SystemConfiguration/Place/ApprovalGroups', { template: '<approval-groups></approval-groups>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/SystemConfiguration/Place/CompanyTemplates', { template: '<company-templates></company-templates>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/SystemConfiguration/Place/CrossPlatformCompanies', { template: '<company-mapping></company-mapping>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/SystemConfiguration/Place/CrossPlatformUsers', { template: '<cross-platform-users></cross-platform-users>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/SystemConfiguration/Place/MaintainSystemRoles', { template: '<maintain-system-roles></maintain-system-roles>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/SystemConfiguration/Place/MaintainSystemUsers', { template: '<maintain-system-users></maintain-system-users>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/SystemConfiguration/Place/Notifications', { template: '<notifications></notifications>', caseInsensitiveMatch: true });
    $routeProvider.when('/SystemConfiguration/Place/OwnershipGroups', { template: '<ownership-groups></ownership-groups>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/SystemConfiguration/Place/RequesterGroups', { template: '<requester-groups></requester-groups>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/SystemConfiguration/Place/RoleTemplates', { templateUrl: '/App/Components/RoleTemplates/roleTemplates.tmpl.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/SystemConfiguration/Place/SupervisorAssignments', { template: '<supervisor-assignments></supervisor-assignments>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/SystemConfiguration/Place/SystemSettings', { template: '<configuration></configuration>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/SystemConfiguration/Place/UserRestrictions', { templateUrl: 'App/Components/userRestrictions/userRestrictions.tmpl.html', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/SystemConfiguration/Setup/ExcludedUsers', { template: '<excluded-users></excluded-users>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/SystemConfiguration/Setup/ExcludedRoles', { template: '<excluded-roles></excluded-roles>', caseInsensitiveMatch: true, data: {} });

    //-- Integrations --
    $routeProvider.when('/Integrations/Place/Connections', { template: '<connections></connections>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/Integrations/Place/Plugins', { template: '<plugins></plugins>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/Integrations/Place/APIClients', { template: '<api-clients></api-clients>', caseInsensitiveMatch: true, data: {} });

    //-- Security Setup --
    $routeProvider.when('/SecuritySetup/Place/AuditLogs', { template: '<audit-logs></audit-logs>', caseInsensitiveMatch: true, data: { IsPlaceReport: true, isComponent: true, ViewLocation: 'audit-logs', PlaceName: 'Audit Logs' } });
    $routeProvider.when('/SecuritySetup/Place/Roles', { template: '<roles></roles>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/SecuritySetup/Place/SecurityAssignment', { template: '<security-assignment></security-assignment>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/SecuritySetup/Place/Users', { template: '<users></users>', caseInsensitiveMatch: true, data: {} });
    
    //-- Dynamic Report Viewers --
    $routeProvider.when('/SecurityDesigner/Report/:reportName/:objectID', { templateUrl: '/App/Components/DynamicReports/ReportViewer.html', caseInsensitiveMatch: true, data: { IsReport: true } });
    $routeProvider.when('/AccessControl/Report/:reportName/:objectID', { templateUrl: '/App/Components/DynamicReports/ReportViewer.html', caseInsensitiveMatch: true, data: { IsReport: true } });
    $routeProvider.when('/SecuritySetup/Report/:reportName/:objectID', { templateUrl: '/App/Components/DynamicReports/ReportViewer.html', caseInsensitiveMatch: true, data: { IsReport: true } });
    $routeProvider.when('/SystemConfiguration/Report/:reportName/:objectID', { templateUrl: '/App/Components/DynamicReports/ReportViewer.html', caseInsensitiveMatch: true, data: { IsReport: true } });
    $routeProvider.when('/ReportManagement/Report/:reportName/:objectID', { templateUrl: '/App/Components/DynamicReports/ReportViewer.html', caseInsensitiveMatch: true, data: { IsReport: true } });
    $routeProvider.when('/IdentityManager/Report/:reportName/:objectID', { templateUrl: '/App/Components/DynamicReports/ReportViewer.html', caseInsensitiveMatch: true, data: { IsReport: true } });
    $routeProvider.when('/ChangeTracking/Report/:reportName/:objectID', { templateUrl: '/App/Components/DynamicReports/ReportViewer.html', caseInsensitiveMatch: true, data: { IsReport: true } });
    $routeProvider.when('/Integrations/Report/:reportName/:objectID', { templateUrl: '/App/Components/DynamicReports/ReportViewer.html', caseInsensitiveMatch: true, data: { IsReport: true } });

    //-- No Module --
    $routeProvider.when('/SignReport', { template: '<sign-report-from-email></sign-report-from-email>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/Downloads', { template: '<my-downloads></my-downloads>', caseInsensitiveMatch: true, data: {} });
    $routeProvider.when('/Inbox', { template: '<my-inbox></my-inbox>', caseInsensitiveMatch: true, data: {} });

    //-- Security Error --
    $routeProvider.when('/403', { templateUrl: '/App/Shared/Partials/SecurityError.html', caseInsensitiveMatch: true, data: {} });

    $routeProvider.otherwise({ redirectTo: '/' });
    $locationProvider.html5Mode(true).hashPrefix('!');

}