import { customAutoCompleteFilter, standardGridPageSize } from "../../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, userGroupsDataService, helperService, $filter, userGroup, usersDataSource) {

    var vm = this;
    vm.cancel = cancel;
    vm.usersDataSource = usersDataSource;

    vm.addUserToUserGroup = addUserToUserGroup;

    var availableGridColumns = [
        { title: "Name", field: "Name", filterable: customAutoCompleteFilter },
        { template: "<a ng-click='umvm.addUserToUserGroup(dataItem)'><i class='fa fa-plus pull-right'></i></a>", title: " ", width: 20 }
    ];

    vm.availableUsersGridOptions = helperService.setMainGridOptions(availableGridColumns, null);

    activate();

    function activate() {
        configureUsersGrid();
    }

    function cancel() { $uibModalInstance.close(); }

    async function configureUsersGrid() {
        await getAllUsers();
        vm.usersDataSource.forEach(user => {
            vm.availableUsers = vm.availableUsers.filter(u => u.InternalId !== user.UserGroupUserInternalId);
        });
        vm.availableUsersGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: (options) => {
                    vm.availableUsers = $filter('orderBy')(vm.availableUsers, 'Name');
                    options.success(vm.availableUsers);
                }
            }
        });
    }

    async function getAllUsers() {
        let response = await userGroupsDataService.getUsers();
        response.data = response.data.filter(user => !user.Inactive);
        vm.availableUsers = response.data;
    }

    async function addUserToUserGroup(user) {
        var data = {
            UserGroupId: userGroup.Id,
            UserGroupUserInternalId: user.InternalId
        };

        await userGroupsDataService.addUserToUserGroup(userGroup.Id, data);
        vm.availableUsers = vm.availableUsers.filter(u => u.InternalId !== user.InternalId);
        vm.availableUsersGridDataSource.read();
    }
}