import { customAutoCompleteFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../Shared/kendo.module";
import swal from "sweetalert2";
import createSpinner from "../../Shared/Functions/createSpinner";
import importUserMappingModalController from "./importUserMappingModal.controller";
import newCrossPlatformUserModalController from "./NewCrossPlatformUserModal/newCrossPlatformUserModal.controller";

export default /*@ngInject*/ function ($scope, $uibModal, crossPlatformUsersDataService, $q, helperService) {

    const vm = this;

    vm.helperService = helperService;

    vm.autoMapUpdatePending = {};
    vm.autoMapUpdatePending.loadingValue = false;

    vm.newEditCrossPlatformUserModal = newEditCrossPlatformUserModal;
    vm.openImportAutoMapModal = openImportAutoMapModal;
    vm.deleteUserMaster = deleteUserMaster;
    vm.exportAutoMapping = exportAutoMapping;

    //Update successful inline saver
    $scope.$on('ImportUserMappings', function (event, args) {
        vm.gridDataSource.read();
    });
    $scope.$on('ExportUserMappings', function (event, args) {
        helperService.successfulSaveButton(vm.exportUpdatePending);
    });

    //When the auto mapper web job completes update the statistics and main grid
    $scope.$on('ImportUserMappings', (event, args) => updateAutoMapperStatistics(event, args));

    function activate() {
        setColumns();
        createGridDataSource();
    }

    activate();

    async function newEditCrossPlatformUserModal(modalType, dataItem) {
        if (modalType === 'New') {
            vm.lastSelectedUser = null;
        } else {
            vm.lastSelectedUser = angular.copy(dataItem);
        }

        let newEditModal = $uibModal.open({
            templateUrl: '/App/Components/CrossPlatformUsers/NewCrossPlatformUserModal/newCrossPlatformUserModal.tmpl.html',
            controller: newCrossPlatformUserModalController,
            controllerAs: 'ncpumvm',
            backdrop: 'static',
            size: 'lg',
            scope: $scope,
            resolve: {
                lastSelectedUser: () => vm.lastSelectedUser,
                modalName: () => modalType,
                availableProducts: () => vm.availableProducts
            }
        });

        try {
            await newEditModal.result;
            await vm.gridDataSource.read();
        } catch {
            return;
        }
    }

    vm.lockNewUserButton = true;

    async function setColumns() {
        let response = await crossPlatformUsersDataService.getProducts();
        vm.availableProducts = response.data;

        vm.lockNewUserButton = false;

        let actionsColumnTemplate = `<div class="btn-group" role="group">
                                     <button class="btn btn-xs btn-secondary" ng-click="cpuvm.newEditCrossPlatformUserModal('Edit', dataItem)"><i class="fa fa-edit text-warning"></i> Edit</button>
                                     <button class="btn btn-xs btn-secondary" ng-click="cpuvm.deleteUserMaster(dataItem)"><i class="fa fa-trash-o text-danger"></i> Delete</button>
                                     </div>`;

        let columnsFromVM = [
            { field: "Name", title: "User Name", filterable: customAutoCompleteFilter },
            { field: "Email", title: "Email", filterable: customAutoCompleteFilter },
            { field: "Products", title: "Products", filterable: customAutoCompleteFilter },
            { title: "Actions", width: 140, template: actionsColumnTemplate, filterable: false },
            { field: "MappingStatus", title: "Mapping Status", headerTemplate: '<div class="flex-between"><label for="mapping-status" style="font-weight:400;">Mapping Status &nbsp;</label><span class="badge badge-primary">{{cpuvm.mappingStatusRowCount}}</span></div>', filterable: customAutoCompleteFilter }
        ];

        vm.mainGridOptions = helperService.setMainGridOptions(columnsFromVM, 600);
        vm.mainGridOptions.selectable = false;
    }

    function updateAutoMapperStatistics(event, args) {
        let promises = [];

        promises.push(vm.mainGrid.dataSource.read());
        //After the grid and statistics have been updated remove the loading icon in the 'Auto Map' section
        $q.all(promises).then(function () {
            vm.autoMapUpdatePending.loadingValue = false;
        });
    }

    function createGridDataSource() {
        vm.gridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: halSchemaKendoGrid,
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: async options => {
                    let data = {};
                    data = helperService.prepareSortsAndFilters(options);

                    try {                  
                        let response = await crossPlatformUsersDataService.updateGrid(options.data.pageSize, options.data.page, data);
                        options.success(response.data);

                        vm.mappingStatusRowCount = response.data.TotalItems;
                    } catch (err) {
                        options.error(err);
                    }
                }
            }
        });
    }

    async function deleteUserMaster(dataItem) {
        await swal(helperService.areYouSureParams('Delete User', 'All data associated with this user will be lost. Are you sure you want to remove this user?', 'Delete'));

            let postData = {
                "Input": {
                    "ID": dataItem.ID,
                    "Name": dataItem.Name,
                    "Email": dataItem.Email,
                    "UserMappings": []
                }
            };

            try {
                await crossPlatformUsersDataService.deleteUserMaster(postData);
                await vm.mainGrid.dataSource.read();
            } catch {
                return;
            }
    }

    function openImportAutoMapModal() {
        $uibModal.open({
            templateUrl: '/App/Components/CrossPlatformUsers/importUserMappingModal.tmpl.html',
            controller: importUserMappingModalController,
            controllerAs: 'iammvm',
            backdrop: 'static',
            size: 'lg',
            scope: $scope
        });
    }

    async function exportAutoMapping() {
        vm.exportUpdatePending = new createSpinner();
        try {
            await crossPlatformUsersDataService.exportAutoMapping();
        } catch {
            return;
        }      
    }

}

