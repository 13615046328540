import { customAutoCompleteFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";
import statusModalController from "./statusModal.controller";


export default /*@ngInject*/ function ($scope, $filter, $uibModal, helperService, statusDataService) {

    const vm = this;

    vm.shouldBeOpen = true;

    vm.deleteItem = deleteItem;
    vm.openModal = openModal;

    function activate() {
        const statusGridColumns =
            [
                { field: "Name", title: "Name", filterable: customAutoCompleteFilter },
                { template: `<a ng-click="msvm.openModal(dataItem)"><i class="fa fa-edit text-warning m-r-xs"></i>Edit</a>`, "title": "Edit", width: 90 },
                { template: `<a ng-click="msvm.deleteItem(dataItem.ID)"><i class="fa fa-trash-o text-danger m-r-xs"></i>Delete</a>`, "title": "Delete", width: 90 }
            ];
        vm.statusGridOptions = helperService.setMainGridOptions(statusGridColumns, 600);
        vm.statusGridOptions.selectable = false;

        vm.statusGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    try {
                        let { data } = await statusDataService.getStatuses();
                        data = $filter('filter')(data, { CanCurrentUserAccess: true });
                        data = $filter('orderBy')(data, 'Name');
                        options.success(data);
                    } catch {
                        return;
                    }
                }
            }
        });
    }

    activate();

    async function deleteItem(id) {
        try {
            await statusDataService.removeStatus(id)
            vm.statusGridDataSource.read();
        } catch {
            return;
        }
    }

    async function openModal(status) {
        let openModal = $uibModal.open({
            templateUrl: '/App/Components/maintainStatuses/statusModal.html',
            controller: statusModalController,
            controllerAs: 'smvm',
            backdrop: 'static',
            scope: $scope,
            resolve: {
                status: () => status || {}
            }
        });

        try {
            await openModal.result;
            vm.statusGridDataSource.read();
        } catch {
            return;
        }
    }

}