import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, $filter, simulation, role, selectedItems, selectedType, sapSdSimulationDataService, sapSdSimRoleDataService, helperService) {
    const vm = this,
        typeDisplayNames = {
            tcodes: "Transactions",
            catalogs: "Catalogs",
            groups: "Groups",
            spaces: "Spaces"
        };


    vm.itemAdded = false;
    selectedItems = [...selectedItems];
    vm.addItem = addItem;
    vm.close = close;
    vm.title = typeDisplayNames[selectedType];

    activate();

    function activate() {
        let availableItemsGridColumns = getColumnsByType(selectedType);

        vm.availableItemsGridOptions = helperService.setMainGridOptions(availableItemsGridColumns, null);

        vm.availableItemsGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    let { data } = await sapSdSimulationDataService.getSimulationItemsByType(simulation.Id, selectedType);

                    data = data.filter(item => {
                        return !selectedItems.some(selectedItem => selectedItem?.Name === item?.Name);
                    });

                    data = $filter('orderBy')(data, "Name");
                    options.success(data);
                }
            }
        });
    }

    async function addItem(item) {
        let data = {
            SimulationId: simulation.Id,
            RoleId: role.Id,
            Name: item.Name
        }

        if (selectedType === "catalogs") {
            data.Url = item.Url;
        }

        vm.addItemProcessing = true;
        kendo.ui.progress(vm.availableItemsGrid.element, true);

        try {
            await sapSdSimRoleDataService.addItemToSimulationRoleByType(data, selectedType);
            if (!vm.itemAdded) {
                vm.itemAdded = true;
            }

            selectedItems.push(JSON.parse(JSON.stringify(item)));
            kendo.ui.progress(vm.availableItemsGrid.element, false);

            vm.availableItemsGridDataSource.read();
            vm.addItemProcessing = false;
        } catch {
            vm.addItemProcessing = false;
            kendo.ui.progress(vm.availableItemsGrid.element, false);
        }
    }

    function close() {
        $uibModalInstance.close(vm.itemAdded);
    }
}

function getColumnsByType(type) {
    let types = {
            tcodes: [
                { title: "Transaction ID", field: "Name", filterable: customAutoCompleteFilter },
                { title: "Transaction Title", field: "Description", filterable: customAutoCompleteFilter },
                { template: "<a ng-disabled='imvm.addingToCompositeRole' ng-click='imvm.addItem(dataItem)'><i class='fa fa-plus pull-right'></i></a>", title: " ", width: 20 }
            ],
            groups: [
                { title: "Group ID", field: "Name", filterable: customAutoCompleteFilter },
                { title: "Group Title", field: "DisplayName", filterable: customAutoCompleteFilter },
                { template: "<a ng-disabled='imvm.addingToCompositeRole' ng-click='imvm.addItem(dataItem)'><i class='fa fa-plus pull-right'></i></a>", title: " ", width: 20 }
            ],
            catalogs: [
                { title: "Catalog Id", field: "Name", filterable: customAutoCompleteFilter },
                { title: "Catalog Title", field: "DisplayName", filterable: customAutoCompleteFilter },
                { template: "<a ng-disabled='imvm.addingToCompositeRole' ng-click='imvm.addItem(dataItem)'><i class='fa fa-plus pull-right'></i></a>", title: " ", width: 20 }
            ],
            spaces: [
                { title: "Space Template ID", field: "Name", filterable: customAutoCompleteFilter },
                { title: "Space Description", field: "Description", filterable: customAutoCompleteFilter },
                { title: "Space Title", field: "DisplayName", filterable: customAutoCompleteFilter },
                { template: "<a ng-disabled='imvm.addingToCompositeRole' ng-click='imvm.addItem(dataItem)'><i class='fa fa-plus pull-right'></i></a>", title: " ", width: 20 }
            ]
    };

    return types[type];
}