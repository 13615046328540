import { standardGridPageSize, customAutoCompleteFilter } from "../../../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../../../Shared/kendo.module";

export default /*@ngInject*/ function (dataService, $http, $filter, helperService) {

    const vm = this;

    vm.$onInit = onInit;

    vm.helperService = helperService;

    vm.typeDropdownChange = typeDropdownChange;
    vm.productTypesDropdownChange = productTypesDropdownChange;
    vm.productsDropdownChange = productsDropdownChange;
    vm.addCondition = addCondition;
    vm.removeFromListOfConditions = removeFromListOfConditions;
    vm.cancel = cancel;

    async function onInit() {
        vm.object = { ...vm.resolve.object };

        if (!vm.object.ProductType) {
            await getProductTypes();
        } else {
            vm.selectedProduct = { 'Type': vm.object.ProductType };
            vm.selectedProductType = { 'Type': vm.object.ProductType };
            await getProducts();
        }
    }

    function typeDropdownChange() {
        setupGrids();
    }

    function productTypesDropdownChange() {
        getProducts();
    }

    function productsDropdownChange() {
        getTypes();
    }


    async function addCondition(role) {
        const data = {
            "RiskObjectId": vm.object.Id,
            "ProductId": vm.selectedProduct.ID,
            "SecurityRoleTypeId": vm.selectedType.ID,
            "SecurityRoleId": role.ID,
            "ProductType": vm.selectedProductType.Type
        };

        let response = await $http.post(apiUrl + 'api/arm/defaultmitigationconditions', data);

        // Add to list displayed in modal
        const newCondition = {
            "RiskObjectId": vm.object.Id,
            "Id": response.data,
            "ProductId": vm.selectedProduct.ID,
            "SecurityRoleId": role.ID,
            "SecurityRoleName": role.Name,
            "SecurityRoleTypeId": vm.selectedType.ID,
            "SecurityRoleTypeName": vm.selectedType.Name,
            "SecurityRoleDescription": role.Description
        };

        vm.object.ConditionList.push(newCondition);
        vm.object.ConditionList = $filter('orderBy')(vm.object.ConditionList, 'SecurityRoleName');

        await vm.availableConditionsGridDataSource.read();
        await vm.selectedConditionsGridDataSource.read();

    }

    async function getProductTypes() {
        let response = await dataService.getProductTypes();
        vm.productTypesDataSource = response.data;
        vm.selectedProductType = response.data[0];
        getProducts();
    }

    async function getProducts() {
        let response = await $http.get(apiUrl + 'api/universal/products/producttype/' + vm.selectedProductType.Type);
        vm.productsDataSource = response.data;
        vm.selectedProduct = response.data[0];
        vm.selectedProductForCondition = response.data[0];
        getTypes();
    }

    async function getTypes() {
        if (vm.selectedProductForCondition) {
            let response = await $http.get(apiUrl + 'api/universal/securityroletypes/producttype/' + vm.selectedProduct.Type);
            vm.typeDataSource = response.data;
            vm.selectedType = response.data[0];
            setupGrids();
        }
    }

    function setupGrids() {
        const availableConditionsGridColumns = [
            { field: "Name", title: "Name", width: 120, filterable: customAutoCompleteFilter },
            { field: "Description", title: "Description", width: 120, filterable: customAutoCompleteFilter },
            { template: "<a ng-click='mcvm.addCondition(dataItem)'><i class='fa fa-plus pull-right'></i></a>", title: " ", width: 20 }
        ];

        const selectedConditionsGridColumns = [
            { field: "SecurityRoleName", title: "Name", width: 120, filterable: customAutoCompleteFilter },
            { field: "SecurityRoleDescription", title: "Description", width: 120, filterable: customAutoCompleteFilter },
            { template: "<a ng-click='mcvm.removeFromListOfConditions(dataItem)'><i class='fa fa-lg fa-trash-o pull-right text-danger'></i></a>", title: " ", width: 20 }
        ];

        vm.availableConditionsGridOptions = helperService.setMainGridOptions(availableConditionsGridColumns, 500);
        vm.selectedConditionsGridOptions = helperService.setMainGridOptions(selectedConditionsGridColumns, 500);
        vm.availableConditionsGridOptions.selectable = false;
        vm.selectedConditionsGridOptions.selectable = false;

        vm.availableConditionsGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: halSchemaKendoGrid,
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: async options => {
                    let data = helperService.prepareSortsAndFilters(options);
                    if (data.Filters.length > 0) {
                        data.Filters.forEach(filter => {
                            filter.FieldName = filter.FieldName === 'Name' ? 'SecurityRoleName' : 'SecurityRoleDescription';
                        });
                    }
                    let response = await $http({
                        method: 'POST',
                        url: `${apiUrl}api/universal/securityroles/product/${vm.selectedProduct.ID}/type/${vm.selectedType.ID}`,
                        data: data,
                        params: { 'pagesize': options.data.pageSize, 'page': options.data.page }
                    });
                    vm.object.ConditionList.forEach(condition => {
                        response.data._embedded.reportData = response.data._embedded.reportData.filter(role => role.ID !== condition.SecurityRoleId);
                    });
                    response.data._embedded.reportData = $filter('orderBy')(response.data._embedded.reportData, 'Name');
                    options.success(response.data);
                }
            }
        });

        vm.selectedConditionsGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: options => {
                    vm.object.ConditionList = $filter('orderBy')(vm.object.ConditionList, 'Name');
                    options.success(vm.object.ConditionList);
                }
            }
        });
    }


    async function removeFromListOfConditions(role) {
        role = role.toJSON();

        await $http.delete(apiUrl + 'api/arm/defaultmitigationconditions/' + role.Id);

        const roleIndex = vm.object.ConditionList.findIndex(condition => condition.SecurityRoleId === role.SecurityRoleId);
        vm.object.ConditionList.splice(roleIndex, 1);

        await vm.selectedConditionsGridDataSource.read();
        await vm.availableConditionsGridDataSource.read();
    }

    function cancel() {
        vm.modalInstance.close();
    }

}