import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($filter, $uibModalInstance, dataService, companyTemplatesData, helperService, errorsDataService, template) {

    const vm = this;

    vm.$onInit = createAvailableCompaniesDataSource;

    vm.template = { ...template };

    vm.addCompany = addCompany;
    vm.close = close;

    const availableCompaniesGridColumns = [
        { title: "Name", field: "Name", filterable: customAutoCompleteFilter },
        { title: "Company ID", field: "CompanyIDs", filterable: customAutoCompleteFilter },
        { template: "<a ng-click='cmvm.addCompany(dataItem)' ng-disabled='cmvm.loading'><i class='fa fa-plus pull-right'></i></a>", title: "", width: 20, filterable: false }
    ];

    vm.availableCompaniesGridOptions = helperService.setMainGridOptions(availableCompaniesGridColumns, null);
    vm.availableCompaniesGridOptions.selectable = false;


    function createAvailableCompaniesDataSource() {
        vm.availableCompaniesGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    try {
                        let response = await dataService.getCompanyMasters();
                        response.data = response.data.filter(company => company.UserHasAccess);
                        response.data = $filter('orderBy')(response.data, 'Name');
                        if (vm.template.Companies && vm.template.Companies.length > 0) {
                            vm.template.Companies.forEach(tc => {
                                response.data = response.data.filter(c => tc.CompanyMaster.ID !== c.ID);
                            });
                        }

                        vm.availableCompanies = response.data.reduce((companiesAccumulator, currentCompany) => {
                            const previousInformation = {
                                ID: currentCompany.ID,
                                Name: currentCompany.Name,
                                UserHasAccess: currentCompany.UserHasAccess
                            };

                            if (currentCompany.Companies.length) {
                                let usableCompanyInstance = [], companyIDsList = '';
                                currentCompany.Companies.forEach(company => {
                                    companyIDsList += company.ID + ', ';
                                })
                                companyIDsList = companyIDsList.slice(0, -2);
                                usableCompanyInstance.push({ ...previousInformation, CompanyIDs: companyIDsList });
                                return companiesAccumulator.concat(usableCompanyInstance);
                            } else {
                                companiesAccumulator.push(previousInformation);
                                return companiesAccumulator;
                            }

                        }, []);

                        options.success(vm.availableCompanies);
                    } catch (error) {
                        options.error(error);
                    }
                }
            }
        });
    }

    async function addCompany(company) {
        vm.loading = true;
        let postData = {
            CompanyTemplateId: vm.template.Id,
            CompanyMasterId: company.ID
        };

        try {
            await companyTemplatesData.addCompany(vm.template.Id, postData);
            removeCompanyFromGrid(company);
            addCompanyToTemplate(company);
        } catch {
            return;
        }

        vm.loading = false;
    }

    function removeCompanyFromGrid(company) {
        let addedCompany = vm.availableCompaniesGridDataSource._data.find(r => r.ID === company.ID);
        vm.availableCompaniesGridDataSource._data.remove(addedCompany);
    }

    function addCompanyToTemplate(company) {
        const companyObject = {
            CompanyTemplateId: vm.template.Id,
            CompanyTemplateName: vm.template.Name,
            CompanyMaster: company.toJSON(),
            CompanyIds: company.CompanyIDs.split(',')
        };
        vm.template.Companies.push(companyObject);
    }

    function close() {
        $uibModalInstance.close();
    }

}