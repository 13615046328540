import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($rootScope, helperService, configurationDataService) {
    var vm = this;

    vm.submit = submit;
    vm.configValuesHaveChanged = configValuesHaveChanged;
    vm.updateConfigurationSetting = updateConfigurationSetting;

    vm.$onInit = activate;

    function activate() {
        vm.plugin = Object.merge(vm.plugin, getPlugin(vm.plugin.Name));
    }

    function submit() {
        updateConfigurationSetting(vm.plugin.configOptions, vm.config, vm.origConfig);
    };

    function configValuesHaveChanged() {
        return helperService.returnTrueIfValuesDiffer(vm.plugin.configOptions.map(item => item.Name), vm.origConfig, vm.config);
    };

    async function updateConfigurationSetting(configOptions, value, origVal) {
        let putData = {
            "Configurations": []
        };

        configOptions.forEach(item => {
            if (item.Encrypted === true) {
                if (value[item.Name] != "password") {
                    putData.Configurations.push({
                        "Name": item.Name,
                        "Value": value[item.Name]
                    });
                }
            } else {
                if (value[item.Name] != origVal[item.Name]) {
                    putData.Configurations.push({
                        "Name": item.Name,
                        "Value": value[item.Name]
                    });
                }
            }
        });

        vm.updatePending = new createSpinner();

        try {
            await configurationDataService.updateConfigurationValues(putData);

            putData.Configurations.forEach(function (config) {
                origVal[config.Name] = config.Value;
            });

            helperService.successfulSaveButton(vm.updatePending);

            $rootScope.reloadConfigurationValues();
        } catch {
            vm.updatePending.loadingValue = false;
        }
    };
}

function getPlugin(name) {
    const plugins = [{
        name: "Active Directory",
        key: "AD",
        logoClass: "active-directory-avatar-logo",
        configOptions: [
                { Name: "AD_HostName", Encrypted: false },
                { Name: "AD_Active", Encrypted: false },
                { Name: "AD_Port", Encrypted: false }
            ]
        }, {
        name: "AuditBoard",
        key: "AUDITBOARD",
        logoClass: "auditboard-avatar-logo",
        configOptions: [
                { Name: "AUDITBOARD_Url", Encrypted: false },
                { Name: "AUDITBOARD_Password", Encrypted: false },
                { Name: "AUDITBOARD_Active", Encrypted: false }
            ]
        }, {
        name: "Entra ID",
        key: "AAD",
        logoClass: "azure-active-directory-avatar-logo",
        configOptions: [
                { Name: "AAD_ClientId", Encrypted: false },
                { Name: "AAD_ClientSecret", Encrypted: true },
                { Name: "AAD_TenantId", Encrypted: false },
                { Name: "AAD_Active", Encrypted: false }
            ]
        }, {
            name: "Azure Log Analytics",
            key: "ALA",
            logoClass: "ala-avatar-logo",
            configOptions: [
                { Name: "ALA_ClientId", Encrypted: false },
                { Name: "ALA_ClientSecret", Encrypted: true },
                { Name: "ALA_TenantId", Encrypted: false },
                { Name: "ALA_Active", Encrypted: false }
            ]
        }, {
        name: "Freshservice",
        key: "FRESHSERVICE",
        logoClass: "freshservice-avatar-logo",
        configOptions: [
                { Name: "FRESHSERVICE_Email", Encrypted: false },
                { Name: "FRESHSERVICE_Url", Encrypted: false },
                { Name: "FRESHSERVICE_Active", Encrypted: false },
                { Name: "FRESHSERVICE_Password", Encrypted: true }
            ]
        }, {
        name: "Jira",
        key: "JIRA",
        logoClass: "jira-avatar-logo",
        configOptions: [
                { Name: "JIRA_Email", Encrypted: false },
                { Name: "JIRA_Url", Encrypted: false },
                { Name: "JIRA_Active", Encrypted: false },
                { Name: "JIRA_Password", Encrypted: true }
            ]
        }, {
        name: "ServiceNow",
        key: "SNOW",
        logoClass: "service-now-avatar-logo",
        configOptions: [
                { Name: "SNOW_User", Encrypted: false },
                { Name: "SNOW_Url", Encrypted: false },
                { Name: "SNOW_Active", Encrypted: false },
                { Name: "SNOW_Password", Encrypted: true }
            ]
        }, {
        name: "Workiva",
        logoClass: "workiva-avatar-logo",
        key: "WORKIVA",
        configOptions: [
                { Name: "WORKIVA_AccountId", Encrypted: false },
                { Name: "WORKIVA_Active", Encrypted: false },
                { Name: "WORKIVA_ClientId", Encrypted: false },
                { Name: "WORKIVA_ClientSecret", Encrypted: false },
                { Name: "WORKIVA_ControlsReportId", Encrypted: false },
                { Name: "WORKIVA_BaseUrl", Encrypted: false }
            ]
        }, {
        name: "Zendesk",
        key: "ZDK",
        logoClass: "zendesk-avatar-logo",
        configOptions: [
                { Name: "ZDK_Email", Encrypted: false },
                { Name: "ZDK_Url", Encrypted: false },
                { Name: "ZDK_Active", Encrypted: false },
                { Name: "ZDK_Token", Encrypted: true }
            ]
        }];

    return plugins.find(item => item.name === name);
}
