import { customAutoCompleteFilter, customBooleanFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";
import swal from "sweetalert2";
import importAutoMitigationRulesetModalController from "./ImportAutoMitigationRulesetModal/importAutoMitigationRulesetModal.controller";
import autoMitigationModalController from "./AutoMitigationModal/autoMitigationModal.controller";

export default /*@ngInject*/ function ($filter, $uibModal, FileSaver, helperService, autoMitigationsDataService, $scope) {

    const vm = this;

    vm.$onInit = onInit;

    vm.helperService = helperService;
    vm.openAutoMitigationModal = openAutoMitigationModal;
    vm.deleteSystemAutoMitigation = deleteSystemAutoMitigation;
    vm.applyAutoMitigationConfirmation = applyAutoMitigationConfirmation;
    vm.openImportAutoMitigationRulesetModal = openImportAutoMitigationRulesetModal;
    vm.exportAutoMitigationRuleset = exportAutoMitigationRuleset;

    $scope.$on('ImportExclusions', (event, args) => vm.autoMitigationRulesGridDatasource.read());

    function onInit() {
        const autoMitigationRulesGridColumns =
            [
                { field: "ExcludeObjectType", title: "Exclude Object Type", filterable: customAutoCompleteFilter },
                { field: "ExcludeObjectId", title: "Exclude Object Id", filterable: customAutoCompleteFilter },
                { field: "ExcludeObjectName", title: "Exclude Object Name", filterable: customAutoCompleteFilter },
                { field: "FromObjectType", title: "From Object Type", filterable: customAutoCompleteFilter },
                { field: "FromObjectId", title: "From Object Id", filterable: customAutoCompleteFilter },
                { field: "FromObjectName", title: "From Object Name", filterable: customAutoCompleteFilter },
                { field: "Description", title: "Description", filterable: customAutoCompleteFilter },
                { field: "Enabled", title: "Enabled", width: 125, filterable: customBooleanFilter },
                { template:
                    `<a ng-click="amvm.openAutoMitigationModal(dataItem)"><i class="fa fa-edit text-warning m-r-xs"></i>Edit</a>`,
                    title: "Edit",
                    width: 90
                },
                { template:
                    `<a ng-click="amvm.deleteSystemAutoMitigation(dataItem)"><i class="fa fa-trash-o text-danger m-r-xs"></i>Delete</a>`,
                    title: "Delete",
                    width: 90
                }
            ];

        vm.autoMitigationRulesGridOptions = helperService.setMainGridOptions(autoMitigationRulesGridColumns, 275);
        vm.autoMitigationRulesGridOptions.selectable = false;

        vm.autoMitigationRulesGridDatasource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: {
                model: {
                    fields: {
                        Enabled: { type: "boolean" }
                    }
                }
            },
            transport: {
                read: async options => {
                    try {
                        const res = await autoMitigationsDataService.getAutoMitigations(vm.product);
                        let { data } = res;
                        data = $filter('orderBy')(data, 'Name');
                        options.success(data);
                    } catch (err) {
                        options.error();
                    }
                }
            }
        });
    }

    async function applyAutoMitigationConfirmation() {
        try {
            await swal(
                helperService.areYouSureParams(
                    'Apply Auto Mitigation',
                    'This process will apply the defined Auto Mitigation Rules to the user risks that have not already been mitigated.',
                    'Apply'
                )
            );
            autoMitigationsDataService.applyAutoMitigations(vm.product.toUpperCase());
        } catch (_) {
            return;
        }
    }

    function openImportAutoMitigationRulesetModal() {
        $uibModal.open({
            controller: importAutoMitigationRulesetModalController,
            controllerAs: 'iamrmvm',
            templateUrl: 'App/Components/AutoMitigations/ImportAutoMitigationRulesetModal/importAutoMitigationRulesetModal.tmpl.html',
            backdrop: 'static',
            resolve: {
                product: () => vm.product
            }
        });
    }

    async function exportAutoMitigationRuleset() {
        vm.rulesetExportPending = true;
        try {
            const res = await autoMitigationsDataService.exportAutoMitigationRuleset(vm.product);
            FileSaver.saveAs(res.data, 'AutoMitigationRuleset.xlsx');
            vm.rulesetExportPending = false;
        } catch (err) {
            vm.rulesetExportPending = false;
        }
    }

    async function openAutoMitigationModal(mitigation) {
        const modal = $uibModal.open({
            controller: autoMitigationModalController,
            controllerAs: 'ammvm',
            templateUrl: '/App/Components/AutoMitigations/AutoMitigationModal/autoMitigationModal.tmpl.html',
            backdrop: 'static',
            resolve: {
                mitigation: () => mitigation || {},
                product: () => vm.product
            }
        });
        try {
            await modal.result;
            vm.autoMitigationRulesGridDatasource.read();
        } catch (_) {
            return;
        }
    }

    async function deleteSystemAutoMitigation(dataItem) {
        const { Id: mitigationId } = dataItem;
        try {
            await autoMitigationsDataService.deleteAutoMitigation(vm.product, mitigationId);
            vm.autoMitigationRulesGridDatasource.read();
        } catch (err) {
            return;
        }
    }

}