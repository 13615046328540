import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($http, $uibModalInstance, apiClient, apiClientDataService, helperService, dataService) {
    const vm = this;

    vm.$onInit = init;

    vm.availableRolesDataSource = [];
    vm.createApiClient = createApiClient;
    vm.cancel = cancel;
    vm.savingApiClient = false;
    vm.apiClientFormSubmit = apiClientFormSubmit;

    vm.apiClient = Object.assign({}, apiClient);
    vm.apiClient.Active = vm.apiClient.Active !== undefined ? vm.apiClient.Active : true;

    async function init() {

        let response;
        vm.rolesDropdownLoading = true;

        try {
            response = await dataService.getRoles();
            vm.availableRolesDataSource = response.data;

        } catch (response) {
            helperService.showErrorMessage(response.data);
        }
        vm.rolesDropdownLoading = false;
    }

    function apiClientFormSubmit() {
        vm.disableSubmit = true;
        if (vm.apiClient.AdmUserId) {
            updateApiClient();
        } else {
            createApiClient();
        }
    }

    async function createApiClient() {
        vm.savingApiClient = new createSpinner();

        try {
            const roleIds = [];
            if (vm.apiClient.Roles && vm.apiClient.Roles.length > 0) {
                for (var i = 0; i < vm.apiClient.Roles.length; i++) {
                    roleIds.push(vm.apiClient.Roles[i].ID);
                }
            }

            const payload = {
                "Name": vm.apiClient.Name,
                "ActivateUponCreation": vm.apiClient.Active,
                "RoleIds": roleIds
            };

            let response = await apiClientDataService.createApiClient(payload);

            await helperService.successfulSaveButton(vm.savingApiClient);
            vm.disableSubmit = false;
            close(response.data);
        } catch (_) {
            vm.savingApiClient.loadingValue = false;
            vm.disableSubmit = false;
        }
    }

    async function updateApiClient() {
        vm.savingApiClient = new createSpinner();

        try {
            const roleIds = [];
            for (var i = 0; i < vm.apiClient.Roles.length; i++) {
                roleIds.push(vm.apiClient.Roles[i].ID);
            }

            const payload = {
                "Name": vm.apiClient.Name,
                "Active": vm.apiClient.Active,
                "RoleIds": roleIds
            };

            await apiClientDataService.updateApiClient(vm.apiClient.AdmUserId, payload);
            await helperService.successfulSaveButton(vm.savingApiClient);
            close();
            vm.disableSubmit = false;
        } catch (_) {
            vm.savingApiClient.loadingValue = false;
            vm.disableSubmit = false;
        }
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }

    function close(updatedOrCreatedId) {
        $uibModalInstance.close(updatedOrCreatedId);
    }
}