import { halSchemaKendoGrid } from "../../Shared/kendo.module";
import { standardGridPageSize, customDatePickerNotNullableFilter, customAutoCompleteFilter } from "../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($scope, $rootScope, SavedReportSelectorService, dataService, objectIdDataService, CustomReportServices, $filter, $http, helperService, AuditTrailEntityDataService, reportsDataService, reportsService) {

    const vm = $scope.vm = {};

    vm.RelativeDateTypeDataSource = CustomReportServices.getRelativeDateTypes();
    vm.PeriodTypeDataSource = CustomReportServices.getPeriodTypes();
    $scope.onRead = onRead;

    vm.RelativeDateType = 2;
    vm.PeriodType = 0;
    $scope.dateCriteria = [
        {
            "DataType": "DateTime",
            "FieldName": "Date",
            "IsNullable": false
        }
    ];

    vm.selectedTemplateType = [];
    $scope.mainGridOptions = helperService.setMainGridOptions(null, 600);
    $scope.mainGridOptions.autoBind = false;
    $scope.helperService = helperService;

    // --- FOR ADVANCED FILTERING ---
    vm.filterCriteria = {};
    vm.filterCriteria.query = [];
    // ---

    $scope.$on('kendoWidgetCreated', function () {
        if ($scope.grid) {
            $scope.gridReady = true;
            $scope.runSavedReport();
        }
    });

    activate();

    async function activate() {
        await getObjectId();
        getProducts();
    }

    async function getObjectId() {
        let objectId = await objectIdDataService.getObjectID();
        // Needed for report toolbar controller functions
        $scope.ObjectId = objectId;
        $scope.reportName = helperService.getSecurityObjectDetailsFromObjectId($scope.ObjectId).Name;
        $scope.filtersDataSource = CustomReportServices.setfiltersDataSource($scope.ObjectId);
    }

    async function getProducts() {
        let { data: products } = await dataService.getProducts();

        vm.productsDataSource = products.filter(product => product.HasAuditTrailModule);

        //Filter Netsuite and Oracle Cloud products from dropdown as they have their own custom Data Changes reports
        vm.productsDataSource = products.filter(product => product.ID !== 'NS' && product.ID !== 'ORFC');


        let defaultProduct = vm.productsDataSource.find(obj => obj.Name === $rootScope.UserPreferences.PreferredProduct);
        let defaultProductSelected = vm.productsDataSource.indexOf(defaultProduct);
        let defaultProductExists = vm.productsDataSource.some(obj => obj.Name === $rootScope.UserPreferences.PreferredProduct);
        if (defaultProductExists) {
            if ($rootScope.UserPreferences.PreferredProduct !== null) {
                vm.selectedProduct = vm.productsDataSource[defaultProductSelected];
            } else {
                vm.selectedProduct = vm.productsDataSource[0];
            }
        } else {
            vm.selectedProduct = vm.productsDataSource[0];
        }

        if ($rootScope.loadedSavedReport) {
            loadSavedReportCriteria();
        }
        vm.selectedProduct = SavedReportSelectorService.checkSavedReportForSingleProduct($rootScope.loadedSavedReport, products, vm.selectedProduct);
        $scope.reportId = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, vm.selectedProduct.Type).ReportID;
        $scope.productDropdownChange();
    }

    $scope.runSavedReport = async function () {
        if ($scope.gridReady && $rootScope.loadedSavedReport && vm.productsDataSource && vm.templateTypesDataSource && vm.filterCriteria.fields) {
            $scope.reportId = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, vm.selectedProduct.Type).ReportID;

            // --- FOR ADVANCED FILTERING ---
            // Convert date filters to criteria
            const dateFieldName = vm.selectedProduct.Type === 'AX' ? 'TimeStamp' : vm.selectedProduct.Type === 'AX7' ? 'ModifiedOn' : vm.selectedProduct.Type === 'SF' ? 'CreatedDate' : 'Date';

            if ($rootScope.loadedSavedReport && $rootScope.loadedSavedReport.ReportDefinition.DateFilters.length > 0) {
                const criteriaDateFilterIndex = $rootScope.loadedSavedReport.ReportDefinition.DateFilters.findIndex(filter => filter.FieldName === dateFieldName);
                if (criteriaDateFilterIndex > -1) {
                    loadDateFilterAsCriteria($rootScope.loadedSavedReport.ReportDefinition.DateFilters[criteriaDateFilterIndex]);
                    $rootScope.loadedSavedReport.ReportDefinition.DateFilters.splice(criteriaDateFilterIndex, 1);
                }
            } else if ($rootScope.loadedSavedReport && $rootScope.loadedSavedReport.ReportDefinition.Filters.length > 0) {
                const dateRangeFilters = $rootScope.loadedSavedReport.ReportDefinition.Filters.filter(filter => filter.FieldName === dateFieldName);
                if (dateRangeFilters.length > 0) {
                    $rootScope.loadedSavedReport.ReportDefinition.Filters = $rootScope.loadedSavedReport.ReportDefinition.Filters.filter(filter => filter.FieldName !== dateFieldName);
                    dateRangeFilters.forEach(filter => {
                        loadDateFilterAsCriteria(filter);
                    });
                }
            }

            vm.filterCriteria.query = reportsService.getSavedReportFilters();
            vm.filterCriteria.dateQuery = reportsService.getSavedReportDateFilters();
            vm.filterCriteria.needsUpdate = true;
            // ---
            $scope.generateGridWithSelectedValues();
        } else if ($scope.gridReady && !$rootScope.loadedSavedReport && vm.productsDataSource && vm.templateTypesDataSource && vm.filterCriteria.fields) {
            // We want the necessary report details available to be able to use the report toolbar without running the report
            $scope.populateCriteriaModel();
        }
    };

    function loadDateFilterAsCriteria(DateFilter) {
        if (DateFilter.Operator === 'GreaterThanOrEqual' || DateFilter.Operator === 'LessThanOrEqual') {
            if (!$rootScope.loadedSavedReport.ReportDefinition.Criteria[0]) {
                $rootScope.loadedSavedReport.ReportDefinition.Criteria = [{
                    "CriteriaType": "Date",
                    "CriteriaValue": DateFilter.FieldName,
                    Filters: []
                }];
                $rootScope.loadedSavedReport.ReportDefinition.Criteria[0].Filters.push(DateFilter);
            } else {
                $rootScope.loadedSavedReport.ReportDefinition.Criteria[0].Filters.push(DateFilter);
            }
        } else {
            $rootScope.loadedSavedReport.ReportDefinition.Criteria = [{
                "CriteriaType": "Date",
                "CriteriaValue": DateFilter.FieldName,
                Filters: []
            }];
            $rootScope.loadedSavedReport.ReportDefinition.Criteria[0].DateFilter = DateFilter;
        }
    }

    function setupFilters() {
        if (!$scope.sortsAndFilters) {
            $scope.sortsAndFilters = {};
        }
        if (!$scope.sortsAndFilters.Filters) {
            $scope.sortsAndFilters.Filters = [];
        }
        if (!$scope.sortsAndFilters.DateFilter) {
            $scope.sortsAndFilters.DateFilters = [];
        }

        let dateFilters = $scope.getDateFilters();

        if (dateFilters.Filters && dateFilters.Filters.length > 0) {
            $scope.sortsAndFilters.Filters = $scope.sortsAndFilters.Filters.filter(f => f.FieldName != dateFilters.Filters[0].FieldName);
            $scope.sortsAndFilters.Filters = $scope.sortsAndFilters.Filters.concat(dateFilters.Filters);
        }
        if (dateFilters.RelativeFilter) {
            $scope.sortsAndFilters.Filters = $scope.sortsAndFilters.Filters.filter(f => f.FieldName != dateFilters.RelativeFilter.FieldName);
            $scope.sortsAndFilters.DateFilters.push(dateFilters.RelativeFilter);
        }
    }

    $scope.productDropdownChange = function () {
        getTemplates();
        vm.setColumns();
        $scope.reportId = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, vm.selectedProduct.Type).ReportID;
        setReportMetadata();
        $scope.populateCriteriaModel();
    };

    $scope.gridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        schema: angular.extend(halSchemaKendoGrid, {
            model: {
                fields: {
                    ModifiedDate: { type: "date" },
                    ChangeDate: { type: "date" },
                    TimeStamp: { type: "date" },
                    ModifiedOn: { type: "date" },
                    dateandTime: { type: "date" },
                    Date: { type: "date" },
                    CreatedDate: { type: "date" }
                }
            }
        }),
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {
            read: function (options) {
                delete $rootScope.loadedSavedReport;

                var data = {
                    'TemplateIds': $scope.templateTypes
                };
                $scope.sortsAndFilters = helperService.prepareSortsAndFilters(options);
                setupFilters();

                if (vm.selectedProduct.Type === "D365Bc" || vm.selectedProduct.Type === 'SF' || vm.selectedProduct.Type === 'WD') {
                    data = {
                        QueryInput: $scope.sortsAndFilters
                    }
                } else {
                    data.QueryInput = $scope.sortsAndFilters;
                }

                // --- FOR ADVANCED FILTERING ---
                if (vm.filterCriteria.query) {
                    data['QueryInput'].Filters = data['QueryInput'].Filters.concat(vm.filterCriteria.query);
                }
                // ---

                // if tabs are on, save report info for the tab
                if ($rootScope.tabs.length > 0) {
                    reportsService.saveTabReportInfo($scope);
                }

                $http({
                    method: 'POST',
                    url: $scope.urlString,
                    data: data,
                    params: { 'pagesize': options.data.pageSize, 'page': options.data.page }
                }).then(function (response) {
                    options.success(response.data);
                    vm.enableGrid = true;

                    $scope.reportId = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, vm.selectedProduct.Type).ReportID;

                }).catch(response => {
                    const timeout = 408;

                    if (response.status !== timeout) {
                        helperService.showErrorMessage(response.data);
                    }
                });
            }
        }
    });

    function loadSavedReportCriteria() {
        if ($rootScope.loadedSavedReport && $rootScope.loadedSavedReport.ReportDefinition.Criteria) {
            $rootScope.loadedSavedReport.ReportDefinition.Criteria.forEach(function (criteria) {
                if (criteria.DateFilter) {
                    switch (criteria.DateFilter.DateType) {
                        case 'Previous':
                            vm.RelativeDateType = 0;
                            vm.PeriodType = $scope.getPeriodId(criteria.DateFilter.Period);
                            break;

                        case 'ToDate':
                            vm.RelativeDateType = 1;
                            vm.PeriodType = $scope.getPeriodId(criteria.DateFilter.Period);
                            break;

                        case 'LastNumberOf':
                            vm.RelativeDateType = 2;
                            vm.PeriodType = $scope.getPeriodId(criteria.DateFilter.Period);
                            vm.LastNumberOf = criteria.DateFilter.Value;
                            break;

                    }
                }
                if (criteria.Filters.length > 0) {
                    criteria.Filters.forEach(function (filter) {
                        switch (filter.Operator) {
                            case 'GreaterThanOrEqual':
                                vm.RelativeDateType = 4;
                                $scope.vm.startDate = new Date(filter.Value);
                                break;

                            case 'LessThanOrEqual':
                                vm.RelativeDateType = 4;
                                $scope.vm.endDate = new Date(filter.Value);
                                break;
                        }
                    });
                }
            });

        }

        if ($rootScope.loadedSavedReport && $rootScope.loadedSavedReport.ReportDefinition.DateFilters.length > 0) {
            const dateFilter = $rootScope.loadedSavedReport.ReportDefinition.DateFilters[0];
            switch (dateFilter.DateType) {
                case 'Previous':
                    vm.RelativeDateType = 0;
                    vm.PeriodType = $scope.getPeriodId(dateFilter.Period);
                    break;

                case 'ToDate':
                    vm.RelativeDateType = 1;
                    vm.PeriodType = $scope.getPeriodId(dateFilter.Period);
                    break;

                case 'LastNumberOf':
                    vm.RelativeDateType = 2;
                    vm.PeriodType = $scope.getPeriodId(dateFilter.Period);
                    vm.LastNumberOf = dateFilter.Value;
                    break;

            }
        }
    }

    vm.deselectSelectedTemplateType = function () {

        if (vm.selectedTemplateType && vm.selectedTemplateType[vm.selectedTemplateType.length - 1].Id !== 'AllObjectsInMultiSelect') {
            vm.selectedTemplateType.forEach(function (row, i) {
                if (row.Id === 'AllObjectsInMultiSelect') {
                    vm.selectedTemplateType.splice(i, 1);
                }
            });
        } else if (vm.selectedProduct.Type === 'GP' || vm.selectedProduct.Type === 'AX' || vm.selectedProduct.Type === 'AX5' || vm.selectedProduct.Type === 'NAV' || vm.selectedProduct.Type === 'SL') {
            vm.selectedTemplateType = [{ "Name": "All Tables", "Id": 'AllObjectsInMultiSelect' }];
        } else {
            vm.selectedTemplateType = [{ "Name": "All Template Types", "Id": 'AllObjectsInMultiSelect' }];
        }
        $scope.populateCriteriaModel();
    };

    function getTemplates() {
        if (vm.selectedProduct.Type === 'AX7') {
            vm.templateDropdownIsLoading = true;
            AuditTrailEntityDataService.getAllTemplates().then(function (response) {

                response.data = $filter('orderBy')(response.data, 'Name');
                //removes template Type if Insert, Update, Delete are all false
                response.data.remove(function (el) { return el.Insert === false && el.Update === false && el.Delete === false; });

                var All = { "Name": "All Template Types", "Id": 'AllObjectsInMultiSelect' };

                response.data.unshift(All);
                vm.templateTypesDataSource = response.data;
                vm.selectedTemplateType = [All];
                vm.selectedTemplateType = SavedReportSelectorService.checkSavedReportForRecordTypeMultiSelect($rootScope.loadedSavedReport, response.data, vm.selectedTemplateType);
                $scope.runSavedReport();
                vm.templateDropdownIsLoading = false;


            });
        } else if (vm.selectedProduct.Type === 'SAP') {
            vm.templateDropdownIsLoading = true;

            $http.get(apiUrl + 'api/audittrail/sap/' + vm.selectedProduct.ID + '/templates').then(function (response) {
                response.data.forEach(function (row) {
                    row['Id'] = row.Name;
                });
                response.data = $filter('orderBy')(response.data, 'Name');
                var All = { "Name": "All Template Types", "Id": 'AllObjectsInMultiSelect' };

                response.data.unshift(All);
                vm.templateTypesDataSource = response.data;
                vm.selectedTemplateType = [All];
                vm.selectedTemplateType = SavedReportSelectorService.checkSavedReportForRecordTypeMultiSelect($rootScope.loadedSavedReport, response.data, vm.selectedTemplateType);
                $scope.runSavedReport();
                vm.templateDropdownIsLoading = false;

            });
        } else if (vm.selectedProduct.Type === 'OR') {
            vm.templateDropdownIsLoading = true;

            $http.get(apiUrl + 'api/audittrail/or/templates').then(function (response) {
                response.data.forEach(function (row) {
                    row['Id'] = row.Name;
                });
                response.data = $filter('orderBy')(response.data, 'Name');
                var All = { "Name": "All Template Types", "Id": 'AllObjectsInMultiSelect' };

                response.data.unshift(All);
                vm.templateTypesDataSource = response.data;
                vm.selectedTemplateType = [All];
                vm.selectedTemplateType = SavedReportSelectorService.checkSavedReportForRecordTypeMultiSelect($rootScope.loadedSavedReport, response.data, vm.selectedTemplateType);
                $scope.runSavedReport();
                vm.templateDropdownIsLoading = false;

            });
        } else if (vm.selectedProduct.Type === 'AX') {
            vm.templateDropdownIsLoading = true;

            $http.get(apiUrl + 'api/audittrail/ax/tables').then(function (response) {
                response.data = $filter('orderBy')(response.data, 'Name');
                var All = { "Name": "All Tables", "Id": 'AllObjectsInMultiSelect' };

                response.data.unshift(All);
                vm.templateTypesDataSource = response.data;
                vm.selectedTemplateType = [All];
                vm.selectedTemplateType = SavedReportSelectorService.checkSavedReportForRecordTypeMultiSelect($rootScope.loadedSavedReport, response.data, vm.selectedTemplateType);
                $scope.runSavedReport();
                vm.templateDropdownIsLoading = false;

            });
        } else if (vm.selectedProduct.Type === 'GP') {
            vm.templateDropdownIsLoading = true;

            $http.get(apiUrl + 'api/audittrail/gp/tables').then(function (response) {
                response.data = $filter('orderBy')(response.data, 'Name');
                var All = { "Name": "All Tables", "Id": 'AllObjectsInMultiSelect' };

                response.data.unshift(All);
                vm.templateTypesDataSource = response.data;
                vm.selectedTemplateType = [All];
                vm.selectedTemplateType = SavedReportSelectorService.checkSavedReportForRecordTypeMultiSelect($rootScope.loadedSavedReport, response.data, vm.selectedTemplateType);
                $scope.runSavedReport();
                vm.templateDropdownIsLoading = false;

            });
        } else if (vm.selectedProduct.Type === 'NAV') {
            vm.templateDropdownIsLoading = true;

            $http.get(apiUrl + 'api/audittrail/nav/tables').then(function (response) {
                response.data = $filter('orderBy')(response.data, 'Name');
                var All = { "Name": "All Tables", "Id": 'AllObjectsInMultiSelect' };

                response.data.unshift(All);
                vm.templateTypesDataSource = response.data;
                vm.selectedTemplateType = [All];
                vm.selectedTemplateType = SavedReportSelectorService.checkSavedReportForRecordTypeMultiSelect($rootScope.loadedSavedReport, response.data, vm.selectedTemplateType);
                $scope.runSavedReport();
                vm.templateDropdownIsLoading = false;

            });
        } else if (vm.selectedProduct.Type === 'AX5') {
            vm.templateDropdownIsLoading = true;

            $http.get(apiUrl + 'api/audittrail/ax5/tables').then(function (response) {
                response.data = $filter('orderBy')(response.data, 'Name');
                var All = { "Name": "All Tables", "Id": 'AllObjectsInMultiSelect' };

                response.data.unshift(All);
                vm.templateTypesDataSource = response.data;
                vm.selectedTemplateType = [All];
                vm.selectedTemplateType = SavedReportSelectorService.checkSavedReportForRecordTypeMultiSelect($rootScope.loadedSavedReport, response.data, vm.selectedTemplateType);
                $scope.runSavedReport();
                vm.templateDropdownIsLoading = false;

            });
        } else if (vm.selectedProduct.Type === 'SL') {
            vm.templateDropdownIsLoading = true;

            $http.get(apiUrl + 'api/audittrail/sl/tables').then(function (response) {
                response.data = $filter('orderBy')(response.data, 'Name');
                var All = { "Name": "All Tables", "Id": 'AllObjectsInMultiSelect' };

                response.data.unshift(All);
                vm.templateTypesDataSource = response.data;
                vm.selectedTemplateType = [All];
                vm.selectedTemplateType = SavedReportSelectorService.checkSavedReportForRecordTypeMultiSelect($rootScope.loadedSavedReport, response.data, vm.selectedTemplateType);
                $scope.runSavedReport();
                vm.templateDropdownIsLoading = false;

            });
        } else if (vm.selectedProduct.IsCustom) {
            vm.templateDropdownIsLoading = true;

            $http.get(apiUrl + 'api/audittrail/universal/' + vm.selectedProduct.ID +'/tables').then(function (response) {
                response.data = $filter('orderBy')(response.data, 'Name');
                var All = { "Name": "All Tables", "Id": 'AllObjectsInMultiSelect' };

                response.data.unshift(All);
                vm.templateTypesDataSource = response.data;
                vm.selectedTemplateType = [All];
                vm.selectedTemplateType = SavedReportSelectorService.checkSavedReportForRecordTypeMultiSelect($rootScope.loadedSavedReport, response.data, vm.selectedTemplateType);
                $scope.runSavedReport();
                vm.templateDropdownIsLoading = false;

            });
        } else {
            vm.templateTypesDataSource = [];
        }
    }

    //Start Date picker settings
    $scope.thirtyDaysAgo = function () {
        var newDate = new Date();
        newDate.setDate(newDate.getDate() - 30);
        $scope.startDate = newDate;
        $scope.startDate = SavedReportSelectorService.checkSavedReportForDate($rootScope.loadedSavedReport, "StartDate", $scope.startDate);
    };
    $scope.thirtyDaysAgo();

    $scope.startDateOptions = {
        dateDisabled: disabled,
        showWeeks: false,
        formatYear: 'yy',
        startingDay: 1
    };

    function disabled(data) {
        var date = data.date,
            mode = data.mode;
        return mode === 'day' && date > $scope.endDate;
    }

    $scope.openStartDate = function () {
        $scope.startDatePopup.opened = true;
    };

    $scope.startDatePopup = {
        opened: false
    };

    //End Date picker settings
    $scope.today = function () {
        $scope.endDate = new Date();
        $scope.endDate = SavedReportSelectorService.checkSavedReportForDate($rootScope.loadedSavedReport, "EndDate", $scope.endDate);
    };
    $scope.today();

    $scope.endDateOptions = {
        showWeeks: false,
        formatYear: 'yy',
        maxDate: new Date(),
        startingDay: 1
    };

    $scope.openEndDate = function () {
        $scope.endDatePopup.opened = true;
    };

    $scope.endDatePopup = {
        opened: false
    };

    $scope.populateCriteriaModel = function () {
        // do not continue if the proper criteria are not loaded
        if (!vm.selectedProduct || vm.selectedTemplateType === undefined) {
            return;
        }
        var criteria = [];

        $scope.product = vm.selectedProduct.ID;
        $scope.productType = vm.selectedProduct.Type;

        $scope.templateTypes = helperService.returnNullIfAllIsFound(vm.selectedTemplateType.map(function (el) { return el.Id; }));

        criteria.push(CustomReportServices.CreateCriteria("ProductId", 'Product', vm.selectedProduct.Name, vm.selectedProduct.ID));

        vm.selectedTemplateType.forEach(function (row) {
            criteria.push(CustomReportServices.CreateCriteria("TemplateIds", 'TemplateId', row.Name, row.Id));
        });

        setupFilters();

        if (vm.selectedProduct.IsCustom) {
            $scope.urlString = apiUrl + 'api/audittrail/universal/' + $scope.productType + '/datachanges';
        } else {
            $scope.urlString = apiUrl + 'api/audittrail/' + $scope.productType + '/datachanges';
        }

        $scope.reportDetailsModel = criteria;

        // if tabs are on, save report info for the tab
        if ($rootScope.tabs.length > 0) {
            reportsService.saveTabReportInfo($scope);
        }

    };

    $scope.generateGridWithSelectedValues = function () {

        if (!$rootScope.loadedSavedReport) {
            $scope.grid.dataSource._filter = null;
            $scope.grid.dataSource._sort = null;
        }

        if ($scope.startDate <= $scope.endDate) {
            $scope.populateCriteriaModel();
            vm.hideExportOptionsFromSubmit = true;

            // Add back the sorting and filtering options if we are running the report for the first time
            if (!$rootScope.loadedSavedReport) {
                $scope.grid.setOptions({
                    filterable: {
                        mode: "row"
                    },
                    sortable: true
                });
            }

            SavedReportSelectorService.queryGridWithOptions($rootScope.loadedSavedReport, $scope.grid, true);
        } else {
            helperService.showErrorMessage("Start Date must be before or equal to End Date");
        }
    };

    function onRead() {
        $scope.gridDataSource.read();
    }

    const rowSettingsTemplate = `<row-settings-template params="dataItem" selected-product="vm.selectedProduct" on-delete="onRead()"></row-settings-template>`;

    var ticketTemplate = `<span ng-if='dataItem.TicketProvider && dataItem.TicketProvider !== "Custom"'>{{ dataItem.TicketProvider }} &nbsp; <a href='{{dataItem.TicketUrl}}' target='_blank'>{{dataItem.TicketId}}</a></span>
                          <span ng-if='dataItem.TicketProvider && dataItem.TicketProvider === "Custom"'>{{ dataItem.TicketProvider }} &nbsp; <span>{{dataItem.TicketId}}</span></span>`;

    vm.setColumns = function () {
        if (vm.selectedProduct) {
            if (vm.selectedProduct.Type === 'AX7') {
                $scope.mainGridColumns = [
                    { title: "", template: rowSettingsTemplate, attributes: { "class": "no-padding overflow-visible" }, minResizableWidth: 22, width: 22 },
                    { field: "TicketDisplayName", title: "Ticket", template: ticketTemplate, filterable: false, sortable: false },
                    { field: "ModifiedOn", title: "Modified On", template: "{{ dataItem.ModifiedOn | date:'short'}}", filterable: customDatePickerNotNullableFilter },
                    { field: "TableLabel", title: "Table Label", filterable: customAutoCompleteFilter },
                    { field: "ColumnLabel", title: "Column Label", filterable: customAutoCompleteFilter },
                    { field: "Event", title: "Event", filterable: customAutoCompleteFilter },
                    { field: "Description", title: "Description", filterable: customAutoCompleteFilter },
                    { field: "OldValue", title: "Old Value", filterable: customAutoCompleteFilter },
                    { field: "NewValue", title: "New Value", filterable: customAutoCompleteFilter },
                    { field: "ModifiedBy", title: "Modified By", filterable: customAutoCompleteFilter },
                    { field: "CompanyName", title: "Company", filterable: customAutoCompleteFilter },

                    { field: "TitleField1", title: "Title Field 1", hidden: true, filterable: customAutoCompleteFilter },
                    { field: "TitleField2", title: "Title Field 2", hidden: true, filterable: customAutoCompleteFilter },
                    { field: "RecId", title: "Rec Id", hidden: true, filterable: customAutoCompleteFilter },
                    { field: "CompanyId", title: "Company Id", hidden: true, filterable: customAutoCompleteFilter },
                    { field: "TableId", title: "Table Id", hidden: true, filterable: customAutoCompleteFilter },
                    { field: "ColumnId", title: "Column Id", hidden: true, filterable: customAutoCompleteFilter },
                    { field: "ObjRecId", title: "Obj Rec Id", hidden: true, filterable: customAutoCompleteFilter },
                    { field: "TicketId", title: "Ticket Id", hidden: true, filterable: customAutoCompleteFilter },
                    { field: "AtHeaderTicketId", title: "Header Ticket Id", hidden: true, filterable: customAutoCompleteFilter },
                    { field: "TicketUrl", title: "Ticket Url", hidden: true, filterable: customAutoCompleteFilter },
                    { field: "TicketProvider", title: "Ticket Provider", hidden: true, filterable: customAutoCompleteFilter },
                    { field: "TableName", title: "Table Name", hidden: true, filterable: customAutoCompleteFilter },
                    { field: "ColumnName", title: "Column Name", hidden: true, filterable: customAutoCompleteFilter }
                ];

            } else if (vm.selectedProduct.Type === 'SAP') {
                $scope.mainGridColumns = [
                    { title: "", template: rowSettingsTemplate, attributes: { "class": "no-padding overflow-visible" }, minResizableWidth: 22, width: 22 },
                    { field: "TicketDisplayName", title: "Ticket", template: ticketTemplate, filterable: false, sortable: false },
                    { field: "ChangeDate", title: "Date", template: "{{ dataItem.ChangeDate | date:'short'}}", filterable: customDatePickerNotNullableFilter },
                    { field: "Template", title: "Template", filterable: customAutoCompleteFilter },
                    { field: "TableName", title: "Table", filterable: customAutoCompleteFilter },
                    { field: "Event", title: "Event", filterable: customAutoCompleteFilter },
                    { field: "FieldName", title: "Field", filterable: customAutoCompleteFilter },
                    { field: "OldValue", title: "Old Value", filterable: customAutoCompleteFilter },
                    { field: "NewValue", title: "New Value", filterable: customAutoCompleteFilter },
                    { field: "User", title: "User", filterable: customAutoCompleteFilter }
                ];
            } else if (vm.selectedProduct.Type === 'OR') {
                $scope.mainGridColumns = [
                    { title: "", template: rowSettingsTemplate, attributes: { "class": "no-padding overflow-visible" }, minResizableWidth: 22, width: 22 },
                    { field: "TicketDisplayName", title: "Ticket", template: ticketTemplate, filterable: false, sortable: false },
                    { field: "ChangeDate", title: "Date", template: "{{ dataItem.ChangeDate | date:'short'}}", filterable: customDatePickerNotNullableFilter },
                    { field: "Template", title: "Template", filterable: customAutoCompleteFilter },
                    { field: "TableName", title: "Table", filterable: customAutoCompleteFilter },
                    { field: "KeyField", title: "Key Field", filterable: customAutoCompleteFilter },
                    { field: "Event", title: "Event", filterable: customAutoCompleteFilter },
                    { field: "FieldName", title: "Field", filterable: customAutoCompleteFilter },
                    { field: "FieldDescription", title: "Field Description", filterable: customAutoCompleteFilter },
                    { field: "OldValue", title: "Old Value", filterable: customAutoCompleteFilter },
                    { field: "NewValue", title: "New Value", filterable: customAutoCompleteFilter },
                    { field: "User", title: "User", filterable: customAutoCompleteFilter },
                    { field: "Responsibility", title: "Responsibility", filterable: customAutoCompleteFilter },
                    { field: "Application", title: "Application", filterable: customAutoCompleteFilter }
                ];
            } else if (vm.selectedProduct.Type === 'AX') {
                $scope.mainGridColumns = [
                    { title: "", template: rowSettingsTemplate, attributes: { "class": "no-padding overflow-visible" }, minResizableWidth: 22, width: 22 },
                    { field: "TicketDisplayName", title: "Ticket", template: ticketTemplate, filterable: false, sortable: false },
                    { field: "TimeStamp", title: "Date", template: "{{ dataItem.TimeStamp | date:'short'}}", filterable: customDatePickerNotNullableFilter },
                    { field: "Event", title: "Event", filterable: customAutoCompleteFilter },
                    { field: "TableName", title: "Table Name", filterable: customAutoCompleteFilter },
                    { field: "TableSystemName", title: "Table System Name", hidden: true, filterable: customAutoCompleteFilter },
                    { field: "ColumnName", title: "Column Name", filterable: customAutoCompleteFilter },
                    { field: "ColumnSystemName", title: "Column System Name", hidden: true, filterable: customAutoCompleteFilter },
                    { field: "CompanyName", title: "Company Name", filterable: customAutoCompleteFilter },
                    { field: "CompanyId", title: "Company Id", hidden: true, filterable: customAutoCompleteFilter },
                    { field: "OldValue", title: "Old Value", filterable: customAutoCompleteFilter },
                    { field: "NewValue", title: "New Value", filterable: customAutoCompleteFilter },
                    { field: "User", title: "User", filterable: customAutoCompleteFilter },
                    { field: "Application", title: "Application", filterable: customAutoCompleteFilter },
                    { field: "Machine", title: "Machine", filterable: customAutoCompleteFilter },
                    { field: "KeyField", title: "Key Field", filterable: customAutoCompleteFilter }
                ];
            } else if (vm.selectedProduct.Type === 'NAV' || vm.selectedProduct.Type === 'SL' || vm.selectedProduct.Type === 'AX5') {
                $scope.mainGridColumns = [
                    { title: "", template: rowSettingsTemplate, attributes: { "class": "no-padding overflow-visible" }, minResizableWidth: 22, width: 22 },
                    { field: "TicketDisplayName", title: "Ticket", template: ticketTemplate, filterable: false, sortable: false },
                    { field: "Date", title: "Date", template: "{{ dataItem.Date | date:'short'}}", filterable: customDatePickerNotNullableFilter },
                    { field: "TableName", title: "Table", filterable: customAutoCompleteFilter },
                    { field: "Event", title: "Event", filterable: customAutoCompleteFilter },
                    { field: "Company", title: "Company", filterable: customAutoCompleteFilter },
                    { field: "KeyField", title: "Key Field", filterable: customAutoCompleteFilter },
                    { field: "ColumnName", title: "Column Name", filterable: customAutoCompleteFilter },
                    { field: "OldValue", title: "Old Value", filterable: customAutoCompleteFilter },
                    { field: "NewValue", title: "New Value", filterable: customAutoCompleteFilter },
                    { field: "User", title: "User", filterable: customAutoCompleteFilter },
                    { field: "Application", title: "Application", filterable: customAutoCompleteFilter },
                    { field: "Machine", title: "Machine", filterable: customAutoCompleteFilter }
                ];
            } else if (vm.selectedProduct.Type === 'GP') {
                $scope.mainGridColumns = [
                    { title: "", template: rowSettingsTemplate, attributes: { "class": "no-padding overflow-visible" }, minResizableWidth: 22, width: 22 },
                    { field: "TicketDisplayName", title: "Ticket", template: ticketTemplate, filterable: false, sortable: false },
                    { field: "Date", title: "Date", template: "{{ dataItem.Date | date:'short'}}", filterable: customDatePickerNotNullableFilter },
                    { field: "TableName", title: "Table", filterable: customAutoCompleteFilter },
                    { field: "UserTableName", title: "Table Name", filterable: customAutoCompleteFilter },
                    { field: "Event", title: "Event", filterable: customAutoCompleteFilter },
                    { field: "Company", title: "Company", filterable: customAutoCompleteFilter },
                    { field: "KeyField", title: "Key Field", filterable: customAutoCompleteFilter },
                    { field: "ColumnName", title: "Column Name", filterable: customAutoCompleteFilter },
                    { field: "OldValue", title: "Old Value", filterable: customAutoCompleteFilter },
                    { field: "NewValue", title: "New Value", filterable: customAutoCompleteFilter },
                    { field: "User", title: "User", filterable: customAutoCompleteFilter },
                    { field: "Application", title: "Application", filterable: customAutoCompleteFilter },
                    { field: "Machine", title: "Machine", filterable: customAutoCompleteFilter }
                ];
            } else if (vm.selectedProduct.Type === 'D365Bc') {
                $scope.mainGridColumns = [
                    { title: "", template: rowSettingsTemplate, attributes: { "class": "no-padding overflow-visible" }, minResizableWidth: 22, width: 22 },
                    { field: "TicketDisplayName", title: "Ticket", template: ticketTemplate, filterable: false, sortable: false },
                    { field: "dateandTime", title: "Date And Time", template: "{{ dataItem.dateandTime | date:'short'}}", filterable: customDatePickerNotNullableFilter },
                    { field: "TableName", title: "Table Name", filterable: customAutoCompleteFilter },
                    { field: "tableNo", title: "Table Number", hidden: true, filterable: customAutoCompleteFilter },
                    { field: "FieldName", title: "Field Name", filterable: customAutoCompleteFilter },
                    { field: "fieldNo", title: "Field Number", hidden: true, filterable: customAutoCompleteFilter },
                    { field: "typeOfChange", title: "Change Type", filterable: customAutoCompleteFilter },
                    { field: "oldValue", title: "Old Value", filterable: customAutoCompleteFilter },
                    { field: "newValue", title: "New Value", filterable: customAutoCompleteFilter },
                    { field: "userId", title: "Modified By", filterable: customAutoCompleteFilter },
                    { field: "primaryKeyField1Value", title: "Primary Key Field 1 Value", filterable: customAutoCompleteFilter },
                    { field: "primaryKeyField1No", title: "Primary Key Field 1 Number", hidden: true, filterable: customAutoCompleteFilter },
                    { field: "primaryKeyField2Value", title: "Primary Key Field 2 Value", filterable: customAutoCompleteFilter },
                    { field: "primaryKeyField2No", title: "Primary Key Field 2 Number", hidden: true, filterable: customAutoCompleteFilter },
                    { field: "primaryKeyField3Value", title: "Primary Key Field 3 Value", filterable: customAutoCompleteFilter },
                    { field: "primaryKeyField3No", title: "Primary Key Field 3 Number", hidden: true, filterable: customAutoCompleteFilter },
                    { field: "recId", title: "Record Id", hidden: true, filterable: customAutoCompleteFilter }
                ];
            } else if (vm.selectedProduct.Type === 'SF') {
                $scope.mainGridColumns = [
                    { title: "", template: rowSettingsTemplate, attributes: { "class": "no-padding overflow-visible" }, minResizableWidth: 22, width: 22 },
                    { field: "TicketDisplayName", title: "Ticket", template: ticketTemplate, filterable: false, sortable: false },
                    { field: "CreatedDate", title: "Created Date", template: "{{ dataItem.CreatedDate | date:'short'}}", filterable: customDatePickerNotNullableFilter },
                    { field: "CreatedBy", title: "Created By", filterable: customAutoCompleteFilter },
                    { field: "Object", title: "Object", filterable: customAutoCompleteFilter },
                    { field: "ObjectName", title: "Object Name", filterable: customAutoCompleteFilter },
                    { field: "Field", title: "Field", filterable: customAutoCompleteFilter },
                    { field: "DataType", title: "Data Type", filterable: customAutoCompleteFilter },
                    { field: "OldValue", title: "Old Value", filterable: customAutoCompleteFilter },
                    { field: "NewValue", title: "New Value", filterable: customAutoCompleteFilter }
                ];
            } else if (vm.selectedProduct.Type === 'WD') {
                $scope.mainGridColumns = [
                    { field: "InstanceId", title: "Instance Id", filterable: customAutoCompleteFilter },
                    { field: "WorkdayId", title: "Workday Id", filterable: customAutoCompleteFilter },
                    { field: "Date", title: "Date", template: "{{ dataItem.Date | date:'short'}}", filterable: customDatePickerNotNullableFilter },
                    { field: "ClassesUpdated", title: "Classes Updated", filterable: customAutoCompleteFilter },
                    { field: "TaskName", title: "Task Name", filterable: customAutoCompleteFilter },
                    { field: "Event", title: "Event", filterable: customAutoCompleteFilter },
                    { field: "ChangeType", title: "Change Type", filterable: customAutoCompleteFilter },
                    { field: "Description", title: "Description", filterable: customAutoCompleteFilter },
                    { field: "OldValue", title: "Old Value", filterable: customAutoCompleteFilter },
                    { field: "NewValue", title: "New Value", filterable: customAutoCompleteFilter },
                    { field: "ModifiedBy", title: "Modified By", filterable: customAutoCompleteFilter }
                ];
            } else if (vm.selectedProduct.IsCustom) {
                $scope.mainGridColumns = [
                    { title: "", template: rowSettingsTemplate, attributes: { "class": "no-padding overflow-visible" }, minResizableWidth: 22, width: 22 },
                    { field: "TicketDisplayName", title: "Ticket", template: ticketTemplate, filterable: false, sortable: false },
                    { field: "Date", title: "Date", template: "{{ dataItem.Date | date:'short'}}", filterable: customDatePickerNotNullableFilter },
                    { field: "TableName", title: "Table", filterable: customAutoCompleteFilter },
                    { field: "UserTableName", title: "Table Name", filterable: customAutoCompleteFilter },
                    { field: "Event", title: "Event", filterable: customAutoCompleteFilter },
                    { field: "Company", title: "Company", filterable: customAutoCompleteFilter },
                    { field: "KeyField", title: "Key Field", filterable: customAutoCompleteFilter },
                    { field: "ColumnName", title: "Column Name", filterable: customAutoCompleteFilter },
                    { field: "OldValue", title: "Old Value", filterable: customAutoCompleteFilter },
                    { field: "NewValue", title: "New Value", filterable: customAutoCompleteFilter },
                    { field: "User", title: "User", filterable: customAutoCompleteFilter },
                    { field: "Application", title: "Application", filterable: customAutoCompleteFilter },
                    { field: "Machine", title: "Machine", filterable: customAutoCompleteFilter }
                ];
            }

            // Don't allow filtering and sorting yet because they will force the report to run
            if (!$rootScope.loadedSavedReport) {
                $scope.mainGridOptions.sortable = false;

                $scope.mainGridOptions.filterable = false;
            }

            vm.setLayout();
        }
    };

    vm.setLayout = function () {

        var productId;
        if (vm.selectedProduct) {
            productId = vm.selectedProduct.ID;
        }

        if (!$rootScope.loadedSavedReport) {
            CustomReportServices.loadSavedGridLayout($scope, productId);
        }
    };

    $scope.filterManagerGridOptions = CustomReportServices.setfilterManagerGridOptions();

    $scope.filterDropdownOptions = helperService.getFilterDropdownOptions();

    $scope.applySelectedFilter = function () {
        helperService.applyFilterCatchError($scope);
    };

    $scope.deleteFilter = function (id) {
        CustomReportServices.deleteFilterFilterManager($scope, id);
    };

    $scope.clearAllFilters = function () {
        $scope.gridDataSource.filter({});
    };

    $scope.saveReportLayoutButton = function () {
        CustomReportServices.saveGridLayout($scope, vm.selectedProduct.ID);
    };

    //Date picker settings
    $scope.setDates = function () {
        var newDate = new Date();
        newDate.setDate(newDate.getDate() - $rootScope.UserPreferences.DefaultDaysForAuditTrailReports);
        vm.LastNumberOf = $rootScope.UserPreferences.DefaultDaysForAuditTrailReports;

        $scope.vm.startDate = newDate;
        $scope.vm.endDate = new Date();
    };
    $scope.setDates();

    $scope.getDateFilters = function () {
        var dateCriteria = $scope.dateCriteria[0];

        if (vm.selectedProduct.Type === 'AX') {
            dateCriteria.FieldName = "TimeStamp";
        } else if (vm.selectedProduct.Type === 'AX7') {
            dateCriteria.FieldName = "ModifiedOn";
        } else if (vm.selectedProduct.Type === 'D365Bc') {
            dateCriteria.FieldName = "dateandTime";
        } else if (vm.selectedProduct.Type === 'SF') {
            dateCriteria.FieldName = "CreatedDate";
        } else {
            dateCriteria.FieldName = "Date";
        }

        if (dateCriteria) {
            var dateTimeFieldName = dateCriteria.FieldName;
            var criteria = { CriteriaType: 'Date', CriteriaValue: dateTimeFieldName, Filters: [], DateFilter: null };
            var returnObject = {
                Criteria: {},
                Filters: [],
                RelativeFilter: null
            };

            var startDate = helperService.setDateToBeginningOfDay($scope.vm.startDate);
            var endDate = helperService.setDateToEndOfDay($scope.vm.endDate);

            if (vm.RelativeDateType === 4) {
                var startDateRow = {
                    "FieldName": dateTimeFieldName,
                    "Operator": 'GreaterThanOrEqual',
                    "Value": startDate
                };
                var endDateRow = {
                    "FieldName": dateTimeFieldName,
                    "Operator": 'LessThanOrEqual',
                    "Value": endDate
                };

                returnObject.Filters.push(startDateRow);
                returnObject.Filters.push(endDateRow);

                criteria.Filters.push(startDateRow);
                criteria.Filters.push(endDateRow);

                returnObject.Criteria = criteria;

            }
            if (vm.RelativeDateType !== 4) {
                var relativeDate = {
                    "DateType": vm.RelativeDateType,
                    "FieldName": dateTimeFieldName,
                    "Period": vm.PeriodType,
                    "Value": vm.RelativeDateType === 2 ? vm.LastNumberOf : null
                };

                returnObject.RelativeFilter = relativeDate;
                criteria.DateFilter = relativeDate;
                returnObject.Criteria = criteria;
            }
        }

        return returnObject;
    };

    $scope.getPeriodId = function (name) {
        if (name) {
            return vm.PeriodTypeDataSource.find(function (type) {
                return type.Name === name;
            }).Id;
        }
    };

    // --- FOR ADVANCED FILTERING ---
    function setReportMetadata() {
        return reportsDataService.getDataChangesReportMetadata(vm.selectedProduct.Type.toLowerCase(), vm.selectedProduct.IsCustom)
            .then(data => {
                vm.reportMetadata = data.data._embedded.reportData;
                vm.reportMetadata.Columns = $filter('orderBy')(vm.reportMetadata.Columns, 'DisplayName');
                vm.filterCriteria.fields = reportsService.getFields(vm.reportMetadata.Columns);
                vm.filterCriteria.fields.forEach(field => field.displayName = $filter('insertSpaceBetweenCapitalWords')(field.displayName));
                if ($rootScope.loadedSavedReport) {
                    $scope.runSavedReport();
                }
            });
    }
    // ---

}