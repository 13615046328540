import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, helperService, approvalGroupsDataService, dataService, $filter, group, level, selectedApprovers) {

    const vm = this;
    vm.level = Object.assign({}, level);
    vm.group = Object.assign({}, group);
    vm.selectedApprovers = selectedApprovers;
    vm.addApproverToLevel = addApproverToLevel;
    vm.close = close;

    activate();

    function activate() {
        configureApproversGrid();
    }

    function close() {
        $uibModalInstance.close();
    }

    async function addApproverToLevel(approver) {
        const data = {
            Id: vm.level.Id,
            ApprovalGroupId: vm.group.Id,
            UserId: approver.UserID
        };
        await approvalGroupsDataService.addApproverToApprovalGroupLevel(vm.group.Id, vm.level.Id, data);
        const formattedApprover = {
            Name: approver.UserLogonName,
            UserId: approver.UserID
        };
        vm.selectedApprovers.push(formattedApprover);
        vm.approversGridDataSource.read();
    }

    function configureApproversGrid() {
        const approversGridColumns = [
            { title: "Name", field: "UserLogonName", filterable: customAutoCompleteFilter },
            { title: "Email", field: "Email", filterable: customAutoCompleteFilter },
            { template: "<a ng-click='amvm.addApproverToLevel(dataItem)'><i class='fa fa-plus pull-right'></i></a>", title: " ", width: 20 }
        ];

        vm.approversGridOptions = helperService.setMainGridOptions(approversGridColumns, null);
        vm.approversGridOptions.selectable = false;
        vm.approversGridOptions.noRecords = {
            template: "<div class='no-data-to-display-message'>All Approvers Are Currently Assigned</div>"
        };

        vm.approversGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: {
                model: {
                    id: "UserID"
                }
            },
            transport: {
                read: async options => {
                    try {
                        let response = await dataService.getUsers();
                        response.data = $filter('orderBy')(response.data, 'UserLogonName');
                        response.data = response.data.filter(obj => obj.Active && !vm.selectedApprovers.some(o => o.UserId === obj.UserID));
                        options.success(response.data);
                    } catch (error) {
                        options.error(error);
                    }
    }
}
        });
    }
}