import createSpinner from "../Functions/createSpinner";
import swal from "sweetalert2";
import allSupportedProducts from "../GlobalVariables/ProductLookUp";

export default /*@ngInject*/ function ($http, $rootScope, $window, $location, $timeout, $route, $filter, authenticationService, applicationInsightsService) {
    const service = {
        setDateToBeginningOfDay,
        setDateToEndOfDay,
        convertDateToSelectedTimeZone,
        convertUTCDateTimeStringToDateString,
        setNonPageableGridOptions,
        getSecurityObjectById,
        compareTwoObjectsForMatch,
        returnTrueIfValuesDiffer,
        successfulSaveButton,
        determineDataChangesLinkProduct,
        productNameLookUp,
        moduleLookUpById,
        manageDataTypeNameLookUp,
        convertConfigValueToTrueFalse,
        allPluginsTurnedOff,
        valueMapperFunction,
        multiValueMapperFunction,
        roleMapperFunction,
        userMapperFunction,
        generateRandomUuid,
        setMainGridOptions,
        goToHomePage,
        backToPreviousPage,
        smallLoad,
        determineContainerClass,
        setProductImageClass,
        setProviderImageClass,
        setStatusCssClass,
        setStatusIconColor,
        getDayOfMonthDropdownOptions,
        getMonthsDropdownOptions,
        cleanReportColumns,
        halPrepareSortArray,
        halPrepareFilterArray,
        prepareSortsAndFilters,
        getFilterDropdownOptions,
        returnNullIfAllIsFound,
        deselectAllObjectsIfOtherIsClicked,
        displayPipeBetween,
        displayPipeBetweenObjectsDrop,
        convertFiltersForGrid,
        applyFilterCatchError,
        getObjectDetails,
        removeSpaces,
        changeMainLocation,
        getTabIndex,
        changeReportView,
        changeView,
        setFavoriteValue,
        getConfigurationValue,
        setConfigurationValue,
        setConfigurationValueClosePopup,
        checkFavorite,
        areYouSureParams,
        areYouSureParamsWithHTML,
        showErrorMessage,
        showConfirmationMessage,
        showConfirmationMessageNoButtons,
        navigateTo,
        refreshPage,
        goBack,
        clone,
        camelCaseToSentence,
        splitHeaderText,
        headerStringTransformer,
        getBaseURL,
        isJson,
        objectExists,
        getApplicationName,
        getProductName,
        getSecurityObjectDetailsFromObjectId,
        getSecurityObjectDetailsFromObjectIdAndProductType,
        getSecurityObjectDetailsFromReportId,
        getAccessName,
        getUserName,
        toUTF8Array,
        setPluginImageClass
    };

    return service;

    function setDateToBeginningOfDay(startDate) {
        startDate = $filter('date')(new Date(startDate), 'longDate');
        startDate = new Date(startDate);
        return startDate;
    }

    function setDateToEndOfDay(endDate) {
        endDate = $filter('date')(new Date(endDate), 'longDate');
        endDate = new Date(endDate);
        endDate.setHours(24);
        endDate.setSeconds(endDate.getSeconds() - 1);
        return endDate;
    }

    function convertDateToSelectedTimeZone(date, decimalOffset) {
        date = new Date(date);
        var offset = date.getTimezoneOffset();
        var selectedTimeZoneOff = decimalOffset;

        var utc = date.getTime() + (date.getTimezoneOffset() * 60000);
        var nd = new Date(utc + (3600000 * selectedTimeZoneOff));

        return nd;
    }

    //Ignores Time Zone
    function convertUTCDateTimeStringToDateString(dateTime) {
        const dateObj = new Date(dateTime),
            month = dateObj.getUTCMonth() + 1,
            day = dateObj.getUTCDate(),
            year = dateObj.getUTCFullYear();

        return year + "/" + month + "/" + day;
    }

    function setNonPageableGridOptions(columns, height) {

        var gridOptions = {
            sortable: true,
            scrollable: true,
            reorderable: true,
            resizable: true,
            allowCopy: true,
            pageable: { numeric: false, previousNext: false, messages: { display: "Total: {2}" } },
            columns: columns,
            height: height

        };

        return gridOptions;
    }

    function getSecurityObjectById(objectId) {
        var userSecurityList = angular.fromJson(localStorage.getItem("UserSecurityObjects"));

        var userSecurityObject = null;

        if (userSecurityList) {
            userSecurityObject = userSecurityList.find(function (object) { return object.ObjectID == objectId; });
            return userSecurityObject;
        } else {
            return null;
        }

    }

    function compareTwoObjectsForMatch(object1, object2, compareList) {

        var compareResults = [];

        compareList.forEach(function (row) {
            var list1 = JSON.stringify($filter('orderBy')(object1[row.Key], row.Sort));
            var list2 = JSON.stringify($filter('orderBy')(object2[row.Key], row.Sort));
            var match = list1 === list2;
            compareResults[row.Key] = match;
        });

        var result = false;

        for (var i in compareResults) {
            if (compareResults[i] === false) {
                result = true;
                break;
            }
        }

        return result;
    }

    function returnTrueIfValuesDiffer(valuesToCheck, origArray, currentArray) {
        if (origArray && currentArray) {

            for (var i = 0; i < valuesToCheck.length; i++) {
                var item = valuesToCheck[i];
                var configValue = currentArray[item];
                var origConfigValue = origArray[item];

                if (configValue !== origConfigValue) {
                    if (origConfigValue === null) {
                        if (configValue !== "") {
                            return true;
                        }
                    } else {
                        return true;
                    }
                }
            }

        }
    }

    function successfulSaveButton(inlineSaver) {

        return new Promise(function (resolve, reject) {

            var currentTime = new Date().getTime();
            var startTime = inlineSaver.spinnerStart.getTime();

            var difference = currentTime - startTime;
            var minSpinTime = 800;

            function setValues() {
                inlineSaver.loadingValue = false;
                inlineSaver.savedValue = true;
                $timeout(function () {
                    inlineSaver.savedValue = false;
                    resolve();
                }, 2000);
            }

            if (difference > minSpinTime) {
                //User has waited long enough
                setValues();
            } else {
                $timeout(function () {
                    setValues();
                }, minSpinTime - difference);
            }


        });

    }

    function determineDataChangesLinkProduct(TicketProvider) {
        if (TicketProvider === 'Zendesk') {
            return 'zendesk-avatar-logo';
        } else if (TicketProvider === 'Jira') {
            return 'jira-avatar-logo';
        }
    }

    function productNameLookUp(productId) {
        var product = allSupportedProducts.filter(e => e.ID == productId)[0];
        if (product) {
            return product.Name;
        }
    }

    function moduleLookUpById(parentId) {
        var NavigationObjects = angular.fromJson(localStorage.getItem("NavigationObjects"));
        var module = NavigationObjects.filter(e => e.ObjectID == parentId)[0];
        if (module) {
            return module.Name;
        }
    }

    function manageDataTypeNameLookUp(typeId) {
        if (typeId === 1) {
            return 'Archive';
        } else if (typeId === 2) {
            return 'Purge';
        } else if (typeId === 3) {
            return 'Restore';
        }
    }

    function convertConfigValueToTrueFalse(configName) {
        var configValues = JSON.parse(localStorage.getItem("ConfigurationValues"));
        if (configValues[configName] == "1") {
            return false;
        } else {
            return true;
        }
    }

    function allPluginsTurnedOff() {
        var configValues = JSON.parse(localStorage.getItem("ConfigurationValues"));
        if (configValues.ZDK_Active === '1' || configValues.JIRA_Active === '1') {
            return false;
        } else {
            return true;
        }
    }

    function valueMapperFunction(options, dataSource, primaryKey) {
        var firstMatch = null;

        if (dataSource) {
            firstMatch = dataSource.find(function (n) {
                return n[primaryKey] === options.value;
            });
        }

        if (!firstMatch) {
            return {};
        } else {
            return firstMatch;
        }
    }

    function multiValueMapperFunction(options, dataSource, primaryKey) {
        let matches = null;

        if (dataSource) {
            matches = dataSource.filter(item => options.value.some(value => value === item[primaryKey]));
        }
        return matches;
    }

    async function roleMapperFunction(roleIdentifiers) {

        const data = {
            Roles: roleIdentifiers.map(identifier => {
                return { Identifier: identifier };
            })
        };

        const res = await $http.post(`${apiUrl}api/universal/securityroles/valuemapper`, data);

        return res.data;

    }

    async function userMapperFunction(userIds) {
        const data = {
            UserMasters: userIds.map(id => {
                return { ID: id };
            })
        };

        const res = await $http.post(`${apiUrl}api/universal/usermaster/valuemapper`, data);

        return res.data;

    }

    function generateRandomUuid(dataArray) {
        dataArray.forEach(function (row, i) {
            row.PrimaryKey = i;
        });

        return dataArray;
    }

    function setMainGridOptions(columns, gridHeight) {

        var mainGridOptions = {
            filterable: {
                mode: "row"
            },
            noRecords: {
                template: "<div class='no-data-to-display-message'>No items to display</div>"
            },
            resizable: true,
            reorderable: true,
            groupable: false,
            sortable: true,
            height: gridHeight,
            selectable: true,
            allowCopy: true,
            pageable: {
                responsive: false
            },
            columns: columns

        };

        return mainGridOptions;
    }

    function goToHomePage() {
        $location.path('').search({});
    }

    function backToPreviousPage() {
        localStorage.setItem("previousUrl", window.location.href, { path: "/" });
        $window.history.back();
    }

    function smallLoad() {
        var options = { radius: 4, width: 1, length: 2 };
        return options;
    }

    function determineContainerClass(type) {
        switch (type) {
            case 'Success':
                return 'toast-success';
            case 'Error':
                return 'toast-error';
            case 'Warning':
                return 'toast-warning';
            case 'Info':
                return 'toast-info';
            case 'InProgress':
                return 'toast-loading';
        }
    }

    function setProductImageClass(ProductTypeOrName) {
        const productImageMap = {
            'OR': 'oracle-avatar-logo',
            'AX7': 'dynamics-365-avatar-logo',
            'D365S': 'dynamics-365-sales-avatar-logo',
            'GP': 'gp-avatar-logo',
            'NS': 'netSuite-avatar-logo',
            'SAP': 'sap-avatar-logo',
            'SF': 'salesforce-avatar-logo',
            'Salesforce': 'salesforce-avatar-logo',
            'INT': 'intacct-avatar-logo',
            'Intacct': 'intacct-avatar-logo',
            'NAV': 'nav-avatar-logo',
            'AX': 'ax-avatar-logo',
            'SL': 'sl-avatar-logo',
            'SAPB1': 'sapb1-avatar-logo',
            'SapGrc': 'sap-avatar-logo',
            'AC': 'acumatica-avatar-logo',
            'PS': 'peoplesoft-avatar-logo',
            'Peoplesoft': 'peoplesoft-avatar-logo',
            'ORFC': 'orfc-avatar-logo',
            'OREPM': 'orfc-avatar-logo',
            'JDE': 'jde-avatar-logo',
            'AX5': 'ax-avatar-logo',
            'AAD': 'azure-active-directory-avatar-logo',
            'ALA': 'ala-avatar-logo',
            'AD': 'active-directory-avatar-logo',
            'D365Bc': 'dynamics-365-businesscentral-avatar-logo',
            'CPA': 'coupa-avatar-logo',
            'WD': 'workday-avatar-logo',
            'Workday': 'workday-avatar-logo',
            'Zuo': 'zuo-avatar-logo',
            'Freshservice': 'freshservice-avatar-logo',
            'AuditBoard': 'auditboard-avatar-logo',
            'AZ': 'azure-avatar-logo',
            'ADO': 'azure-devops-avatar-logo',
            'ARB': 'ariba-avatar-logo',
            'CNC': 'concur-avatar-logo'
        };
        if (!productImageMap[ProductTypeOrName]) {
            return 'tile-23fa3370-b057-489e-b6bb-49aa2a2fa040';
        }
        if (ProductTypeOrName) {
            return productImageMap[ProductTypeOrName];
        }
    }

    function setProviderImageClass(identityProvider) {
        if (!identityProvider) {
            return;
        }

        let image = null;
        switch (identityProvider) {
            case '7b29af8a-c18f-4e26-9b78-d118aa87d577':
                image = 'FastpathAccount';
                break;
            case '3763bd0e-9d71-4c6b-9874-450773e0a47e':
                image = 'AADExchange';
                break;
            default:
                image = 'SAML2';
                break;
        }

        return image;
    }

    function setStatusCssClass(status) {
        if (status === 'Pending') {
            return 'label-warning';
        } if (status === 'Approved') {
            return 'label-success';
        } if (status === 'Denied') {
            return 'label-danger';
        } if (status === 'Completed') {
            return 'label-success';
        } if (status === 'Cancelled') {
            return null;
        } if (status === 'ProcessingError') {
            return 'label-danger';
        } if (status === 'AwaitingADAccountCreation') {
            return 'label-warning';
        } if (status === 'AwaitingEffectiveDateProcessing') {
            return 'label-warning';
        } if (status === 'ApprovalInProgress') {
            return 'label-warning';
        } if (status === 'InProgress') {
            return 'label-warning';
        }
    }

    function setStatusIconColor(status) {
        if (status === 'Pending') {
            return 'text-warning';
        } if (status === 'Approved') {
            return 'text-success';
        } if (status === 'Denied') {
            return 'text-danger';
        } if (status === 'Completed') {
            return 'text-success';
        } if (status === 'Cancelled') {
            return null;
        } if (status === 'ProcessingError') {
            return 'text-danger';
        } if (status === 'AwaitingADAccountCreation') {
            return 'text-warning';
        } if (status === 'AwaitingEffectiveDateProcessing') {
            return 'text-warning';
        } if (status === 'ApprovalInProgress') {
            return 'text-warning';
        }
    }

    function getDayOfMonthDropdownOptions() {
        return [{ Name: 1, Value: 1 }, { Name: 2, Value: 2 }, { Name: 3, Value: 3 }, { Name: 4, Value: 4 }, { Name: 5, Value: 5 }, { Name: 6, Value: 6 }, { Name: 7, Value: 7 }, { Name: 8, Value: 8 }, { Name: 9, Value: 9 }, { Name: 10, Value: 10 }, { Name: 11, Value: 11 }, { Name: 12, Value: 12 }, { Name: 13, Value: 13 }, { Name: 14, Value: 14 }, { Name: 15, Value: 15 }, { Name: 16, Value: 16 }, { Name: 17, Value: 17 }, { Name: 18, Value: 18 }, { Name: 19, Value: 19 }, { Name: 20, Value: 20 }, { Name: 21, Value: 21 }, { Name: 22, Value: 22 }, { Name: 23, Value: 23 }, { Name: 24, Value: 24 }, { Name: 25, Value: 25 }, { Name: 26, Value: 26 }, { Name: 27, Value: 27 }, { Name: 28, Value: 28 }, { Name: 29, Value: 29 }, { Name: 30, Value: 30 }, { Name: 31, Value: 31 }, { Name: 'Last Day of Month', Value: -1 }];
    }

    function getMonthsDropdownOptions() {
        return [{ Name: 'January', Value: 1 }, { Name: 'February', Value: 2 }, { Name: 'March', Value: 3 }, { Name: 'April', Value: 4 }, { Name: 'May', Value: 5 }, { Name: 'June', Value: 6 }, { Name: 'July', Value: 7 }, { Name: 'August', Value: 8 }, { Name: 'September', Value: 9 }, { Name: 'October', Value: 10 }, { Name: 'November', Value: 11 }, { Name: 'December', Value: 12 }];
    }

    function cleanReportColumns(columns) {
        var formattedArray = [];
        if (columns) {
            columns.forEach(function (row, i) {
                if (row.hidden === false || !row.hidden) {
                    if (row.field) {
                        var formattedRow = { "FieldName": row.field, "DisplayName": row.title, "Ordinal": i };
                        formattedArray.push(formattedRow);
                    }
                }
            });
        }
        return formattedArray;
    }

    function halPrepareSortArray(sort) {
        var formattedSortArray = [];
        if (sort) {
            sort.forEach(function (row) {
                var formattedRow = { "FieldName": row.field, "SortDirection": row.dir.toUpperCase() };
                formattedSortArray.push(formattedRow);
            });
        }
        return formattedSortArray;
    }

    function halPrepareFilterArray(filter) {
        var formattedFilterArray = [];
        if (filter && filter.filters) {
            filter.filters.forEach(function (row) {

                if (row.operator === 'eq') {
                    row.operator = "Equals";
                } else if (row.operator === 'contains') {
                    row.operator = "Contains";
                } else if (row.operator === 'gte') {
                    row.operator = "GreaterThanOrEqual";
                } else if (row.operator === 'gt') {
                    row.operator = "GreaterThan";
                } else if (row.operator === 'lte') {
                    row.operator = "LessThanOrEqual";
                } else if (row.operator === 'lt') {
                    row.operator = "LessThan";
                } else if (row.operator === 'neq') {
                    row.operator = "NotEquals";
                } else if (row.operator === 'doesnotcontain') {
                    row.operator = "DoesNotContain";
                } else if (row.operator === 'startswith') {
                    row.operator = "StartsWith";
                } else if (row.operator === 'endswith') {
                    row.operator = "EndsWith";
                }

                var formattedRow = {
                    "FieldName": row.field,
                    "Operator": row.operator,
                    "Value": row.value
                };
                formattedFilterArray.push(formattedRow);
            });
        }
        return formattedFilterArray;
    }

    function prepareSortsAndFilters(options) {
        return {
            "Filters": halPrepareFilterArray(options.data.filter),
            "Sorts": halPrepareSortArray(options.data.sort)
        };
    }

    function getFilterDropdownOptions() {
        return {
            dataTextField: "Name",
            dataValueField: "Id",
            optionLabel: {
                Name: "Select Filter",
                FilterString: {}
            },
            template: "<span ng-non-bindable>#: Name #</span>"
        };
    }

    function returnNullIfAllIsFound(listOfIds) {

        var allExists = false;

        listOfIds.forEach(function (row, i) {
            if (row == 'AllObjectsInMultiSelect') {
                allExists = true;
            }
        });

        if (allExists) {
            return null;
        } else {
            return listOfIds;
        }
    }

    function deselectAllObjectsIfOtherIsClicked(itemsSelected, allValue) {
        var returnValue = [];
        if (itemsSelected && itemsSelected.length > 0) {
            if ((itemsSelected[itemsSelected.length - 1].ID != null && itemsSelected[itemsSelected.length - 1].ID != 'AllObjectsInMultiSelect') || (itemsSelected[itemsSelected.length - 1].Id != null && itemsSelected[itemsSelected.length - 1].Id != 'AllObjectsInMultiSelect') || (itemsSelected[itemsSelected.length - 1].InternalId != null && itemsSelected[itemsSelected.length - 1].InternalId != 'AllObjectsInMultiSelect')) {
                itemsSelected.forEach(function (row, i) {
                    if ((row.ID != null && row.ID === 'AllObjectsInMultiSelect') || (row.Id != null && row.Id === 'AllObjectsInMultiSelect') || (row.InternalId != null && row.InternalId === 'AllObjectsInMultiSelect')) {
                        itemsSelected.splice(i, 1);
                        returnValue = itemsSelected;
                    } else {
                        returnValue = itemsSelected;
                    }
                });
            } else {
                returnValue = [allValue];
            }
        }

        return returnValue;
    }

    function displayPipeBetween(name, product) {
        if (product) {
            return name + ' | ' + product;
        } else {
            return name;
        }
    }

    function displayPipeBetweenObjectsDrop(name, altName, objectType, product) {
        var altNamePlusName = $filter('addPipeBeforeWord')(name, altName);
        if (product) {
            product = productNameLookUp(product);
            return altNamePlusName + ' | ' + objectType + ' | ' + product;
        } else {
            return altNamePlusName;
        }
    }

    function convertFiltersForGrid(currentFilter, newFilter) {
        var convertedNewFilters = [];

        newFilter.forEach(function (column, i) {

            if (column.Operator === "Equals") {
                column.Operator = "eq";
            } else if (column.Operator === "Contains") {
                column.Operator = "contains";
            } else if (column.Operator === "GreaterThanOrEqual") {
                column.Operator = "gte";
            } else if (column.Operator === "GreaterThan") {
                column.Operator = "gt";
            } else if (column.Operator === "LessThanOrEqual") {
                column.Operator = "lte";
            } else if (column.Operator === "LessThan") {
                column.Operator = "lt";
            } else if (column.Operator === "NotEquals") {
                column.Operator = "neq";
            } else if (column.Operator === "DoesNotContain") {
                column.Operator = "doesnotcontain";
            } else if (column.Operator === "StartsWith") {
                column.Operator = "startswith";
            } else if (column.Operator === "EndsWith") {
                column.Operator = "endswith";
            }

            var convertedFormat = { "value": column.Value, "operator": column.Operator, "field": column.FieldName, "ignoreCase": true };
            convertedNewFilters.push(convertedFormat);
        });

        return { "filters": convertedNewFilters, "logic": "and" };
    }

    function applyFilterCatchError(xscope) {
        var currentOptions = xscope.grid.getOptions();

        if (xscope.vm.selectedFilter != null && xscope.vm.selectedFilter.Filters != null && xscope.vm.selectedFilter.Filters.length > 0) {
            currentOptions.dataSource.filter = convertFiltersForGrid(currentOptions.dataSource.filter, xscope.vm.selectedFilter.Filters);

            try {
                xscope.grid.dataSource._filter = currentOptions.dataSource.filter;
                xscope.grid.dataSource.read();
            }
            catch (error) {
                console.log(error);
                xscope.gridDataSource.filter({});
            }
        }
    }

    function getObjectDetails(objectId) {
        return $http.get(apiUrl + 'api/core/objects/' + objectId).then(getComplete);

        //Return results
        function getComplete(response) {
            return response;
        }
    }

    function removeSpaces(stringValue) {
        var stringType = typeof stringValue;
        if (stringType === "string") {
            return stringValue.replace(/[\s]/g, '');
        } else {
            return stringValue;
        }
    }

    function changeMainLocation(link, module, viewName, type) {
        $location.path(link).search('');
    }

    function getTabIndex() {
        var hightestId = -1;

        if ($rootScope.tabs != null) {
            for (var i = 0; i < $rootScope.tabs.length; i++) {
                if ($rootScope.tabs[i].id > hightestId) {
                    hightestId = $rootScope.tabs[i].id;
                }
            }
        }

        return hightestId;
    }

    function changeReportView(moduleName, reportName, objectID, type) {

        var moduleNameNoSpaces = removeSpaces(moduleName);
        var reportNameNoSpaces = removeSpaces(reportName);

        var link = '/' + moduleNameNoSpaces + "/" + type + "/" + reportNameNoSpaces + "/" + objectID;

        if ($rootScope.previousURL == link) {
            $route.reload(link);
        } else {
            $location.path(link).search('');
        }
    }

    function changeView(moduleName, viewName, type) {
        var moduleNameNoSpaces = removeSpaces(moduleName);
        var viewNameNoSpaces = removeSpaces(viewName);
        var link = null;
        if (moduleNameNoSpaces != null && type != null && viewNameNoSpaces != null) {
            link = '/' + moduleNameNoSpaces + "/" + type + "/" + viewNameNoSpaces;
        } else if (moduleNameNoSpaces != null) {
            link = '/' + moduleNameNoSpaces;
        }

        if ($rootScope.previousURL == link) {
            $route.reload(link);
        } else {
            $location.path(link).search('');
        }

    }

    function setFavoriteValue() {
        var url = $location.path();
        return $http({
            method: 'GET',
            url: apiUrl + 'api/core/favorites/url/',
            params: { url: url }
        })
            .then(function successCallback(response) {
                if (response.data) {
                    return 1;
                }

            }, function errorCallback(response) {
                if (response.status === 404) {
                    return 0;
                }
            });
    }

    function getConfigurationValue(name) {

        var findConfigDfrd = $.Deferred();

        $http({
            method: 'GET',
            url: apiUrl + 'api/core/configuration'
        }).then(function (response) {

            var foundValue = null;
            var config = {};

            response.data.forEach(function (row) {
                config[row.Name] = row.Value;
            });

            foundValue = config[name];

            findConfigDfrd.resolve(foundValue);

        });

        return $.when(findConfigDfrd).done(function () {
        }).promise();
    }

    function setConfigurationValue(name, value) {

        var putData = {
            "Configurations": []
        };

        putData.Configurations.push({
            "Name": name,
            "Value": value
        });

        $http.put(apiUrl + 'api/core/configuration', putData).then(function successCallback(response) {

        }, function (response) {
            showErrorMessage(response.data);
        });
    }

    function setConfigurationValueClosePopup(name, value, vm) {

        var putData = {
            "Configurations": []
        };

        putData.Configurations.push({
            "Name": name,
            "Value": value
        });

        vm.updatePending = new createSpinner();

        $http.put(apiUrl + 'api/core/configuration', putData).then(function successCallback(response) {
            successfulSaveButton(vm.updatePending);
            //Close popover after the save
            $timeout(function () {
                vm.popoverIsOpen = false;
            }, 1500);
        }, function (response) {
            showErrorMessage(response.data);
            vm.updatePending.loadingValue = false;
        });
    }

    async function checkFavorite(favorited) {

        applicationInsightsService.trackEvent('CheckFavorite', { URL: $location.path(), Favorited: favorited });
        const currentUser = await authenticationService.getUser();

        if (favorited) {

            var postData = {
                "UserId": currentUser.userId,
                "Url": $location.path()
            };

            $http.post(apiUrl + 'api/core/favorites', postData).then(function successCallback(response) {
                $rootScope.$broadcast('handleFavoriteBroadcast');
                //Post Success
            }, function errorCallback(response) {
                //alert('post failed')
            });
        }
        else {

            var url = $location.path();

            $http({
                method: 'GET',
                url: apiUrl + 'api/core/favorites/url/',
                params: { url: url }

            }).then(function successCallback(response) {

                $http.delete(apiUrl + 'api/core/favorites/' + response.data.Id)
                    .then(function successCallback(response) {
                        $rootScope.$broadcast('handleFavoriteBroadcast');
                        //Delete Success
                    }, function errorCallback(response) {

                    });
            });
        }
    }

    function areYouSureParams(title, text, confirmButton, cancelButtonText) {
        var cancelButton = 'Cancel';
        if (cancelButtonText != null) {
            cancelButton = cancelButtonText;
        }
        return { title: title, text: text, type: 'warning', showCancelButton: true, confirmButtonText: confirmButton, cancelButtonText: cancelButton };
    }

    function areYouSureParamsWithHTML(title, html, confirmButton, cancelButtonText) {
        var cancelButton = 'Cancel';
        if (cancelButtonText != null) {
            cancelButton = cancelButtonText;
        }
        return { title: title, html: html, type: 'warning', showCancelButton: true, confirmButtonText: confirmButton, cancelButtonText: cancelButton };
    }

    function showErrorMessage(message) {
        if (message) {
            message = message.split('Validation failed:').pop();
            message = message.split('Invalid request:').pop();
        }

        swal({
            title: 'Error',
            text: message,
            type: 'warning'
        });

    }

    function showConfirmationMessage(title, text) {
        swal({ title: title, text: text, type: 'success' });
    }

    function showConfirmationMessageNoButtons(title, text) {
        swal({
            title: title,
            text: text,
            type: 'success',
            showCancelButton: false,//No cancel button
            showConfirmButton: false //No confirm button
        });
    }

    function navigateTo(path) {
        $location.path(path);
    }

    function refreshPage(path) {
        $window.location.href = MyApp.rootPath + path;
    }

    function goBack() {
        $window.history.back();
    }

    function clone(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    function camelCaseToSentence(string) {
        if (!angular.isString(string)) {
            return string;
        }
        var addSpace = string.replace(/([A-Z])/g, ' $1').trim();
        var removeSpaces = addSpace.replace('A X', 'AX').replace('S O D', 'SOD').replace('Net Suite', 'NetSuite').replace('S A P', 'SAP').replace('Dynamics365', 'Dynamics 365');
        return removeSpaces;
    }

    function splitHeaderText(grid, gridOptions) {
        var columns = angular.copy(grid.columns);

        columns.forEach(function (row, i) {
            row["title"] = headerStringTransformer(row.field);
        });

        gridOptions.columns = columns;

    }

    function headerStringTransformer(headerText) {
        headerText = headerText.replace('FP_', '');
        headerText = headerText.replace(/([a-z])([A-Z])/g, '$1 $2');
        headerText = headerText.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
        //Inserts space before numbers
        headerText = headerText.replace(/(\d+)/g, function (_, num) {
            return ' ' + num + ' ';
        });
        headerText = headerText.replace('Net Suite', 'NetSuite');
        headerText = headerText.replace('Audit Board', 'AuditBoard');
        return headerText;
    }

    function getBaseURL() {
        return appUrl;
    }

    function isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    function objectExists(securityObjectID) {

        var navigationObjectList = [];
        var exists = false;

        // localStorage.removeItem("UserSecurityObjects");

        if (localStorage.getItem("UserSecurityObjects") != null) {
            navigationObjectList = angular.fromJson(localStorage.getItem("UserSecurityObjects"));

            exists = !navigationObjectList.none(function (n) {
                return n.ObjectID == securityObjectID;
            });

        } else {
            exists = true;
        }

        return exists;
    }

    //IM functions
    function getApplicationName(applicationId, availableApplications) {
        var applicationName = '';
        if (availableApplications != null) {
            var application = availableApplications.filter(e => e.Id == applicationId)[0];

            if (application != null) {
                applicationName = application.Name;
            }
        }

        return applicationName;
    }

    function getProductName(productId, availableApplications) {
        var getProductName = '';
        if (availableApplications) {
            var product = availableApplications.filter(e => e.ProductID == productId)[0];

            if (product != null) {
                getProductName = product.ProductName;
            } else {
                return productId;
            }
        }

        return getProductName;
    }

    function getSecurityObjectDetailsFromObjectId(objectId) {
        if (objectId) {
            var UserSecurityObjects = localStorage.getItem('UserSecurityObjects');
            if (UserSecurityObjects) {
                UserSecurityObjects = JSON.parse(UserSecurityObjects);
                var securityObject = UserSecurityObjects.filter(e => e.ObjectID == objectId)[0];
                return securityObject;
            }
        } else {
            return "";
        }
    }

    function getSecurityObjectDetailsFromObjectIdAndProductType(objectId, productType) {
        if (objectId && productType) {
            var UserSecurityObjects = localStorage.getItem('UserSecurityObjects');
            if (UserSecurityObjects) {
                UserSecurityObjects = JSON.parse(UserSecurityObjects);
                if (productType === 'customProduct') {
                    productType = null;
                }
                var securityObject = UserSecurityObjects.filter(e => e.ObjectID == objectId && e.ProductType == productType)[0];
                return securityObject;
            }
        } else {
            return "";
        }
    }

    function getSecurityObjectDetailsFromReportId(reportId) {
        if (reportId) {
            var UserSecurityObjects = localStorage.getItem('UserSecurityObjects');
            if (UserSecurityObjects) {
                UserSecurityObjects = JSON.parse(UserSecurityObjects);
                var securityObject = UserSecurityObjects.filter(e => e.ReportID == reportId)[0];
                if (securityObject) {
                    return securityObject;
                } else {
                    // user doesn't have access to this so send them to 403 page
                    $location.path('/403');
                }
            }
        } else {
            return "";
        }
    }

    function getAccessName(access) {
        var accessObject = JSON.parse(access);

        return accessObject.Name;
    }

    function getUserName(user) {
        //Get the user name from the user JSON
        var userName = '';
        if (user != null) {
            if (user.Name || (user.ProductId === 'OR' && user.Name)) userName = user.Name;
            else if (user.UserName) userName = user.UserName;
            else if (user.DisplayName) userName = user.DisplayName;
            else if (user.Email) userName = user.Email;
            else if (user.ProductId === 'PS') userName = user.Id

            else {
                if (user.UserMaster != null) {
                    userName = user.UserMaster.Name;
                }
                if (user.FullName) {
                    userName = user.FullName;
                } else {
                    userName = user.FirstName + " " + user.LastName;
                }
            }
        }
        return userName;
    }

    function toUTF8Array(str) {
        var utf8 = [];
        for (var i = 0; i < str.length; i++) {
            var charcode = str.charCodeAt(i);
            if (charcode < 0x80) utf8.push(charcode);
            else if (charcode < 0x800) {
                utf8.push(0xc0 | (charcode >> 6),
                    0x80 | (charcode & 0x3f));
            }
            else if (charcode < 0xd800 || charcode >= 0xe000) {
                utf8.push(0xe0 | (charcode >> 12),
                    0x80 | ((charcode >> 6) & 0x3f),
                    0x80 | (charcode & 0x3f));
            }
            // surrogate pair
            else {
                i++;
                // UTF-16 encodes 0x10000-0x10FFFF by
                // subtracting 0x10000 and splitting the
                // 20 bits of 0x0-0xFFFFF into two halves
                charcode = 0x10000 + (((charcode & 0x3ff) << 10)
                    | (str.charCodeAt(i) & 0x3ff));
                utf8.push(0xf0 | (charcode >> 18),
                    0x80 | ((charcode >> 12) & 0x3f),
                    0x80 | ((charcode >> 6) & 0x3f),
                    0x80 | (charcode & 0x3f));
            }
        }
        return utf8;
    }

    function setPluginImageClass(pluginName) {
        const pluginImageMap = {
            'Active Directory': 'active-directory-avatar-logo',
            'Jira': 'jira-avatar-logo',
            'ServiceNow': 'service-now-avatar-logo',
            'Workiva': 'workiva-avatar-logo',
            'Zendesk': 'zendesk-avatar-logo',
            'Freshservice': 'freshservice-avatar-logo',
            'AuditBoard': 'auditboard-avatar-logo',
            'Azure Log Analytics': 'ala-avatar-logo'


        };
        if (pluginName) {
            return pluginImageMap[pluginName];
        }
    }
}