import createSpinner from "../../Shared/Functions/createSpinner";
import { customAutoCompleteFilter, customBooleanFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../Shared/kendo.module";
import editSystemRoleModalController from "./editSystemRoleModal/editSystemRoleModal.controller";
import importModalController from "./importModal/importModal.controller";

export default /*@ngInject*/ function ($filter, maintainSystemRolesDataService, dataService, helperService, $uibModal, $scope, reportsService, ownershipGroupsDataService, FileSaver) {

    const vm = this;
    vm.openEditSystemRoleModal = openEditSystemRoleModal;
    vm.openImportModal = openImportModal;
    vm.exportSystemRoles = exportSystemRoles;

    $scope.$on('ImportSecurityRoleProperties', (event, args) => vm.systemRolesGridDataSource.read());

    activate();

    async function activate() {
        vm.productsDataSource = await getProducts();
        vm.multipleProducts = checkForMultipleProducts();
        vm.hasSap = checkForSap();

        configureSystemRolesGrid();
    }

    async function getProducts() {
        const { data } = await dataService.getProducts();

        return $filter('orderBy')(data, 'Name');
    }

    function checkForMultipleProducts() {
        let hasMultiple = vm.productsDataSource.length > 1;

        if (hasMultiple) {
            vm.selectedProduct = vm.productsDataSource[0];
        }

        return hasMultiple;
    }

    async function getRoleOwnershipGroups() {
        const { data } = await ownershipGroupsDataService.getOwnershipGroups();

        vm.roleOwnershipGroupsLoading = true;
        vm.roleOwnershipGroupsDataSource = data;
        vm.eaApproversGroupId = Number(JSON.parse(localStorage.getItem("ConfigurationValues")).EmergencyAccessApproversGroup);

        if (vm.eaApproversGroupId && vm.roleOwnershipGroupsDataSource.length > 0) {
            vm.selectedOwnershipGroup = vm.roleOwnershipGroupsDataSource.find(group => group.Id === vm.eaApproversGroupId).Id;
        }

        vm.roleOwnershipGroupsLoading = false;
    }

    function checkForSap() {
        return vm.productsDataSource.some(product => product.Type === 'SAP');
    }

    async function configureSystemRolesGrid() {
        const eaColumn = { field: "IsEmergencyAccess", title: "Is Emergency Access", headerTemplate: '<div class="flex-between"><label for="is-emergency-access">Is Emergency Access &nbsp;</label><span class="badge badge-primary">{{msrvm.isEmergencyAccessRolesCount}}</span></div>', filterable: customBooleanFilter, width: 160 },
            systemRolesGridColumns = [
            { field: "Name", title: "Name", filterable: customAutoCompleteFilter, width: 120 },
            { field: "ID", title: "Id", filterable: customAutoCompleteFilter, width: 100 },
            { field: "Description", title: "Description", filterable: customAutoCompleteFilter },
            { field: "IsAssignable", title: "Is Assignable", headerTemplate: '<div class="flex-between"><label for="is-assignable">Is Assignable &nbsp;</label><span class="badge badge-primary">{{msrvm.isAssignableRolesCount}}</span></div>', filterable: customBooleanFilter, width: 120 },
            { field: "IsAutoApproved", title: "Is Auto Approved", headerTemplate: '<div class="flex-between"><label for="is-auto-approved">Is Auto Approved &nbsp;</label><span class="badge badge-primary">{{msrvm.isAutoApprovedRolesCount}}</span></div>', filterable: customBooleanFilter, width: 140 },
            { field: "IgnoreCertification", title: "Ignore Certification", headerTemplate: '<div class="flex-between"><label for="ignore-certification">Ignore Certification &nbsp;</label><span class="badge badge-primary">{{msrvm.ignoreCertificationRolesCount}}</span></div>', filterable: customBooleanFilter, width: 160  },
            { field: "Reviewed", title: "Is Reviewed", headerTemplate: '<div class="flex-between"><label for="is-reviewed">Is Reviewed &nbsp;</label><span class="badge badge-primary">{{msrvm.isReviewedRolesCount}}</span></div>', filterable: customBooleanFilter, width: 120 },
            { field: "ProductName", title: "Product", filterable: customAutoCompleteFilter, hidden: !vm.multipleProducts },
            { template: `<a ng-click="msrvm.openEditSystemRoleModal(dataItem)" ng-disabled="msrvm.disabled"><i ng-if="dataItem.Reviewed" class="fa fa-edit text-warning m-r-xs"></i><i ng-if="!dataItem.Reviewed" class="fa fa-file-o m-r-xs"></i>{{dataItem.Reviewed ? 'Edit' : 'Review'}}</a>`, "title": "Status", width: 100 },
        ];

        if (vm.hasSap) {
            systemRolesGridColumns.splice(7, 0, eaColumn);
        }

        vm.systemRolesGridOptions = helperService.setMainGridOptions(systemRolesGridColumns, 600);
        vm.systemRolesGridOptions.autoBind = false;

        vm.systemRolesGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: angular.extend(halSchemaKendoGrid, {
                model: {
                    fields: {
                        IsAssignable: { type: "boolean" },
                        IsAutoApproved: { type: "boolean" },
                        IgnoreCertification: { type: "boolean" },
                        IsEmergencyAccess: { type: "boolean" },
                        Reviewed: { type: "boolean" }
                    }
                }
            }),
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: async options => {
                    let query = helperService.prepareSortsAndFilters(options);
                    query = reportsService.fixFieldNamesForApi(query, "SecurityRole");
                    let params = { 'pagesize': options.data.pageSize, 'page': options.data.page };
                    try{
                        const { data } = await maintainSystemRolesDataService.getSystemRoles(query, params);
                        vm.isAssignableRolesCount = data._embedded.reportData.filter(role => role.IsAssignable).length;
                        vm.isAutoApprovedRolesCount = data._embedded.reportData.filter(role => role.IsAutoApproved).length;
                        vm.ignoreCertificationRolesCount = data._embedded.reportData.filter(role => role.IgnoreCertification).length
                        vm.isEmergencyAccessRolesCount = data._embedded.reportData.filter(role => role.IsEmergencyAccess).length
                        vm.isReviewedRolesCount = data._embedded.reportData.filter(role => role.Reviewed).length
                        options.success(data);
                    } catch (err) {
                        options.error(err);
                    }
                }
            }
        });

        await vm.systemRolesGridDataSource.read();
    }
    async function openEditSystemRoleModal(role) {

        const editSystemRoleModal = $uibModal.open({
            templateUrl: '/App/Components/maintainSystemRoles/editSystemRoleModal/editSystemRoleModal.html',
            controller: editSystemRoleModalController,
            controllerAs: 'esrmvm',
            backdrop: 'static',
            resolve: {
                role: () => role
            }
        });
        try {
            await editSystemRoleModal.result;
            vm.systemRolesGridDataSource.read();
        } catch {
            return;
        }
    }

    async function openImportModal() {
        $uibModal.open({
            templateUrl: '/App/Components/maintainSystemRoles/importModal/importModal.html',
            controller: importModalController,
            controllerAs: 'isrmvm',
            backdrop: 'static'
        });
    }

    async function exportSystemRoles() {
        vm.exportUpdatePending = new createSpinner();
        try {
            let { data } = await maintainSystemRolesDataService.exportSystemRoles();
            await helperService.successfulSaveButton(vm.exportUpdatePending);
            FileSaver.saveAs(data, 'SystemRoles.xlsx');
        } catch {
            vm.exportUpdatePending.loadingValue = false;
        }
    }
}