import createSpinner from "../../../Shared/Functions/createSpinner";
import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, helperService, userRestrictionsDataService, maintainSystemUsersDataService, $filter, user) {

    const vm = this;

    vm.user = user;
    vm.selectUser = selectUser;
    vm.createUserMap = createUserMap;
    vm.deleteUserMap = deleteUserMap;
    vm.dismiss = $uibModalInstance.close;

    configureGrid();

    function configureGrid() {

        let gridColumns = [
            { field: "UserLogonName", title: "Name", filterable: customAutoCompleteFilter },
            { field: "Email", title: "Email", filterable: customAutoCompleteFilter },
            { template: `<a ng-click="summvm.createUserMap(dataItem)"><i class="fa fa-plus"></i></a>`, title: "", width: 25, filterable: false }
        ];

        vm.gridOptions = helperService.setMainGridOptions(gridColumns, 300);

        vm.gridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    try {
                        let { data } = await userRestrictionsDataService.getFastpathUsers();
                        if (!user.FpUserName) {
                            vm.suggestion = data.find(u => u.Email === user.Email);
                        }
                        data = $filter('orderBy')(data, 'UserLogonName');
                        options.success(data);
                    } catch {
                        options.error();
                    }
                }
            }
        });
    }

    function selectUser(user) { vm.selectedUser = user; }

    async function createUserMap(dataItem) {
        kendo.ui.progress(vm.systemUsersGrid.element, true);
        vm.fastpathUser = dataItem;
        let map = {};

        map.FpUserId = vm.fastpathUser.UserID;
        map.UserMasterId = user.ID;

        try {
            await maintainSystemUsersDataService.createUserMap(map);
            kendo.ui.progress(vm.systemUsersGrid.element, false);
            // Bind to parent list item immediately to reflect modal change
            user.FpUserName = vm.fastpathUser.UserLogonName;
            user.FpUserId = vm.fastpathUser.UserID;
        } catch {
            kendo.ui.progress(vm.systemUsersGrid.element, false);
        }

    }

    async function deleteUserMap() {
        vm.deleting = new createSpinner();
        try {
            await maintainSystemUsersDataService.deleteUserMap(vm.user.FpUserId, vm.user.ID);
            vm.deleting.loadingValue = false;
            // Bind to parent list item immediately to reflect modal change
            user.FpUserName = null;
            user.FpUserId = null;
        } catch {
            vm.deleting.loadingValue = false;
        }

    }
}