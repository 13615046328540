import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, $filter, simulation, selectedCompositeRole, selectedRoles, helperService, sapSdSimRoleDataService, sapSdSimCompositeRoleDataService) {
    const vm = this;

    vm.close = close;
    vm.addRoleToCompositeRole = addRoleToCompositeRole;
    selectedRoles = [...selectedRoles];

    activate();

    function activate() {
        setupAvailableRolesGrid();
    }

    function setupAvailableRolesGrid() {
        let availableRolesGridColumns = [
            { title: "Name", field: "Name", filterable: customAutoCompleteFilter },
            { template: "<a ng-disabled='armvm.addingToCompositeRole' ng-click='armvm.addRoleToCompositeRole(dataItem)'><i class='fa fa-plus pull-right'></i></a>", title: " ", width: 20 }
        ];

        vm.availableRolesGridOptions = helperService.setMainGridOptions(availableRolesGridColumns, null);

        vm.availableRolesGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    let response = await sapSdSimRoleDataService.getSimulationRoles(simulation.Id);
                    response.data = response.data.filter(role => {
                        return !selectedRoles.some(selectedRole => selectedRole.Name === role.Name);
                    });
                    response.data = $filter('orderBy')(response.data, "Name");
                    options.success(response.data);
                }
            }
        });
    }

    async function addRoleToCompositeRole(role) {
        vm.addingToCompositeRole = true;
        let data = {
            SimulationId: simulation.Id,
            CompositeRoleId: selectedCompositeRole.Id,
            RoleName: role.Name,
        }
        try {
            await sapSdSimCompositeRoleDataService.addRoleToCompositeRole(data);
            let processedRole = JSON.parse(JSON.stringify(role));
            selectedRoles.push(processedRole);
            vm.availableRolesGridDataSource.read();
            vm.addingToCompositeRole = false;
        } catch {
            vm.addingToCompositeRole = false;
        }
    }

    function close() {
        $uibModalInstance.close();
    }
}