import swal from "sweetalert2";
import { standardGridPageSize, customAutoCompleteFilter, customDatePickerFilter } from "../../Shared/GlobalVariables/filterVariables";
import requestDetailsUserRisksMitigationModalController from "./UserRisksMitigationModal/requestDetailsUserRisksMitigationModal.controller";
import requestDetailsModalController from "./RequestDetailsModal/requestDetailsModal.controller";
import approveDenyModalController from "./ApproveDenyModal/approveDenyModal.controller";
import requestDelegationModalController from "./DelegationModal/requestDelegationModal.controller";
import createSpinner from "../../Shared/Functions/createSpinner";
import editEffectiveDatesModalController from "./EditEffectiveDatesModal/editEffectiveDatesModal.controller";
import cancellationNotesModalController from "./cancellationNotesModal/cancellationNotesModal.controller";
import editRequestExpirationModalController from "./EditRequestExpirationModal/editRequestExpirationModal.controller";
export default /*@ngInject*/ function ($scope, $rootScope, $uibModal, RequestManagementLoadDataService, emailNavigationService, openRequestsDataService, configurationDataService, $route, authenticationService, $filter, $http, $window, $location, helperService, FileSaver, controlLibraryDataService) {

    var vm = $scope.vm = {};
    vm.helperService = helperService;
    vm.requestDetails = null;
    vm.pageParams = $location.search();
    const tenantInlocalStorage = angular.fromJson(localStorage.getItem("selectedTenant"));
    vm.selectedItemsForAction = [];
    vm.allUserSelected = false;
    vm.allAccessSelected = false;
    vm.selectedUserItemsForAction = [];
    vm.selectedAccessItemsForAction = [];
    vm.attachments = [];
    vm.changesToSubmit = false;
    vm.emergencyAccessStatus = null;
    vm.selectedDetailView = 'Notes';
    vm.awaitingRequestProcessing = false;

    vm.requestRiskMitigation = requestRiskMitigation;
    vm.expandDetails = expandDetails;
    vm.openApproveDenyRequestModal = openApproveDenyRequestModal;
    vm.updateSelectedAccessItemsForAction = updateSelectedAccessItemsForAction;
    vm.updateSelectedUserItemsForAction = updateSelectedUserItemsForAction;
    vm.selectAllAccesses = selectAllAccesses;
    vm.selectAllUsers = selectAllUsers;
    vm.applyActionToSelectedItems = applyActionToSelectedItems;
    vm.collectTCodeUsage = collectTCodeUsage;
    vm.openEffectiveDateModal = openEffectiveDateModal;
    vm.openEditRequestExpirationModal = openEditRequestExpirationModal;
    vm.removeFile = removeFile;
    vm.downloadFile = downloadFile;
    vm.hideBackButton = false;

    async function activate() {
        vm.disableObjectLevelRiskAnalysis = await getDisableObjectLevelConfiguration();
        vm.currentUser = await authenticationService.getUser();
        checkForCancelOrEditAllRequests();
        redirectIfNeeded();
        defineColumnsAndReports();
    }

    activate();

    $scope.$on('$locationChangeStart', (e, newUrl) => {
        if (checkForUnsavedChanges() && !vm.awaitingRequestProcessing) {
            e.preventDefault();
            swal(helperService.areYouSureParams('Pending Changes', 'Any request items with pending approve or deny actions will not be saved until you have submitted. Do you still want to continue?', 'Yes')).then(_ => {
                $window.location.href = newUrl;
            }).catch(_ => {
                return;
            });
        }
    });

    $scope.$on('RequestReview', _ => {
        $route.reload();
    });

    async function getDisableObjectLevelConfiguration() {
        const disableObjectLevelRiskAnalysis = await configurationDataService.getConfigurationValueByName("IM_DisableObjectLevelRiskAnalysis");
        return disableObjectLevelRiskAnalysis.data === 1;
    }

    function checkForUnsavedChanges() {
        return vm.selectedAccessItemsForAction.length > 0 || vm.selectedUserItemsForAction.length > 0 || vm.req && vm.req.RequestAccesses.some(access => access.Action && access.Action !== 'Delegated') || vm.req && vm.req.RequestUsers.some(user => user.Action && user.Action !== 'Delegated');
    }

    // Attachments Logic

    async function removeFile(file) {
        vm.fileActionPending = true;

        try {
            await controlLibraryDataService.deleteAttachment(file.Identifier);

            const index = vm.attachments.findIndex(remainingFile => remainingFile.Identifier === file.Identifier);
            vm.attachments.splice(index, 1);
            vm.fileActionPending = false;
        } catch {
            vm.fileActionPending = false;
        }
    }

    async function downloadFile(file) {
        vm.fileActionPending = true;

        try {
            const response = await controlLibraryDataService.downloadAttachment(file.Identifier);
            FileSaver.saveAs(response.data, file.FileName);
            vm.fileActionPending = false;
        } catch {
            vm.fileActionPending = false;
        }
    }

    async function redirectIfNeeded() {
        try {
            if (vm.pageParams.tenantid) {
                vm.hideBackButton = true;

                if (vm.pageParams.tenantid === tenantInlocalStorage.ID) {
                    vm.sufficientSecurityAccess = true;
                    return;
                } else {
                    await findTenantFromEmail();
                }
            } else {
                vm.sufficientSecurityAccess = true;
                return;
            }
        } catch {
            return;
        }
    }

    async function findTenantFromEmail() {
        emailNavigationService.availableTenants(vm.currentUser.userId)
            .then(response => {

                let validTenant = response.data.find(tenant => {
                    return tenant.ID === vm.pageParams.tenantid;
                });

                if (validTenant) {
                    vm.redirectToTenant = true;
                    localStorage.setItem("selectedTenant", JSON.stringify(angular.copy(validTenant)));
                    $window.location.reload();
                } else {
                    vm.insufficientSecurityAccess = true;
                }

            });
    }

    var requestIdParam = null;
    if (vm.pageParams.requestid) {
        requestIdParam = vm.pageParams.requestid;
    }
    if (vm.pageParams.RequestId) {
        requestIdParam = vm.pageParams.RequestId;
    }

    var requestVersionParam = null;
    if (vm.pageParams.Version) {
        requestVersionParam = vm.pageParams.Version;
    }
    if (vm.pageParams.version) {
        requestVersionParam = vm.pageParams.version;
    }

    vm.reportDetailsComplete = false;

    async function createDataSources(data) {
        if (vm.emergencyAccessStatus) {
            const requestedResponse = await $http.get(`${apiUrl}api/identitymanager/requests/${vm.req.Id}/version/${vm.req.Version}/emergencyaccessobjects`);
            data.TCodeRequest = requestedResponse.data;
            const usageResponse = await $http.get(`${apiUrl}api/identitymanager/requests/${vm.req.Id}/version/${vm.req.Version}/emergencyaccess/usage`);
            data.TCodeUsage = usageResponse.data;
            vm.selectedDetailView = 'TCodes';
        }
        vm.ApprovalsRequiredDataSource = new kendo.data.DataSource({
            data: data.Reviewers
        });
        vm.ApprovalsSubmittedDataSource = new kendo.data.DataSource({
            data: data.Reviews
        });
        vm.ErrorLogDataSource = new kendo.data.DataSource({
            data: data.Errors
        });
        vm.TCodeRequestedDataSource = new kendo.data.DataSource({
            data: data.TCodeRequest
        });
        vm.TCodeUsageDataSource = new kendo.data.DataSource({
            data: data.TCodeUsage
        });

        if (data.RiskAnalysis) {

            vm.RisksDataSource = new kendo.data.DataSource({
                pageSize: standardGridPageSize,
                data: data.RiskAnalysis.UserRisks,
                schema: {
                    model: {
                        fields: {
                            RiskId: { type: "number" }
                        }
                    }
                },
                pageable: false
            });

            vm.BusinessProcessDataSource = new kendo.data.DataSource({
                pageSize: standardGridPageSize,
                data: data.RiskAnalysis.BusinessProcesses,
                group: [{ field: "BusinessProcessGroupName", dir: "asc" }, { field: "ObjectComparison", dir: "asc" }]
            });

        }

        vm.ucExistingTotal = 0;
        vm.ucNewTotal = 0;
        vm.ucRemovedTotal = 0;
        vm.ucTotal = 0;

        //Assign totals for summary grids
        if (data.RiskAnalysis) {
            //Assign totals for summary grids
            vm.riskResults = data.RiskAnalysis;

            vm.ucExistingTotal = RequestManagementLoadDataService.searchByKey(vm.riskResults.UserRiskAnalysis.CountByComparison, 'Existing');
            vm.ucNewTotal = RequestManagementLoadDataService.searchByKey(vm.riskResults.UserRiskAnalysis.CountByComparison, 'Added');
            vm.ucRemovedTotal = RequestManagementLoadDataService.searchByKey(vm.riskResults.UserRiskAnalysis.CountByComparison, 'Deleted');
            vm.ucTotal = vm.ucExistingTotal + vm.ucNewTotal;

            vm.bpGroupExistingTotal = RequestManagementLoadDataService.searchByKey(vm.riskResults.BusinessProcessAnalysis.GroupCountByComparison, 'Existing');
            vm.bpGroupNewTotal = RequestManagementLoadDataService.searchByKey(vm.riskResults.BusinessProcessAnalysis.GroupCountByComparison, 'Added');
            vm.bpGroupRemovedTotal = RequestManagementLoadDataService.searchByKey(vm.riskResults.BusinessProcessAnalysis.GroupCountByComparison, 'Deleted');
            vm.bpGroupTotal = vm.bpGroupExistingTotal + vm.bpGroupNewTotal;

            vm.bpObjectExistingTotal = RequestManagementLoadDataService.searchByKey(vm.riskResults.BusinessProcessAnalysis.ObjectCountByComparison, 'Existing');
            vm.bpObjectNewTotal = RequestManagementLoadDataService.searchByKey(vm.riskResults.BusinessProcessAnalysis.ObjectCountByComparison, 'Added');
            vm.bpObjectRemovedTotal = RequestManagementLoadDataService.searchByKey(vm.riskResults.BusinessProcessAnalysis.ObjectCountByComparison, 'Deleted');
            vm.bpObjectTotal = vm.bpObjectExistingTotal + vm.bpObjectNewTotal;
        }

        vm.notesList = data.Notes;
    }

    async function collectTCodeUsage() {
        vm.collecting = new createSpinner();
        let postBody = {
            RequestId: vm.req.Id,
            Version: vm.req.Version
        };
        try {
            await $http.post(`${apiUrl}api/identitymanager/requests/${vm.req.Id}/version/${vm.req.Version}/emergencyaccess/usage/collect`, postBody);
            await helperService.successfulSaveButton(vm.collecting);
            let response = await $http.get(`${apiUrl}api/identitymanager/requests/${vm.req.Id}/version/${vm.req.Version}/emergencyaccess/usage`);
            vm.TCodeUsageDataSource = response.data;
            await vm.TCodeUsageDataSource.read();
        } catch {
            vm.collecting.loadingValue = false;
        }
    }

    async function checkForCancelOrEditAllRequests() {
        vm.cancelAllRequests = await openRequestsDataService.checkForCancelOrEditAllRequests("Cancel Other User's Requests");
        vm.editAllRequests = await openRequestsDataService.checkForCancelOrEditAllRequests('Edit All Requests');
    }

    vm.editRequest = function () {
        $rootScope.requestToEdit = { Id: vm.req.Id, Version: vm.req.Version };
        $location.path('/IdentityManager/Place/RequestManagement/' + vm.req.Action).search({});
    };

    vm.cancelRequest = function () {
        swal(helperService.areYouSureParams('Cancel Request', 'Are you sure you want to cancel this request?', 'Yes', 'No')).then(async function () {
            var putData = {
                "RequestId": vm.req.Id,
                "Version": vm.req.Version
            };

            const requireNotes = await helperService.getConfigurationValue('IM_RequireNoteOnCancelledRequests') === '1';

            if (requireNotes) {
                openCancellationNotesModal(putData);
            } else {
                $http.put(`${apiUrl}api/identitymanager/requests/${vm.req.Id}/version/${vm.req.Version}/cancel`, putData).then(function (response) {
                    $route.reload();
                }, function (response) {
                    helperService.showErrorMessage(response.data);
                });
            }
        }, function (dismiss) {
            return;
        });
    };

    async function openCancellationNotesModal(requestData) {
        let cancelNotesModal = $uibModal.open({
            templateUrl: '/App/Components/IdentityManagerRequestDetails/cancellationNotesModal/cancellationNotesModal.html',
            controller: cancellationNotesModalController,
            controllerAs: 'cnvm',
            backdrop: 'static',
            resolve: {
                requestData: () => requestData
            }
        });

        try {
            await cancelNotesModal.result;
            $route.reload();
        } catch {
            return;
        }
    }

    vm.endEmergencyAccessUsage = function () {
        swal(helperService.areYouSureParams('End Emergency Access', 'Are you sure you want to end emergency access for the roles in this request?', 'Continue')).then(function () {
            var putData = {
                "RequestId": vm.req.Id,
                "Version": vm.req.Version
            };
            $http.delete(apiUrl + `api/identitymanager/requests/${vm.req.Id}/version/${vm.req.Version}/emergencyaccess/end`).then(function (response) {
                $route.reload();
            }, function (response) {
                helperService.showErrorMessage(response.data);
            });
        }, function (dismiss) {
            return;
        });
    };

    vm.reprocessRequest = function () {
        var putData = {
            "RequestId": vm.req.Id,
            "Version": vm.req.Version
        };

        $http.put(apiUrl + 'api/identitymanager/requests/' + vm.req.Id + '/version/' + vm.req.Version + '/reprocess', putData).then(function (response) {
            helperService.showConfirmationMessage("Success", "Request is being reprocessed");
            $route.reload();
        }, function (response) {
            helperService.showErrorMessage(response.data);
        });
    };

    vm.exportReport = function () {
        var postData = {
            "RequestId": vm.req.Id,
            "Version": vm.req.Version
        };

        $http.post(apiUrl + 'api/core/exports/imrequest', postData).then(function successCallback(response) {
            var fullExportName;
            fullExportName = 'IdentityManagementRequestDetails' + '.pdf';

            response.data = { ExportId: response.data, ExportName: fullExportName };

        }, function errorCallback(response) {
            helperService.showErrorMessage(response.data);
        });
    };

    $http.get(apiUrl + 'api/identitymanager/requests/' + requestIdParam + '/version/' + requestVersionParam).then(async function (response) {

        var data = response.data;
        vm.linkedTickets = response.data.Tickets;

        if (data.RiskAnalysis && data.RiskAnalysis.UserRisks.length > 0) {
            data.RiskAnalysis.UserRisks = $filter('orderBy')(data.RiskAnalysis.UserRisks, 'Comparison');
            vm.userRisksData = data.RiskAnalysis.UserRisks;
        }

        if (vm.userRisksData && data.CanIReview) {
            data.RiskAnalysis.UserRisks.forEach(risk => {
                if (risk.RequestMitigationIdentifier) {
                    risk.RequestMitigation = 'Edit';
                } else {
                    risk.RequestMitigation = 'Request';
                }

                vm.requestAllowed = true;
            });
        } else if (vm.userRisksData && data.RiskAnalysis.UserRisks.length > 0) {
            data.RiskAnalysis.UserRisks.forEach(risk => {
                if (risk.RequestMitigationIdentifier) {
                    risk.RequestMitigation = 'Requested Mitigation';
                } else {
                    risk.RequestMitigation = 'N/A';
                }
                vm.requestAllowed = false;
            });
        }

        if (data.GeneralUser) {
            data.AffectedObject = data.GeneralUser;
        }

        data.RequestAccesses.forEach(function (row, i) {
            if (row.Access) {
                row.Access = row.Access;
            }
        });

        data.RequestUsers.forEach(function (row, i) {
            if (row.User) {
                row.User = row.User;
            }
        });

        vm.req = data;
        vm.requestDetails = data;

        vm.terminations = data.TerminatedUserRoles.reduce((accessAccumulator, currentRole) => {
            const role = {
                Product: currentRole.ProductName,
                AccessType: currentRole.TypeID,
                Access: currentRole.Name
            };
            if (currentRole.Companies.length) {
                $scope.vm.hasCompany = true;
                let roleCompanies = [];
                currentRole.Companies.forEach(company => {
                    roleCompanies.push({ ...role, Company: company.Name });
                })
                return accessAccumulator.concat(roleCompanies);
            }
            else {
                accessAccumulator.push(role);
                return accessAccumulator;
            }
        }, []);

        vm.accessItems = data.RequestAccesses;

        vm.canReviewAccessRequest = vm.accessItems.some(access => access.CanIReview);
        vm.canReviewUserRequest = data.RequestUsers.some(user => user.CanIReview);

        vm.emptyStartEndDates = vm.accessItems.every(access => access.StartDate === null && access.EndDate === null);

        // Check all Access Statuses to determine if 'Edit Effective Dates' button and column should be hidden
        vm.checkEveryAccessStatus = vm.accessItems.every(access => access.Status === 'Pending' || access.Status === 'Approved' || access.Status === 'AwaitingEffectiveDateProcessing');

        // Check to see if any Access Request Statuses are Pending, Approved, or Awaiting and disable 'Edit Effective Dates' button and column accordingly
        vm.checkSomeAccessStatuses = vm.accessItems.some(access => access.Status === 'Pending' || access.Status === 'Approved' || access.Status === 'AwaitingEffectiveDateProcessing');

        // Check to see if Start Date or End Date is null or in the past
        vm.checkEveryStartDate = vm.accessItems.every(access => (access.StartDate === null || (new Date(access.StartDate) < new Date())));
        vm.checkEveryEndDate = vm.accessItems.every(access => (access.EndDate === null || (new Date(access.EndDate) < new Date())));

        // Consolidate Access and Date Checks
        vm.showRequests = (vm.checkEveryAccessStatus || vm.checkSomeAccessStatuses) && (!vm.checkEveryStartDate || !vm.checkEveryEndDate) && (vm.currentUser.userId === vm.req.Requestor);


        checkEffectiveDates();
        vm.attachments = vm.req.Attachments;
        vm.req.hasObjectId = vm.req.AffectedObject.ID === '00000000-0000-0000-0000-000000000000';
        await checkForEmergencyAccessStatus(data.RequestAccesses);
        createDataSources(data);
        updateActionTotals();
        vm.reportDetailsComplete = true;
    }, function errorCallback(response) {
        vm.reportDetailsComplete = true;
    });

    function checkEffectiveDates() {
        const currentDate = new Date();

        vm.accessItems.forEach(access => {
            const startDate = new Date(access.StartDate);
            const endDate = new Date(access.EndDate);
            access.validStartDate = false;
            access.validEndDate = false;

            if (access.StartDate !== null && (startDate >= currentDate)) {
                access.validStartDate = true;
            } else {
                access.validStartDate = false;
            }

            if (access.EndDate !== null && (endDate >= currentDate)) {
                access.validEndDate = true;
            } else {
                access.validEndDate = false;
            }

            if ((access.EndDate !== null && (endDate >= currentDate)) && (access.StartDate === null || (startDate < currentDate))) {
                access.validEndDate = true;
            }

        });

        vm.availableEffectiveDatesAccessItems = vm.accessItems.filter(access => (access.validEndDate || access.validStartDate) && (access.Status === 'Pending' || access.Status === 'Approved' || access.Status === 'AwaitingEffectiveDateProcessing'));
    }

    //Copy the Request ID to the clipboard
    vm.copyToClipboard = function () {
        try {
            const el = document.createElement('input');
            el.value = vm.req.Id;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        }
        catch (ex) {
            swal({ title: 'Error copying to clipboard', type: 'warning', showCancelButton: false });
        }
    };

    function defineColumnsAndReports() {
        var ApprovalsRequiredColumns = [
            { field: "Type", filterable: customAutoCompleteFilter },
            { field: "Name", filterable: customAutoCompleteFilter },
            { field: "Level", filterable: customAutoCompleteFilter },
            { field: "Quantity", filterable: customAutoCompleteFilter }
        ];

        var ApprovalsSubmittedColumns = [
            { field: "Reviewer", title: "Approver", filterable: customAutoCompleteFilter },
            { field: "ReviewedOn", template: "{{ dataItem.ReviewedOn | date:'short'}}", title: "Approval Date", filterable: customDatePickerFilter },
            { field: "Version", filterable: customAutoCompleteFilter }
        ];

        var ErrorLogColumns = [
            { field: "Detail", filterable: customAutoCompleteFilter },
            { field: "TimeStamp", template: "{{ dataItem.TimeStamp | date:'short'}}", title: "Time", filterable: customDatePickerFilter }
        ];

        var RisksColumns = [
            { field: "Comparison", width: 100, template: '<span set-style="#:Comparison == \'Added\'? \'bafcd4\': Comparison == \'Deleted\'? \'ffd5d6\': Comparison == \'Existing\'? \'FCF8E3\': null#">{{dataItem.Comparison}}</span>' },
            { field: "RequestMitigation", title: "Request Mitigation", template: "<span ng-if='!vm.requestAllowed'>{{dataItem.RequestMitigation}}</span><a ng-if='vm.requestAllowed' ng-click='vm.requestRiskMitigation(dataItem)'>{{dataItem.RequestMitigation}}</a>", width: 180, filterable: customAutoCompleteFilter },
            { field: "CompanyName", title: "Company", width: 190, filterable: customAutoCompleteFilter },
            { field: "RiskId", title: "Risk ID", width: 120, filterable: customAutoCompleteFilter, hidden: true },
            { field: "RiskName", title: "Risk Name", width: 190, filterable: customAutoCompleteFilter, groupHeaderTemplate: "#=  value #" },
            { field: "RiskTypeName", title: "Risk Type", width: 190, filterable: customAutoCompleteFilter },
            { field: "BusinessCycle", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter },
            { field: "RiskLevel", title: "Risk Level", width: 110, filterable: customAutoCompleteFilter },
            { field: "Policy", title: "Policy", width: 110, filterable: customAutoCompleteFilter },
            { field: "RiskDescription", title: "Risk Description", width: 190, filterable: customAutoCompleteFilter },
            { field: "Mitigation", title: "Current Mitigation", width: 190, filterable: customAutoCompleteFilter },
            { field: "MitigationStatus", title: "Current Mitigation Status", width: 130, filterable: customAutoCompleteFilter },
            { field: "RequestMitigationStatusName", title: "Request Mitigation Status", width: 130, filterable: customAutoCompleteFilter },
            { field: "MitigationNotes", title: "Current Mitigation Notes", width: 190, filterable: customAutoCompleteFilter },
            { field: "RequestMitigationNotes", title: "Request Mitigation Notes", width: 190, filterable: customAutoCompleteFilter },
            { field: "MitigationControls", title: "Current Mitigation Controls", width: 190, filterable: customAutoCompleteFilter },
            { field: "RequestMitigationControlNames", title: "Request Mitigation Controls", width: 190, filterable: customAutoCompleteFilter },
            { field: "ProductID", title: "ProductID", width: 150, filterable: customAutoCompleteFilter, hidden: true },
            { field: "SecurityRoleID", title: "Security Role ID", width: 150, filterable: customAutoCompleteFilter, hidden: true },
            { field: "SecurityObjectTypeID", title: "Security ObjectType ID", width: 150, filterable: customAutoCompleteFilter, hidden: true },
            { field: "SecurityObjectID", title: "Security Object ID", width: 150, filterable: customAutoCompleteFilter, hidden: true },
            { field: "AccessTypeID", title: "Access Type ID", width: 150, filterable: customAutoCompleteFilter, hidden: true }
        ];

        const TCodeRequestColumns = [
            { field: "SecurityObjectName", title: "TCode", filterable: false },
            { field: "SecurityObjectAltName", title: "Description", filterable: false }];

        const TCodeUseColumns = [
            { field: "SecurityObjectId", title: "TCode", filterable: false },
            { field: "AccessDate", title: "Access Date", filterable: false }];

        var columnsFromVMBusinessProcess = [
            { field: "CompanyName", title: "Company", width: 190, filterable: customAutoCompleteFilter },
            { field: "ProductName", title: "Product", width: 150, filterable: customAutoCompleteFilter },
            { field: "SecurityObjectAltName", title: "Security Object", width: 180, filterable: customAutoCompleteFilter, hidden: vm.disableObjectLevelRiskAnalysis },
            { field: "SecurityObjectTypeName", title: "Security Object Type", width: 180, filterable: customAutoCompleteFilter, hidden: vm.disableObjectLevelRiskAnalysis },
            { field: "ObjectComparison", width: 125, groupHeaderTemplate: "#= value #", template: '<span set-style="#:ObjectComparison == \'Added\'? \'bafcd4\': ObjectComparison == \'Deleted\'? \'ffd5d6\': ObjectComparison == \'Existing\'? \'FCF8E3\': null#">{{dataItem.ObjectComparison}}</span>', hidden: vm.disableObjectLevelRiskAnalysis },
            { field: "AccessLevelName", title: "Access Level", width: 150, filterable: customAutoCompleteFilter, hidden: vm.disableObjectLevelRiskAnalysis },
            { field: "AccessTypeName", title: "Access Type", width: 150, filterable: customAutoCompleteFilter, hidden: vm.disableObjectLevelRiskAnalysis },
            { field: "BusinessProcessGroupName", title: "Group", width: 180, filterable: customAutoCompleteFilter, groupHeaderTemplate: "#= value #" },
            { field: "GroupComparison", width: 125, groupHeaderTemplate: "#= value #", template: '<span set-style="#:GroupComparison == \'Added\'? \'bafcd4\': GroupComparison == \'Deleted\'? \'ffd5d6\': GroupComparison == \'Existing\'? \'FCF8E3\': null#">{{dataItem.GroupComparison}}</span>' }
        ];

        vm.TCodeRequestedOptions = helperService.setNonPageableGridOptions(TCodeRequestColumns);
        vm.TCodeUsageOptions = helperService.setNonPageableGridOptions(TCodeUseColumns);
        vm.TCodeUsageOptions.noRecords = {
            template: "<div class='no-data-to-display-message'>No TCode Usage Was Recorded</div>"
        };

        vm.ApprovalsRequiredOptions = helperService.setNonPageableGridOptions(ApprovalsRequiredColumns);
        vm.ApprovalsRequiredOptions.dataBound = function (e) {
            let items = e.sender.items();
            items.each(function () {
                var row = $(this);
                var dataItem = e.sender.dataItem(row);
                // We only want to show the detail template if necessary
                if (dataItem.GroupUsers.length === 0) {
                    row.find(".k-hierarchy-cell").html("");
                }
            });
        };
        vm.ApprovalsSubmittedOptions = helperService.setNonPageableGridOptions(ApprovalsSubmittedColumns);
        vm.ApprovalsSubmittedOptions.detailTemplate = kendo.template($("#approvalsSubmittedTemplate").html());

        vm.ErrorLogOptions = helperService.setNonPageableGridOptions(ErrorLogColumns);

        vm.RisksOptions = helperService.setMainGridOptions(RisksColumns, null);
        if (!vm.disableObjectLevelRiskAnalysis) {
            vm.RisksOptions.detailTemplate = kendo.template($("#risksTemplate").html());
        }
        vm.RisksOptions.selectable = false;
        vm.RisksOptions.filterable = {
            mode: "row",
            operators: {
                string: {
                    contains: "Contains",
                    doesnotcontain: "Does not contain",
                    eq: "Equal to",
                    neq: "Not equal to"
                }
            }
        };
        vm.RisksOptions.columnMenu = true;

        vm.BusinessProcessOptions = helperService.setMainGridOptions(columnsFromVMBusinessProcess, null);
        vm.BusinessProcessOptions.selectable = false;

        vm.BusinessProcessOptions.filterable = {
            mode: "row",
            operators: {
                string: {
                    contains: "Contains",
                    doesnotcontain: "Does not contain",
                    eq: "Equal to",
                    neq: "Not equal to"
                }
            }
        };
    }

    function openRequestMitigationModal(dataItem, dataGrid) {
        $uibModal.open({
            templateUrl: '/App/Components/IdentityManagerRequestDetails/UserRisksMitigationModal/requestDetailsUserRisksMitigationModal.html',
            controller: requestDetailsUserRisksMitigationModalController,
            backdrop: 'static',
            size: 'lg',
            scope: $scope,
            resolve: {
                row: () => dataItem,
                grid: () => dataGrid,
                request: () => vm.req
            }
        });
    }

    function requestRiskMitigation(dataItem) {
        $scope.riskToMitigate = angular.copy(dataItem);
        openRequestMitigationModal(dataItem, vm.userRisksData);
    }

    function openExpandDetailsModal(dataGrid, scope) {

        $uibModal.open({
            templateUrl: '/App/Shared/Partials/RequestDetailsModal.html',
            controller: requestDetailsModalController,
            controllerAs: 'vm',
            backdrop: 'static',
            scope: $scope,
            resolve: {
                mainGrid: () => dataGrid,
                data: () => scope,
                disableObjectData: () => vm.disableObjectLevelRiskAnalysis
            }
        });
    }

    function expandDetails() {
        openExpandDetailsModal(vm.userRisksData, vm);
    }

    async function openApproveDenyRequestModal() {
        let approveDenyRequestModal = $uibModal.open({
            templateUrl: '/App/Components/IdentityManagerRequestDetails/ApproveDenyModal/ApproveDenyModal.html',
            controller: approveDenyModalController,
            controllerAs: 'vm',
            backdrop: 'static',
            scope: $scope,
            resolve: {
                request: () => vm.req
            }
        });

        try {
            await approveDenyRequestModal.result;
            // we are awaiting request processing so we would want to update the page as such
            vm.awaitingRequestProcessing = true;
        } catch {
            return;
        }
    }

    function updateSelectedAccessItemsForAction() {
        if (vm.currentUser.userId === vm.req.Requestor) {
            // user is requestor - we are working with effective date selections
            vm.allAccessSelected = vm.availableEffectiveDatesAccessItems.every(access => access.selectedForAction);
            vm.selectedAccessItemsForAction = vm.availableEffectiveDatesAccessItems.filter(access => access.selectedForAction);
        } else {
            let reviewableAccessItems = vm.req.RequestAccesses.filter(access => access.CanIReview);
            vm.allAccessSelected = reviewableAccessItems.every(access => access.selectedForAction);
            vm.selectedAccessItemsForAction = vm.req.RequestAccesses.filter(access => access.selectedForAction && access.CanIReview);
        }
    }

    function updateSelectedUserItemsForAction() {
        vm.allUserSelected = vm.req.RequestUsers.every(user => user.selectedForAction);
        vm.selectedUserItemsForAction = vm.req.RequestUsers.filter(user => user.selectedForAction && user.CanIReview);
    }

    function selectAllAccesses() {
        let selectionStatus = vm.allAccessSelected;
        if (vm.currentUser.userId === vm.req.Requestor) {
            // user is requestor so we need to set all effective dates available to match the select all
            vm.availableEffectiveDatesAccessItems.forEach(access => access.selectedForAction = selectionStatus);
        } else {
            // user is reviewer so we need to set accesses they can review to match the select all
            vm.req.RequestAccesses.forEach(access => {
                if (access.CanIReview) {
                    access.selectedForAction = selectionStatus;
                }
            });
        }
        updateSelectedAccessItemsForAction();
    }

    function selectAllUsers() {
        let selectionStatus = vm.allUserSelected;
        vm.req.RequestUsers.forEach(user => user.selectedForAction = selectionStatus);
        updateSelectedUserItemsForAction();
    }

    async function openEffectiveDateModal() {
        const selectedAccessItemsForAction = vm.selectedAccessItemsForAction.map(access => {
            let newAccess = { ...access };
            if (newAccess.StartDate !== null) {
                newAccess.originalStartDate = new Date(newAccess.StartDate);
            } else {
                newAccess.originalStartDate = null;
            }

            if (newAccess.EndDate !== null) {
                newAccess.originalEndDate = new Date(newAccess.EndDate);
            } else {
                newAccess.originalEndDate = null;
            }

            return newAccess;
        });
        let modal = $uibModal.open({
            templateUrl: '/App/Components/IdentityManagerRequestDetails/EditEffectiveDatesModal/editEffectiveDatesModal.tmpl.html',
            controller: editEffectiveDatesModalController,
            controllerAs: 'eedmvm',
            backdrop: 'static',
            resolve: {
                selectedAccessItemsForAction: () => selectedAccessItemsForAction,
                request: () => vm.req
            }
        });

        try {
            await modal.result;
            $route.reload();
        } catch {
            return;
        }
    }

    async function openEditRequestExpirationModal() {
        let modal = $uibModal.open({
            templateUrl: '/App/Components/IdentityManagerRequestDetails/EditRequestExpirationModal/editRequestExpirationModal.tmpl.html',
            controller: editRequestExpirationModalController,
            controllerAs: 'eremvm',
            backdrop: 'static',
            resolve: {
                request: () => vm.req
            }
        });

        try {
            await modal.result;
            $route.reload();
        } catch {
            return;
        }
    }

    async function applyActionToSelectedItems(action, requestType) {
        let items = [];
        if (requestType === 'User') {
            items = vm.selectedUserItemsForAction;
        } else {
            items = vm.selectedAccessItemsForAction;
        }
        if (action === 'Delegate') {
            let delegateModal = $uibModal.open({
                templateUrl: '/App/Components/IdentityManagerRequestDetails/DelegationModal/requestDelegationModal.html',
                controller: requestDelegationModalController,
                controllerAs: 'rdmvm',
                backdrop: 'static',
                resolve: {
                    request: () => vm.req,
                    delegationItems: () => items,
                    requestType: () => requestType
                }
            });

            try {
                let items = await delegateModal.result;
                setActionsToDelegated(items);
            } catch (_) {
                return;
            }
        } else {
            items.forEach(item => {
                item.Action = action;
                item.selectedForAction = false;
            });
            vm.changesToSubmit = true;
        }
        clearSelectedRequestType(requestType);

        updateActionTotals();
    }

    function updateActionTotals() {
        vm.approvedRequestItems = vm.req.RequestUsers.filter(item => item.Action === 'Approve').length + vm.req.RequestAccesses.filter(item => item.Action === 'Approve').length;
        vm.deniedRequestItems = vm.req.RequestUsers.filter(item => item.Action === 'Deny').length + vm.req.RequestAccesses.filter(item => item.Action === 'Deny').length;
    }


    function clearSelectedRequestType(requestType) {
        switch (requestType) {
            case 'Access':
                vm.allAccessSelected = false;
                vm.selectedAccessItemsForAction = [];
                if (!vm.req.RequestAccesses.some(access => access.Action === 'Deny' || access.Action === 'Approve')) {
                    vm.changesToSubmit = false;
                }
                break;
            case 'User':
                vm.allUserSelected = false;
                vm.selectedUserItemsForAction = [];
                if (!vm.req.RequestUsers.some(user => user.Action === 'Deny' || user.Action === 'Approve')) {
                    vm.changesToSubmit = false;
                }
                break;
        }
    }

    function setActionsToDelegated(requestItems) {
        requestItems.forEach(item => {
            item.Action = 'Delegated';
            item.CanIReview = false;
            item.selectedForAction = false;
        });

    }

    function checkForEmergencyAccessStatus(requestAccessItems) {
        vm.emergencyAccessItem = requestAccessItems.find(accessItem => accessItem.Status.includes('EmergencyAccess'));
        if (vm.emergencyAccessItem) {
            vm.emergencyAccessStatus = vm.emergencyAccessItem.Status;
        }
    }

}
