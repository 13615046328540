import { customAutoCompleteFilter, standardGridPageSize, customBooleanFilter } from "../../../../Shared/GlobalVariables/filterVariables";
import migrationDetailsEditModalController from "./migrationDetailsEditModal.controller";

export default /*@ngInject*/ function (securityMigrationDataService, helperService, $uibModal) {
    const vm = this;

    vm.$onInit = activate;
    vm.$onChanges = updateGridDataSource;
    vm.openMigrationDetailsEditModal = openMigrationDetailsEditModal;
    vm.changeEnabledValue = changeEnabledValue;
    vm.changeAllEnabled = changeAllEnabled;
    vm.allSelected = false;

    function activate() {
        setupMigrationDetailsGrid();
    }

    function setupMigrationDetailsGrid() {
        let migrationDetailsGridColumns =
            [
                { field: "Enabled", title: "Enabled", template: `<label class='switch'><input type='checkbox' ng-disabled="mdgvm.loadingDetails || mdgvm.allEnabledLoading" ng-model='dataItem.Enabled' ng-change='mdgvm.changeEnabledValue(dataItem)'><i></i></label>`, width: 65, filterable: false },
                { field: "Id", title: "Id", filterable: customAutoCompleteFilter, hidden: true },
                { field: "HeaderId", title: "Header Id", filterable: customAutoCompleteFilter, hidden: true },
                { field: "Name", title: "Name", filterable: customAutoCompleteFilter },
                { field: "Label", title: "Label", filterable: customAutoCompleteFilter },
                { field: "Description", title: "Description", filterable: customAutoCompleteFilter },
                { field: "Type", title: "Type", filterable: customAutoCompleteFilter },
                { template: `<a ng-click="mdgvm.openMigrationDetailsEditModal(dataItem)"><i class="fa fa-edit text-warning m-r-xs"></i>Edit</a>`, "title": "Edit", width: 90 }
            ];

        vm.migrationDetailsGridOptions = helperService.setMainGridOptions(migrationDetailsGridColumns, 612);
        vm.migrationDetailsGridOptions.selectable = false;

        vm.migrationDetailsGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    vm.loadingDetails = true;
                    let response = await securityMigrationDataService.getSecurityMigrationDetails(vm.migration.Id);
                    options.success(response.data);
                    vm.allEnabled = checkEnabledValues(response.data);
                    vm.loadingDetails = false;
                }
            }
        });
    }

    function updateGridDataSource() {
        if (vm.migrationDetailsGridDataSource) {
            vm.migrationDetailsGridDataSource.read();
        }
    }

    async function openMigrationDetailsEditModal(migrationDetailsRow) {
        const migrationModal = $uibModal.open({
            templateUrl: '/App/Components/AXSecurityDesigner/SecurityMigration/MigrationDetails/migrationDetailsEditModal.html',
            controller: migrationDetailsEditModalController,
            controllerAs: 'mdemvm',
            backdrop: 'static',
            resolve: {
                migrationDetail: () => migrationDetailsRow,
                migrationHeaderId: () => vm.migration.Id
            }
        });

        try {
            await migrationModal.result;
            await vm.migrationDetailsGridDataSource.read();
        } catch {
            return;
        }
    }

    function checkEnabledValues(data) {
        if (data.every(item => item.Enabled)) {
            return true;
        } else {
            return false;
        }
    }

    async function changeAllEnabled() {
        vm.allEnabledLoading = true;
        const data = {
            HeaderId: vm.migration.Id,
            Enabled: vm.allEnabled
        };
        try {
            await securityMigrationDataService.updateSecurityMigrationDetailEnabled(data);
            vm.migrationDetailsGridDataSource.read();
        } catch {
            return;
        }
        vm.allEnabledLoading = false;
    }

    async function changeEnabledValue(detailItem) {
        vm.loadingDetails = true;
        const data = {
            Id: detailItem.Id,
            Enabled: detailItem.Enabled,
            Name: detailItem.Name,
            Label: detailItem.Label,
            Description: detailItem.Description
        };

        try {
            await securityMigrationDataService.updateSecurityMigrationDetails(vm.migration.Id, data);
            updateGridDataSource();
        } catch {
            return;
        }
        vm.loadingDetails = false;
    }
}