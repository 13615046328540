import { customAutoCompleteFilter, customBooleanFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";
import editControlModalController from "./EditControlModal/editControlModal.controller";
import globalConditionsModalController from "./GlobalConditionsModal/globalConditionsModal.controller";

export default /*@ngInject*/ function ($scope, $uibModal, helperService, controlManagementDataService, $filter) {

    const vm = this;

    vm.shouldBeOpen = true;

    vm.openEditControlModal = openEditControlModal;
    vm.openGlobalConditionsModal = openGlobalConditionsModal;
    vm.runNow = runNow;

    async function activate() {
        let controlManagementGridColumns =
            [
                { template: `<a ng-click="cmvm.openEditControlModal(dataItem)"><i class="fa fa-edit text-warning m-r-xs"></i>Edit</a>`, "title": "Edit", width: 65 },
                { template: `<a ng-click="cmvm.runNow(dataItem)" ng-disabled="cmvm.disableButton">Run Now</a>`, "title": "Run Now", width: 80 },
                { field: "Id", title: "Id", filterable: customAutoCompleteFilter, width: 55 },
                { field: "Name", title: "Name", filterable: customAutoCompleteFilter, width: 350 },           
                { field: "Description", title: "Description", filterable: customAutoCompleteFilter },
                { field: "ReferenceNumber", title: "Reference Number", filterable: customAutoCompleteFilter, width: 125 },
                { field: "Notes", title: "Notes", filterable: customAutoCompleteFilter },
                { field: "AutoClose", title: "Auto Close", filterable: customBooleanFilter, width: 125 },
                { field: "Enabled", title: "Enabled", filterable: customBooleanFilter, width: 125 },           
                { template: `<span>{{dataItem.Schedule.LastExecutionTime | date:'short'}}</span>`, "title": "Last Ran", width: 130 },
                { template: `<span>{{dataItem.Schedule.NextExecutionTime | date:'short'}}</span>`, "title": "Scheduled Next", width: 130 }
            ];

        vm.controlManagementGridOptions = helperService.setMainGridOptions(controlManagementGridColumns, 600);
        vm.controlManagementGridOptions.selectable = false;

        vm.controlManagementGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: {
                model: {
                    fields: {
                        AutoClose: { type: "boolean" },
                        Enabled: { type: "boolean" }
                    }
                }
            },
            transport: {
                read: async function (options) {
                    try {
                        let response = await controlManagementDataService.getListOfControls();
                        response.data = $filter('orderBy')(response.data, 'Name');
                        options.success(response.data);
                    } catch {
                        options.error();
                    }
                }
            }
        });
    }

    activate();

    async function openEditControlModal(control) {

        let openModal = $uibModal.open({
            templateUrl: '/App/Components/TransactionMonitoring/ControlManagement/EditControlModal/editControlModal.tmpl.html',
            controller: editControlModalController,
            controllerAs: 'ecmvm',
            backdrop: 'static',
            scope: $scope,
            size: 'lg',
            resolve: {
                control: () => control
            }
        });

        await openModal.result;
        vm.controlManagementGridDataSource.read();
    }

    async function openGlobalConditionsModal() {

        let openModal = $uibModal.open({
            templateUrl: '/App/Components/TransactionMonitoring/ControlManagement/GlobalConditionsModal/globalConditionsModal.tmpl.html',
            controller: globalConditionsModalController,
            controllerAs: 'gcmvm',
            backdrop: 'static',
            scope: $scope
        });

        await openModal.result;
        vm.controlManagementGridDataSource.read();
    }

    async function runNow(control) {
        vm.disableButton = true;

        try {
            await controlManagementDataService.onDemandControl(control.Id);
            vm.controlManagementGridDataSource.read();
            vm.disableButton = false;
        } catch {
            vm.disableButton = false;
        }
    }
}