import createSpinner from "../../../../Shared/Functions/createSpinner";
import { customAutoCompleteFilter, customBooleanFilter, standardGridPageSize } from "../../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, simulationId, detailedReportType, roleType, helperService, sapSdSimAnalysisDataService, FileSaver) {
    const vm = this;
    vm.detailedReportType = detailedReportType;

    vm.dismiss = dismiss;
    vm.exportDetailedReport = exportDetailedReport;

    activate();

    function activate() {
        setupDetailedReportGrid();
    }

    function setupDetailedReportGrid() {
        vm.reportDetails = getReportRouteAndColumnDefinitions(detailedReportType);

        vm.detailedReportGridOptions = helperService.setMainGridOptions(vm.reportDetails.columnDefinitions, null);

        vm.detailedReportGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    let response = await sapSdSimAnalysisDataService.getRiskAnalysisReportStatistics(simulationId, vm.reportDetails.routeString);
                    options.success(response.data);
                }
            }
        });
    }

    function getReportRouteAndColumnDefinitions(reportType) {
        let reportDetails = {
            columnDefinitions: [],
            routeString: ''
        }, statusColumn = { title: "Status", field: "AccessStatus", width: 125, filterable: customAutoCompleteFilter, template: `<span set-style="#:AccessStatus === 'Added' || AccessStatus === 'Add' ? 'bafcd4' : AccessStatus === 'Removed' ? 'ffd5d6' : AccessStatus === 'Existed' || AccessStatus === 'Existing' ? 'FCF8E3' : null#">{{dataItem.AccessStatus}}</span>` };

        switch (reportType) {
            case 'roleRiskDetailed':
                reportDetails.columnDefinitions = [
                    { title: "Product", field: "ProductName", filterable: customAutoCompleteFilter, width: 80 },
                    { title: "Role Id", field: "RoleId", filterable: customAutoCompleteFilter, width: 100 },
                    { title: "Role Description", field: "RoleDescription", filterable: customAutoCompleteFilter, width: 130 },
                    { title: "Ruleset", field: "RulesetName", filterable: customAutoCompleteFilter, width: 130 },
                    { title: "Risk Id", field: "RiskId", filterable: customAutoCompleteFilter, width: 80 },
                    { title: "Risk Name", field: "RiskName", filterable: customAutoCompleteFilter, width: 120 },
                    { title: "Risk Description", field: "RiskDescription", filterable: customAutoCompleteFilter, width: 240 },
                    { title: "Risk Type", field: "RiskType", filterable: customAutoCompleteFilter, width: 120 },
                    { title: "Business Process", field: "BusinessProcess", filterable: customAutoCompleteFilter, width: 120 },
                    { title: "Business Process Group", field: "BusinessProcessGroup", filterable: customAutoCompleteFilter, width: 120 },
                    { title: "Security Object Name", field: "SecurityObjectName", filterable: customAutoCompleteFilter, width: 120 },
                    { title: "Security Object Type Name", field: "SecurityObjectTypeName", filterable: customAutoCompleteFilter, width: 85 },
                    { title: "Authorization", field: "Authorization", filterable: customAutoCompleteFilter, width: 100 },
                    { title: "Field", field: "Field", filterable: customAutoCompleteFilter, width: 80 },
                    { title: "Low", field: "Low", filterable: customAutoCompleteFilter, width: 80 },
                    { title: "High", field: "High", filterable: customAutoCompleteFilter, width: 80 }
                ];
                vm.reportTitle = 'Role Risk Detailed';
                break;
            case 'compositeRoleRiskDetailed':
                reportDetails.columnDefinitions = [
                    { title: "Product", field: "ProductName", filterable: customAutoCompleteFilter, width: 80 },
                    { title: "Role Id", field: "RoleId", filterable: customAutoCompleteFilter, width: 100 },
                    { title: "Role Description", field: "RoleDescription", filterable: customAutoCompleteFilter, width: 240 },
                    { title: "Single Role", field: "SingleRole", filterable: customAutoCompleteFilter, width: 120 },
                    { title: "Single Role Description", field: "SingleRoleDescription", filterable: customAutoCompleteFilter, width: 240 },
                    { title: "Ruleset", field: "RulesetName", filterable: customAutoCompleteFilter, width: 130 },
                    { title: "Risk Id", field: "RiskId", filterable: customAutoCompleteFilter, width: 80 },
                    { title: "Risk Name", field: "RiskName", filterable: customAutoCompleteFilter, width: 120 },
                    { title: "Risk Description", field: "RiskDescription", filterable: customAutoCompleteFilter, width: 240 },
                    { title: "Risk Type", field: "RiskType", filterable: customAutoCompleteFilter, width: 120 },
                    { title: "Business Cycle", field: "BusinessCycle", filterable: customAutoCompleteFilter, width: 80 },
                    { title: "Policy", field: "Policy", filterable: customAutoCompleteFilter, width: 130 },
                    { title: "Risk Level", field: "RiskLevel", filterable: customAutoCompleteFilter, width: 80 },
                    { title: "Default Mitigation", field: "DefaultMitigation", filterable: customAutoCompleteFilter, width: 150 }
                ];
                vm.reportTitle = 'Composite Role Risk Detailed';
                break;
            case 'roleBpAccess':
                reportDetails.columnDefinitions = [
                    { field: "ObjectId", title: "Object Id", width: 180, filterable: customAutoCompleteFilter },
                    { field: "ObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                    { field: "IsAssigned", title: "Is Assigned", width: 180, filterable: customBooleanFilter },
                    { field: "ProcessId", title: "Business Process Id", width: 180, filterable: customBooleanFilter },
                    { field: "BusinessProcess", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                    { field: "GroupName", title: "Group Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RoleType", title: "Role Type", width: 180, filterable: customAutoCompleteFilter },
                    { field: "ProductName", title: "Product", width: 180, filterable: customAutoCompleteFilter },
                    { field: "ObjectLabel", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                    { field: "ObjectType", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                    { field: "ObjectTypeName", title: "Object Type Name", width: 180, filterable: customAutoCompleteFilter },
                    { field: "RulesetName", title: "Ruleset Name", width: 180, filterable: customAutoCompleteFilter },
                    { field: "Authorization", title: "Authorization", width: 180, filterable: customAutoCompleteFilter },
                    { field: "Field", title: "Field", width: 180, filterable: customAutoCompleteFilter },
                    { field: "RulesetLow", title: "Ruleset Low", width: 180, filterable: customAutoCompleteFilter },
                    { field: "RulesetHigh", title: "Ruleset High", width: 180, filterable: customAutoCompleteFilter },
                    { field: "TechnicalLow", title: "Technical Low", width: 180, filterable: customAutoCompleteFilter },
                    { field: "Low", title: "Low", width: 180, filterable: customAutoCompleteFilter },
                    { field: "TechnicalHigh", title: "Technical High", width: 180, filterable: customAutoCompleteFilter },
                    { field: "High", title: "High", width: 180, filterable: customAutoCompleteFilter },
                    { field: "AccessStatus", title: "Access Status", width: 180, filterable: customAutoCompleteFilter }
                ];
                if (roleType === 'single') {
                    reportDetails.columnDefinitions.unshift({ field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter });
                    reportDetails.routeString = 'rolebpdetailed';
                    vm.reportTitle = 'Role Business Process Detailed';
                } else {
                    reportDetails.columnDefinitions.unshift(
                        { field: "CompositeRoleName", title: "Composite Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SingleRoleName", title: "Single Role Name", width: 180, filterable: customAutoCompleteFilter });
                    reportDetails.routeString = 'compositerolebpdetailed';
                    vm.reportTitle = 'Composite Role Business Process Detailed';
                }
                break;
            case 'userBpAccess':
                reportDetails.columnDefinitions = [
                    { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                    { field: "UserGroup", title: "User Group", width: 180, filterable: customAutoCompleteFilter },
                    { field: "UserType", title: "User Type", width: 180, filterable: customAutoCompleteFilter },
                    { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                    { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                    { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "Type", title: "Type", width: 160, filterable: customAutoCompleteFilter },
                    { field: "Name", title: "Name", width: 160, filterable: customAutoCompleteFilter },
                    { field: "ObjectId", title: "Object ID", width: 180, filterable: customAutoCompleteFilter },
                    { field: "ObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                    { field: "ObjectType", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                    { field: "Authorization", title: "Authorization", width: 130, filterable: customAutoCompleteFilter },
                    { field: "Field", title: "Field", width: 130, filterable: customAutoCompleteFilter },
                    { field: "RulesetLow", title: "Ruleset Low", width: 130, filterable: customAutoCompleteFilter },
                    { field: "RulesetHigh", title: "Ruleset High", width: 130, filterable: customAutoCompleteFilter },
                    { field: "TechnicalLow", title: "Technical Low", width: 130, filterable: customAutoCompleteFilter },
                    { field: "TechnicalHigh", title: "Technical High", width: 130, filterable: customAutoCompleteFilter },
                ]
                reportDetails.routeString = 'userbpaccessdetailed ';
                vm.reportTitle = 'User Business Process Detailed';
                break;
            case 'userRisk':
                reportDetails.columnDefinitions = [
                    { field: "UserId", title: "User ID", width: 150, filterable: customAutoCompleteFilter },
                    { field: "UserName", title: "User Name", width: 150, filterable: customAutoCompleteFilter },
                    { field: "UserGroup", title: "User Group", width: 150, filterable: customAutoCompleteFilter },
                    { field: "UserType", title: "User Type", width: 180, filterable: customAutoCompleteFilter },
                    { field: "RiskName", title: "Risk Name", width: 130, filterable: customAutoCompleteFilter },
                    { field: "BusinessProcess", title: "Business Process", width: 130, filterable: customAutoCompleteFilter },
                    { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                    { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "Role", title: "Role", width: 130, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectProduct", title: "Security Object Product", width: 80, filterable: customAutoCompleteFilter },
                    { field: "ObjectLabel", title: "Object Label", width: 130, filterable: customAutoCompleteFilter },
                    { field: "ObjectType", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                    { field: "Authorization", title: "Authorization", width: 130, filterable: customAutoCompleteFilter },
                    { field: "Field", title: "Field", width: 130, filterable: customAutoCompleteFilter },
                    { field: "RulesetLow", title: "Ruleset Low", width: 130, filterable: customAutoCompleteFilter },
                    { field: "RulesetHigh", title: "Ruleset High", width: 130, filterable: customAutoCompleteFilter },
                    { field: "TechnicalLow", title: "Technical Low", width: 130, filterable: customAutoCompleteFilter },
                    { field: "Low", title: "Low", width: 130, filterable: customAutoCompleteFilter },
                    { field: "TechnicalHigh", title: "Technical High", width: 130, filterable: customAutoCompleteFilter },
                    { field: "High", title: "High", width: 130, filterable: customAutoCompleteFilter },
                    { field: "RiskDescription", title: "Risk Description", width: 320, filterable: customAutoCompleteFilter },
                ];
                reportDetails.routeString = 'userriskdetailed';
                vm.reportTitle = 'User Risk Detailed';
                break;
        }
        if (!reportDetails.routeString) {
            reportDetails.routeString = reportType;
        }
        reportDetails.columnDefinitions.push(statusColumn);

        return reportDetails;
    }

    async function exportDetailedReport() {
        vm.exportPending = new createSpinner();

        try {
            let response = await sapSdSimAnalysisDataService.exportRiskAnalysisReportDetails(simulationId, vm.reportDetails.routeString);
            await helperService.successfulSaveButton(vm.exportPending);
            FileSaver.saveAs(response.data, `${vm.reportDetails.routeString}.xlsx`);
        } catch {
            vm.exportPending.loadingValue = false;
        }
    }

    function dismiss() {
        $uibModalInstance.dismiss();
    }
}