import { customAutoCompleteFilter, customBooleanFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";
import swal from "sweetalert2";
import { halSchemaKendoGrid } from "../../Shared/kendo.module";

export default /*@ngInject*/ function ($scope, dataService, $http, helperService, errorsDataService, riskRulesetService) {
    const DEFAULT_SAP_OBJECTID = 20211; 
    const DEFAULT_SAP_GRC_OBJECTID = 37130202; 
    $scope.helperService = helperService;

    var vm = $scope.vm = {};
    $scope.$on('CodeChecker', _ => $scope.vm.codeCheckerDataSource.read());

    vm.showDropdown = false;

    vm.rulesets = [
        { name: 'SAP GRC Ruleset', value: 2 }
    ];

    var columnsVM =
        [
            { field: "Product", title: "Product", filterable: customAutoCompleteFilter },
            { field: "TCode", title: "TCode", filterable: customAutoCompleteFilter },
            { field: "Object", title: "Object", filterable: customAutoCompleteFilter },
            { field: "ZTCode", title: "ZTCode", filterable: customAutoCompleteFilter },
            { field: "Function", title: "Function", filterable: customAutoCompleteFilter },
            { field: "BAPI", title: "BAPI", filterable: customBooleanFilter },
            { field: "Type", title: "Type", filterable: customBooleanFilter },
            { field: "ProcessName", title: "Business Process", filterable: customBooleanFilter },
            {
                field: "ExistsInBP", title: "Exists in Business Process",
                template: `<div>
                                {{ dataItem.ExistsInBP }}
                                <a ng-if=\'vm.selectedRuleset.value === 1 && vm.addLink(dataItem)\' style=\'margin-left: 0.75em;\' ng-click=\'vm.addObjectToBusinessProcess(dataItem)\'>
                                    <i class=\'fa fa-plus\' aria-hidden=\'true\'></i>
                                </a>
                           </div>`,
                filterable: customBooleanFilter
            }
        ];

    activate();

    function activate() {
        initializeRulesetInfo();
        setBpDropdownVisibility();
    }

    $scope.runCodeCheckerConfirmation = function () {
        swal(helperService.areYouSureParams('Run Code Checker', 'This process will analyze your custom programs and includes for TCodes in the ruleset.', 'Run')).then(function () {
            $http.post(apiUrl + 'api/assure/sap/SAP/codechecker/run')
                .then(function (response) {
                    return;
                }, function (response) {
                    helperService.showErrorMessage(response.data);

                });
        }, function (dismiss) {
            return;
        });
    };

    $scope.vm.addLink = function (dataItem) {
        if (dataItem.ZTCode && dataItem.ProcessName && dataItem.ExistsInBP === 'No') return true;
        else return false;
    };

    $scope.vm.addObjectToBusinessProcess = async function (dataItem) {

        let newGroupData = {
            BusinessProcessID: dataItem.ProcessID,
            BusinessProcessGroupID: dataItem.GroupID,
            Name: dataItem.ZTCode,
            Type: "And"
        };

        createNewGroup(newGroupData);
    };

    $scope.changeRuleset = function () {
        $scope.vm.codeCheckerDataSource.read();
    };

    async function setBpDropdownVisibility() {
        let products = await dataService.getProducts();
        vm.showDropdown = products.data.sum(product => product.ID === 'SapGrc');
    }

    async function createNewGroup(data) {
        try {
            await $http.post(apiUrl + 'api/assure/sap/SAP/codechecker/businessprocessgroups', data);
            $scope.vm.codeCheckerDataSource.read();
        } catch (error) { errorsDataService.catch(error); }
    }
   
    $scope.vm.codeCheckerGridOptions = helperService.setMainGridOptions(columnsVM, 600);

    async function initializeRulesetInfo() {
        let response = await riskRulesetService.getRiskRulesetList();
        if (response && response.data.length > 0) {
            let defaultRuleset = response.data.find(r => r.IsDefault === true);
            if (defaultRuleset) {
                vm.rulesets.unshift({
                    name: defaultRuleset == null ? 'Fastpath Default Ruleset' : defaultRuleset.Name,
                    value: 1
                });
            }
            else {
                vm.rulesets.unshift({
                    name: response.data[0].Name,
                    value: 1
                }); 
            }
        }
        else {
            //If we didn't get any rulesets we will default to old functionality with generic name
            vm.rulesets.unshift({
                name: 'Fastpath Default Ruleset',
                value: 1
            });
        }

        vm.selectedRuleset = vm.rulesets[0];

        $scope.vm.codeCheckerDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: halSchemaKendoGrid,
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            transport: {

                read: function (options) {

                    var sortsAndFilters = helperService.prepareSortsAndFilters(options);
                    let objectId = vm.selectedRuleset.value === 1 ? DEFAULT_SAP_OBJECTID : DEFAULT_SAP_GRC_OBJECTID;

                    $http({
                        method: 'POST',
                        url: apiUrl + 'api/core/reports/execute/' + objectId,
                        data: sortsAndFilters,
                        params: { 'pagesize': options.data.pageSize, 'page': options.data.page }
                    }).then(function (response) {

                        var data = response.data._embedded.reportData;
                        options.success(response.data);
                        vm.enableGrid = true;

                    }, function (response) {
                        options.error();
                    });
                }
            }
        });
    }
}