import { customAutoCompleteFilter, customBooleanFilter, customDatePickerFilter, customDatePickerNotNullableFilter, customNotStringEmptyFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../../Shared/kendo.module";
import editIncidentModalController from "./EditIncidentModal/editIncidentModal.controller";

export default /*@ngInject*/ function ($scope, $rootScope, $http, SavedReportSelectorService, $uibModal, objectIdDataService, CustomReportServices, $filter, reportsDataService, reportsService, helperService, controlManagementDataService, incidentManagementDataService) {

    const vm = this;

    $scope.$on('kendoWidgetCreated', function () {
        if (vm.grid) {
            vm.gridReady = true;
            runSavedReport();
        }
    });

    vm.helperService = helperService;

    vm.runSavedReport = runSavedReport;
    vm.populateCriteriaModel = populateCriteriaModel;
    vm.controlSelectedChanged = controlSelectedChanged;
    vm.onReadGrid = onReadGrid;
    vm.onClearFilters = onClearFilters;
    vm.onApplyFilter = onApplyFilter;
    vm.deleteFilter = deleteFilter;
    vm.generateGridWithSelectedValues = generateGridWithSelectedValues;
    vm.openEditIncidentModal = openEditIncidentModal;

    // --- FOR ADVANCED FILTERING ---
    vm.filterCriteria = {};
    vm.filterCriteria.query = [];
    // ---

    async function activate() {
        if ($rootScope.loadedSavedReport) {
            vm.savedReportFilters = $rootScope.loadedSavedReport.ReportDefinition.Filters;
        }

        // Get ObjectId and reportName for layouts and tabs
        vm.ObjectId = await objectIdDataService.getObjectID();
        let securityObject = helperService.getSecurityObjectDetailsFromObjectId(vm.ObjectId);
        vm.reportName = securityObject.Name;

        vm.mainGridOptions = helperService.setMainGridOptions(null, 600);
        vm.mainGridOptions.autoBind = false;

        getControls();
    }

    activate();

    async function runSavedReport() {

        if (vm.gridReady && $rootScope.loadedSavedReport && vm.controlDataSource && vm.periodDataSource && vm.filterCriteria.fields) {
            // --- FOR ADVANCED FILTERING ---
            vm.filterCriteria.query = reportsService.getSavedReportFilters();
            // clear out ImportId and ControlId filters because they are represented by dropdowns
            vm.filterCriteria.query = vm.filterCriteria.query.filter(query => query.FieldName !== 'ImportId' && query.FieldName !== 'ControlId');
            vm.filterCriteria.needsUpdate = true;
            // ---
            generateGridWithSelectedValues();
        } else if (vm.gridReady && !$rootScope.loadedSavedReport && vm.controlDataSource && vm.periodDataSource && vm.filterCriteria.fields) {
            // We want the necessary report details available to be able to use the report toolbar without running the report
            populateCriteriaModel();
        }
    }

    function setupMetaDataColumns(metadataColumns) {
        metadataColumns = $filter('orderBy')(vm.reportMetadata.Columns, 'Ordinal');
        metadataColumns = metadataColumns.filter(d => !d.FieldName.includes('_hidden_'));

        // Omit these columns from the UI completely to prevent user from accessing and causing errors
        metadataColumns = metadataColumns.filter(column => column.FieldName !== 'StatusId' && column.FieldName !== 'StatusUpdatedById');

        metadataColumns.forEach(function (column) {
            if (column.DataType === 'DateTime') {
                column.DataType = 'dateTime';
            } else if (column.DataType === 'Date') {
                column.DataType = 'date';
            } else if (column.DataType === 'Time') {
                column.DataType = 'time';
            } else if (column.DataType === 'Boolean') {
                column.DataType = 'boolean';
            } else if (column.DataType === 'String') {
                column.DataType = 'string';
            } else if (column.DataType === 'Number') {
                column.DataType = 'number';
            }
        });

        return metadataColumns;
    }

    async function setupGridColumns(metaDataColumns) {
        vm.gridColumns = [];
        metaDataColumns = $filter('orderBy')(metaDataColumns, 'Ordinal');
        metaDataColumns.forEach(column => {
            // Ensure column display name includes a space (filter dropdown options match column titles)
            column.DisplayName = !column.DisplayName ? helperService.headerStringTransformer(column.FieldName) : helperService.headerStringTransformer(column.DisplayName);

            // Ensure IsVisible property is properly set for unnecessary columns
            if (column.FieldName === 'ControlId' || column.FieldName === 'ImportId') {
                column.IsVisible = false;
            }

            let baseColumn = { "field": column.FieldName, "title": !column.DisplayName ? helperService.headerStringTransformer(column.FieldName) : column.DisplayName, filterable: customAutoCompleteFilter, hidden: !column.IsVisible, ordinal: column.Ordinal };

            if (column.DataType === 'dateTime' || column.DataType === 'date' || column.DataType === 'time') {
                baseColumn.filterable = column.IsNullable ? customDatePickerFilter : customDatePickerNotNullableFilter;
                baseColumn.template = column.DataType === 'date' ? "<span>{{dataItem." + column.FieldName + " | date:'M/d/yy'}}</span>" : "<span>{{dataItem." + column.FieldName + " | date:'short'}}</span>";
            }
            if (column.DataType === 'boolean') {
                baseColumn.filterable = customBooleanFilter;
            }
            if (column.DataType === 'number') {
                baseColumn.filterable = customAutoCompleteFilter;
            }
            if (column.DataType === 'string') {
                // prevents kendo from parsing any code found in the string
                baseColumn.template = "<span>{{dataItem." + column.FieldName + "}}</span>";
            }
            if (column.DataType !== 'string' && column.IsNullable) {
                baseColumn.filterable = customNotStringEmptyFilter;
            }

            vm.gridColumns.push(baseColumn);

        });

        // Add edit column
        vm.gridColumns.unshift({ "field": 'Edit', "title": 'Edit', "template": `<a ng-click="imvm.openEditIncidentModal(dataItem)"><i class="fa fa-edit text-warning m-r-xs"></i>Edit</a>`, filterable: false, ordinal: 0 });
        return vm.gridColumns;
    }

    async function createIncidentManagementGrid() {

        // Don't allow filtering and sorting yet because they will force the report to run
        if (!$rootScope.loadedSavedReport) {
            vm.mainGridOptions.sortable = false;

            vm.mainGridOptions.filterable = false;
        }

        vm.gridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: angular.extend(halSchemaKendoGrid, {
                model: {
                    fields: determineTypeOfField(vm.reportMetadata.Columns)
                }
            }),
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: async function (options) {
                    delete $rootScope.loadedSavedReport;
                    let data = {};
                    vm.sortsAndFilters = helperService.prepareSortsAndFilters(options);

                    vm.sortsAndFilters.Filters.push({
                        "FieldName": 'ImportId',
                        "Operator": 'Equals',
                        "Value": vm.selectedPeriod.Id
                    });

                    vm.sortsAndFilters.Filters.push({
                        "FieldName": 'ControlId',
                        "Operator": 'Equals',
                        "Value": vm.selectedControl.Id
                    });

                    data = vm.sortsAndFilters;

                    // --- FOR ADVANCED FILTERING ---
                    if (vm.filterCriteria.query) {
                        data.Filters = data.Filters.concat(vm.filterCriteria.query);
                    }
                    // ---

                    // if tabs are on, save report info for the tab
                    if ($rootScope.tabs.length > 0) {
                        reportsService.saveTabReportInfo(vm);
                    }

                    let params = { 'pagesize': options.data.pageSize, 'page': options.data.page };

                    try {
                        let response = await $http.post(`${apiUrl}api/core/reports/${vm.reportId}/execute`, data, { params: params });
                        data = response.data._embedded.reportData;
                        options.success(response.data);
                        vm.enableGrid = true;
                    } catch {
                        return;
                    }

                }
            }
        });

        if (vm.reportId) {
            setLayout();
        }

        return vm.gridDataSource;
    }

    function determineTypeOfField(columnsWithType) {
        var fields = {};
        columnsWithType.forEach(function (column) {
            if (column.DataType === 'date' || column.DataType === 'time' || column.DataType === 'dateTime') {
                fields[column.FieldName] = { type: 'date' };
            } else {
                fields[column.FieldName] = { type: column.DataType };
            }
        });
        return fields;
    }


    async function getControls() {
        vm.controlsLoading = true;
        let response = await controlManagementDataService.getListOfControls();
        response.data = $filter('filter')(response.data, { Enabled: true });
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.controlDataSource = response.data;
        vm.controlsLoading = false;

        if (vm.savedReportFilters) {
            vm.selectedControl = vm.controlDataSource.find(c => c.Id === vm.savedReportFilters.find(f => f.FieldName === 'ControlId').Value);
        }

        if (vm.selectedControl) {
            vm.reportId = vm.selectedControl.ReportId;
            await controlSelectedChanged();
        }

        return vm.controlDataSource;
    }

    async function getPeriods() {
        vm.periodsLoading = true;
        vm.reportId = vm.selectedControl.ReportId;

        let response = await incidentManagementDataService.getIncidentLogsByControlId(vm.selectedControl.Id);
        response.data = $filter('orderBy')(response.data, 'StartDate', true);
        vm.periodDataSource = response.data;
        vm.periodsLoading = false;

        if (vm.savedReportFilters) {
            vm.selectedPeriod = vm.periodDataSource.find(period => period.Id === vm.savedReportFilters.find(f => f.FieldName === 'ImportId').Value);
        }

        return vm.periodDataSource;
    }

    async function controlSelectedChanged() {
        try {
            if (vm.selectedControl) {
                await getPeriods();

                populateCriteriaModel();

                if (vm.periodDataSource.length > 0) {
                    vm.noIncidentLogs = false;
                    await getReportMetadata();
                    await setupGridColumns(vm.reportMetadata.Columns);
                    await createIncidentManagementGrid();
                    await runSavedReport();
                } else {
                    vm.noIncidentLogs = true;
                }
            }
        } catch {
            return;
        }
    }

    function populateCriteriaModel() {
        // do not continue if the proper criteria are not loaded
        if (vm.selectedControl === undefined || vm.selectedPeriod === undefined) {
            return;
        }

        let criteria = [];


        vm.reportDetailsModel = criteria;

        // if tabs are on, save report info for the tab
        if ($rootScope.tabs.length > 0) {
            reportsService.saveTabReportInfo(vm);
        }
    }

    function onApplyFilter(selectedFilter) {
        const currentOptions = vm.grid.getOptions();

        if (selectedFilter && selectedFilter.Filters && selectedFilter.Filters.length > 0) {
            currentOptions.dataSource.filter = helperService.convertFiltersForGrid(_, selectedFilter.Filters);
            try {
                vm.grid.dataSource._filter = currentOptions.dataSource.filter;
                vm.grid.dataSource.read();
            }
            catch (err) {
                vm.gridDataSource.filter({});
            }
        }
    }

    function onReadGrid() {
        vm.gridDataSource.read();
    }

    function onClearFilters() {
        vm.gridDataSource.filter({});
    }


    function generateGridWithSelectedValues() {

        if (!$rootScope.loadedSavedReport) {
            vm.grid.dataSource._filter = null;
            vm.grid.dataSource._sort = null;
        }

        populateCriteriaModel();
        vm.hideExportOptionsFromSubmit = true;

        // Add back the sorting and filtering options if we are running the report for the first time
        if (!$rootScope.loadedSavedReport) {
            vm.grid.setOptions({
                filterable: {
                    mode: "row"
                },
                sortable: true
            });
        }


        SavedReportSelectorService.queryGridWithOptions($rootScope.loadedSavedReport, vm.grid, true);
    }

    function deleteFilter(id) {
        CustomReportServices.deleteFilterFilterManager(vm, id);
    }

    function setLayout() {
        if (!$rootScope.loadedSavedReport) {
            CustomReportServices.loadSavedGridLayout(vm);
        }
    }



    // --- FOR ADVANCED FILTERING ---
    async function getReportMetadata() {
        if (vm.reportId) {
            let response = await reportsDataService.getReportMetadata(vm.reportId);
            vm.reportMetadata = response.data._embedded.reportData;
            vm.reportMetadata.Columns = setupMetaDataColumns(vm.reportMetadata.Columns);
            vm.reportMetadata.Columns = $filter('orderBy')(vm.reportMetadata.Columns, 'DisplayName');
            vm.filterCriteria.fields = reportsService.getFields(vm.reportMetadata.Columns);
            vm.filterCriteria.fields.forEach(field => field.displayName = $filter('insertSpaceBetweenCapitalWords')(field.displayName));
            return vm.reportMetadata;
        }
    }
    // ---

    async function openEditIncidentModal(incident) {

        let openModal = $uibModal.open({
            templateUrl: '/App/Components/TransactionMonitoring/Incidents/EditIncidentModal/editIncidentModal.tmpl.html',
            controller: editIncidentModalController,
            controllerAs: 'eimvm',
            backdrop: 'static',
            scope: $scope,
            resolve: {
                incident: () => incident
            }
        });

        await openModal.result;
        vm.gridDataSource.read();
    }
}