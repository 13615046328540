import angular from 'angular';

// module imports
import pluginsModule from './plugins.module';
import apiClientsModule from './apiClients.module';
import productConnectionsModule from './productConnections.module';
import integrationsFactoriesModule from './integrations.factories.module';

export default angular
    .module('app.integrations', [integrationsFactoriesModule, pluginsModule, apiClientsModule, productConnectionsModule])
    .name;