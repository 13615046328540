import { standardGridPageSize, customAutoCompleteFilter, customDatePickerFilter, customBooleanFilter } from "../../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../../Shared/kendo.module";
import allChangesEditModalController from "./allChangesEditModal.controller";

export default /*@ngInject*/ function ($scope, $rootScope, objectIdDataService, SavedReportSelectorService, templatesDataService, userGroupsDataService, CustomReportServices, $filter, $uibModal, helperService, reportsDataService, reportsService, allChangesDataService) {

    const vm = this;

    vm.selectedTemplates = [];
    vm.helperService = helperService;

    // --- FOR ADVANCED FILTERING ---
    vm.filterCriteria = {};
    vm.filterCriteria.query = [];
    // ---

    vm.populateCriteriaModel = populateCriteriaModel;
    vm.generateGridWithSelectedValues = generateGridWithSelectedValues;
    vm.deselectSelectedTemplates = deselectSelectedTemplates;
    vm.deselectSelectedUserGroups = deselectSelectedUserGroups;
    vm.openEditModal = openEditModal;

    vm.onApplyFilter = onApplyFilter;
    vm.onReadGrid = onReadGrid;
    vm.onClearFilters = onClearFilters;

    $scope.$on('kendoWidgetCreated', function () {
        if (vm.grid) {
            vm.gridReady = true;
            runSavedReport();
        }
    });

    async function activate() {
        getTemplates();
        getUserGroups();
        await getObjectId();

        // --- Grid initialization ---
        setupAllChangesGrid();

        setColumns();
        runSavedReport();

        // --- FOR ADVANCED FILTERING ---
        await getReportMetadata();
        vm.filterCriteria.fields = reportsService.getFields(vm.reportMetadata.Columns);
        if ($rootScope.loadedSavedReport) {
            runSavedReport();
        }
        // ---    
    }

    activate();

    async function getObjectId() {
        let objectId = await objectIdDataService.getObjectID();

        let securityObject = await helperService.getSecurityObjectDetailsFromObjectId(objectId);
        vm.ObjectId = objectId;
        vm.reportName = securityObject.Name;
        vm.reportId = securityObject.ReportID;

        vm.filtersDataSource = CustomReportServices.setfiltersDataSource(objectId);
    }

    function runSavedReport() {
        if (vm.gridReady && $rootScope.loadedSavedReport && vm.templatesDataSource && vm.userGroupsDataSource && vm.filterCriteria.fields) {
            vm.filterCriteria.query = reportsService.getSavedReportFilters();
            vm.filterCriteria.dateQuery = reportsService.getSavedReportDateFilters();
            vm.filterCriteria.needsUpdate = true;
            generateGridWithSelectedValues();
        } else if (vm.gridReady && !$rootScope.loadedSavedReport && vm.templatesDataSource && vm.userGroupsDataSource && vm.filterCriteria.fields) {
            // We want the necessary report details available to be able to use the report toolbar without running the report
            populateCriteriaModel();
        }
    }

    function setupAllChangesGrid() {
        vm.mainGridOptions = helperService.setMainGridOptions(null, 600);
        vm.mainGridOptions.autoBind = false;
        vm.filterManagerGridOptions = CustomReportServices.setfilterManagerGridOptions();
        vm.filterDropdownOptions = helperService.getFilterDropdownOptions();

        vm.gridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: angular.extend(halSchemaKendoGrid, {
                model: {
                    fields: {
                        Date: { type: "date" },
                        SystemChange: { type: "boolean" }
                    }
                }
            }),
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: async function (options) {
                    delete $rootScope.loadedSavedReport;

                    let data = {
                        'TemplateIds': vm.templates,
                        'UserGroupIds': vm.userGroups
                    };
                    data['QueryInput'] = helperService.prepareSortsAndFilters(options);

                    const params = { 'pagesize': options.data.pageSize, 'page': options.data.page };

                    // --- FOR ADVANCED FILTERING ---
                    if (vm.filterCriteria.query) {
                        data['QueryInput'].Filters = data['QueryInput'].Filters.concat(vm.filterCriteria.query);
                        data['QueryInput'].DateFilters = vm.filterCriteria.dateQuery;
                    }
                    // ---

                    // if tabs are on, save report info for the tab
                    if ($rootScope.tabs.length > 0) {
                        reportsService.saveTabReportInfo(vm);
                    }


                    try {
                        let response = await allChangesDataService.allChangesReport(data, params);
                        options.success(response.data);
                        vm.enableGrid = true;
                    } catch (err) {
                        options.error(err);
                    }
                }
            }
        });
    }

    function populateCriteriaModel() {
        // do not continue if the proper criteria are not loaded
        if (vm.selectedTemplates === undefined || vm.selectedUserGroups === undefined) {
            return;
        }

        vm.templates = helperService.returnNullIfAllIsFound(vm.selectedTemplates.map(function (el) { return el.Id; }));
        vm.userGroups = helperService.returnNullIfAllIsFound(vm.selectedUserGroups.map(function (el) { return el.Id; }));

        var criteria = [];

        vm.selectedTemplates.forEach(function (row) {
            criteria.push(CustomReportServices.CreateCriteria("TemplateIds", 'TemplateId', row.Name, row.Id));
        });

        vm.selectedUserGroups.forEach(function (row) {
            criteria.push(CustomReportServices.CreateCriteria("UserGroupIds", 'UserGroupId', row.Name, row.Id));
        });

        vm.reportDetailsModel = criteria;

        vm.urlString = apiUrl + 'api/audittrail/ns/v2/places/allchanges';

        // if tabs are on, save report info for the tab
        if ($rootScope.tabs.length > 0) {
            reportsService.saveTabReportInfo(vm);
        }
    }

    function generateGridWithSelectedValues() {

        if (!$rootScope.loadedSavedReport) {
            vm.grid.dataSource._filter = null;
            vm.grid.dataSource._sort = null;
        }

        populateCriteriaModel();
        vm.hideExportOptionsFromSubmit = true;

        // Add back the sorting and filtering options if we are running the report for the first time
        if (!$rootScope.loadedSavedReport) {
            vm.grid.setOptions({
                filterable: {
                    mode: "row"
                },
                sortable: true
            });
        }

        SavedReportSelectorService.queryGridWithOptions($rootScope.loadedSavedReport, vm.grid, true);
    }

    function deselectSelectedTemplates() {

        if (vm.selectedTemplates && vm.selectedTemplates[vm.selectedTemplates.length - 1].Id !== 'AllObjectsInMultiSelect') {
            vm.selectedTemplates.forEach(function (row, i) {
                if (row.Id === 'AllObjectsInMultiSelect') {
                    vm.selectedTemplates.splice(i, 1);
                }
            });
        } else {
            vm.selectedTemplates = [{ "Name": "All Template Types", "Id": 'AllObjectsInMultiSelect' }];
        }
        populateCriteriaModel();
    }

    function deselectSelectedUserGroups() {

        if (vm.selectedUserGroups && vm.selectedUserGroups[vm.selectedUserGroups.length - 1].Id !== 'AllObjectsInMultiSelect') {
            vm.selectedUserGroups.forEach(function (row, i) {
                if (row.Id === 'AllObjectsInMultiSelect') {
                    vm.selectedUserGroups.splice(i, 1);
                }
            });
        } else {
            vm.selectedUserGroups = [{ "Name": "All User Groups", "Id": 'AllObjectsInMultiSelect' }];
        }
        populateCriteriaModel();
    }

    async function getTemplates() {
        vm.templateDropdownIsLoading = true;

        try {
            let response = await templatesDataService.getTemplates('NS');
            response.data = $filter('orderBy')(response.data, 'Name');

            const All = { "Name": "All Templates", "Id": 'AllObjectsInMultiSelect' };

            response.data.unshift(All);
            vm.templatesDataSource = response.data;
            vm.selectedTemplates = [All];
            vm.selectedTemplates = SavedReportSelectorService.checkSavedReportForRecordTypeMultiSelect($rootScope.loadedSavedReport, response.data, vm.selectedTemplates);
            runSavedReport();
            vm.templateDropdownIsLoading = false;
        } catch {
            vm.templateDropdownIsLoading = false;
        }
    }

    async function getUserGroups() {
        vm.userGroupDropdownIsLoading = true;

        try {
            let response = await userGroupsDataService.getUserGroups();
            response.data = $filter('orderBy')(response.data, 'Name');

            const AllUserGroups = { "Name": "All User Groups", "Id": 'AllObjectsInMultiSelect' };

            response.data.unshift(AllUserGroups);
            vm.userGroupsDataSource = response.data;
            vm.selectedUserGroups = [AllUserGroups];
            vm.selectedUserGroups = SavedReportSelectorService.checkSavedReportForRecordTypeMultiSelect($rootScope.loadedSavedReport, response.data, vm.selectedUserGroups);
            runSavedReport();
            vm.userGroupDropdownIsLoading = false;
        } catch {
            vm.userGroupDropdownIsLoading = false;
        }
    }

    let ticketTemplate = `<span ng-if='dataItem.TicketProvider && dataItem.TicketProvider !== "Custom"'>{{ dataItem.TicketProvider }} &nbsp; {{dataItem.TicketId}}</span>
                          <span ng-if='dataItem.TicketProvider && dataItem.TicketProvider === "Custom"'>{{ dataItem.TicketProvider }} &nbsp; <span>{{dataItem.TicketId}}</span></span>`;

    function setColumns() {
        vm.mainGridColumns = [
            { template: `<a ng-click="acvm.openEditModal(dataItem)"><i class="fa fa-edit text-warning m-r-xs"></i>Edit</a>`, "title": "Edit", width: 90 },
            { field: "TicketDisplayName", title: "Ticket", template: ticketTemplate, filterable: false, sortable: false },
            { field: "ChangeId", title: "Change Id", filterable: customAutoCompleteFilter },
            { field: "Date", title: "Date", template: "{{ dataItem.Date | date:'short'}}", filterable: customDatePickerFilter },
            { field: "ObjectName", title: "Object", filterable: customAutoCompleteFilter },
            { field: "SubObjectName", title: "Sub Object", filterable: customAutoCompleteFilter },
            { field: "FieldName", title: "Field", filterable: customAutoCompleteFilter },
            { field: "RecordId", title: "Record Id", filterable: customAutoCompleteFilter },
            { field: "RecordName", title: "Record Name", filterable: customAutoCompleteFilter },
            { field: "UserId", title: "User Id", filterable: customAutoCompleteFilter },
            { field: "UserName", title: "User Name", filterable: customAutoCompleteFilter },
            { field: "RoleId", title: "Role Id", filterable: customAutoCompleteFilter },
            { field: "RoleName", title: "Role Name", filterable: customAutoCompleteFilter },
            { field: "Context", title: "Context", filterable: customAutoCompleteFilter },
            { field: "Type", title: "Type", filterable: customAutoCompleteFilter },
            { field: "OldValue", title: "Old Value", filterable: customAutoCompleteFilter },
            { field: "NewValue", title: "New Value", filterable: customAutoCompleteFilter },
            { field: "Notes", title: "Notes", filterable: customAutoCompleteFilter },
            { field: "SystemChange", title: "System Change", filterable: customBooleanFilter }
        ];

        // Don't allow filtering and sorting yet because they will force the report to run
        if (!$rootScope.loadedSavedReport) {
            vm.mainGridOptions.sortable = false;

            vm.mainGridOptions.filterable = false;
        }

        setLayout();
    }

    function setLayout() {
        if (!$rootScope.loadedSavedReport) {
            CustomReportServices.loadSavedGridLayout(vm, null);
        }
    }

    function onApplyFilter(selectedFilter) {
        const currentOptions = vm.grid.getOptions();

        if (selectedFilter && selectedFilter.Filters && selectedFilter.Filters.length > 0) {
            currentOptions.dataSource.filter = helperService.convertFiltersForGrid(_, selectedFilter.Filters);
            try {
                vm.grid.dataSource._filter = currentOptions.dataSource.filter;
                vm.grid.dataSource.read();
            }
            catch (err) {
                vm.gridDataSource.filter({});
            }
        }
    }

    function onReadGrid() {
        vm.gridDataSource.read();
    }

    function onClearFilters() {
        vm.gridDataSource.filter({});
    }

    async function openEditModal(item) {
        let editModal = $uibModal.open({
            templateUrl: '/App/Components/NetSuiteAuditTrail/AllChanges/allChangesEditModal.tmpl.html',
            controller: allChangesEditModalController,
            controllerAs: 'acemvm',
            backdrop: 'static',
            resolve: {
                record: () => item
            }
        });

        try {
            await editModal.result;
            vm.gridDataSource.read();
        } catch {
            return;
        }
    }

    // --- FOR ADVANCED FILTERING ---
    async function getReportMetadata() {
        let response = await reportsDataService.getReportMetadata(vm.reportId);

        vm.reportMetadata = response.data._embedded.reportData;
        vm.reportMetadata.Columns = $filter('orderBy')(vm.reportMetadata.Columns, 'DisplayName');

        return vm.reportMetadata;
    }

}