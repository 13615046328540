import { customAutoCompleteFilter, standardGridPageSize } from "../../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, templatesDataService, helperService, $filter, template, object, fieldsDataSource) {

    var vm = this;
    vm.cancel = cancel;
    vm.fieldsDataSource = fieldsDataSource;

    vm.addFieldToObject = addFieldToObject;

    var availableGridColumns = [
        { title: "Name", field: "Name", filterable: customAutoCompleteFilter },
        { template: "<a ng-click='fmvm.addFieldToObject(dataItem)'><i class='fa fa-plus pull-right'></i></a>", title: " ", width: 20 }
    ];

    vm.availableFieldsGridOptions = helperService.setMainGridOptions(availableGridColumns, null);

    function activate() {
        configureFieldsGrid();
    }

    activate();

    function cancel() { $uibModalInstance.close(); }

    function configureFieldsGrid() {
        getAllFields()
            .then(_ => {
                vm.fieldsDataSource.forEach(function (field) {
                    vm.availableFields = vm.availableFields.filter(function (el) {
                        return el.Id !== field.FieldId;
                    });
                });
                vm.availableFieldsGridDataSource = new kendo.data.DataSource({
                    pageSize: standardGridPageSize,
                    transport: {
                        read: function (options) {
                            vm.availableFields = $filter('orderBy')(vm.availableFields, 'Name');
                            options.success(vm.availableFields);
                        }
                    }
                });
            });
    }

    function getAllFields() {
        return templatesDataService.getFieldsByObjectId(object.ObjectId)
            .then(response => {
                vm.availableFields = response;
                return vm.availableFields;
            });
    }

    function addFieldToObject(field) {
        const data = {
            TemplateObjectId: object.TemplateObjectId,
            FieldId: field.Id
        };

        templatesDataService.addFieldToObject(template.Id, object.TemplateObjectId, data)
            .then(_ => {
                vm.availableFields = vm.availableFields.filter(fld => fld.Id !== field.Id);
                vm.availableFieldsGridDataSource.read();
            })
            .catch(_ => {
                return;
            });
    }
}