import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../../Shared/kendo.module";

export default /*@ngInject*/ function ($http, $uibModalInstance, helperService, dataService, businessProcessDataService, businessProcess, bpGroup, groupObjects) {

    const vm = this;

    vm.addObjectToBpGroup = addObjectToBpGroup;
    vm.cancel = cancel;

    activate();

    async function activate() {
        vm.availableProducts = await getProducts();
        setupAvailableGrid();
        createAvailableObjectsDataSource();
    }

    function cancel() {
        $uibModalInstance.close();
    }

    async function getProducts() {
        const response = await dataService.getProducts();
        return response.data;
    }

    function setupAvailableGrid() {
        let availableGridColumns = [
            { title: "Security Object Label", field: "AltName", filterable: customAutoCompleteFilter },
            { title: "Security Object Name", field: "Name", filterable: customAutoCompleteFilter },
            { title: "Module", field: "ModuleName", filterable: customAutoCompleteFilter },
            { title: "Type", field: "SecurityObjectTypeName", filterable: customAutoCompleteFilter },
            { template: "<a ng-disabled='bpomvm.addingToSelectedGrid' ng-click='bpomvm.addObjectToBpGroup(dataItem)'><i class='fa fa-plus pull-right'></i></a>", title: " ", width: 20 }
        ];

        if (vm.availableProducts.length > 1) {
            availableGridColumns.unshift({ title: "Product", field: "ProductName", filterable: customAutoCompleteFilter });
        }

        vm.availableObjectsGridOptions = helperService.setMainGridOptions(availableGridColumns, null);
    }

    function createAvailableObjectsDataSource() {
        vm.availableObjectsGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: halSchemaKendoGrid,
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: async options => {
                    let data = helperService.prepareSortsAndFilters(options);
                    if (data.Filters.length > 0) {
                        let altNameFilterIndex = data.Filters.findIndex(filter => filter.FieldName === "AltName");
                        let nameFilterIndex = data.Filters.findIndex(filter => filter.FieldName === "Name");

                        if (altNameFilterIndex > -1) {
                            data.Filters[altNameFilterIndex].FieldName = "SecurityObjectAltName";
                        }
                        if (nameFilterIndex > -1) {
                            data.Filters[nameFilterIndex].FieldName = "SecurityObjectName";
                        }
                    }
                    if (data.Sorts.length > 0) {
                        let altNameSortIndex = data.Sorts.findIndex(sort => sort.FieldName === "AltName");
                        let nameSortIndex = data.Sorts.findIndex(sort => sort.FieldName === "Name");
                        if (altNameSortIndex > -1) {
                            data.Sorts[altNameSortIndex].FieldName = "SecurityObjectAltName";
                        }
                        if (nameSortIndex > -1) {
                            data.Sorts[nameSortIndex].FieldName = "SecurityObjectName";
                        }
                    }

                    let response = await $http({
                        method: 'POST',
                        url: apiUrl + 'api/universal/securityobjects',
                        data: data,
                        params: { 'pagesize': options.data.pageSize, 'page': options.data.page, 'productType': [bpGroup.ProductType] }
                    });
                    // filter out any objects that are already part of the group
                    response.data._embedded.reportData = response.data._embedded.reportData.reduce((newArray, object) => {
                        if (!groupObjects.some(groupObject => groupObject.Id === object.Id && groupObject.SecurityObjectTypeId === object.SecurityObjectTypeId && groupObject.ProductType === object.ProductType)) {
                            newArray.push(object);
                        }
                        return newArray;
                    }, []);
                    options.success(response.data);
                }
            }
        });
    }

    //Add Selected Object
    async function addObjectToBpGroup(dataItem) {
        vm.addingToSelectedGrid = true;
        kendo.ui.progress(vm.availableGrid.element, true);
        const data = {
            "BusinessProcessID": businessProcess.Id,
            "SecurityObjectID": dataItem.Id,
            "SecurityObjectName": dataItem.Name,
            "SecurityObjectTypeID": dataItem.SecurityObjectTypeId,
            "ProductType": dataItem.ProductType,
            "GroupID": bpGroup.Id
        };

        try {
            await businessProcessDataService.addObjectToBpGroup(data);
            groupObjects.push(dataItem);
            kendo.ui.progress(vm.availableGrid.element, false);
            await vm.availableObjectsGridDataSource.read();
            vm.addingToSelectedGrid = false;
        } catch (response) {
            vm.addingToSelectedGrid = false;
            kendo.ui.progress(vm.availableGrid.element, false);
        }
    };

}