import controller from "./apiClients.controller";

/* apiClients.component.js */

/**
 * @desc component for API Clients
 * @example <api-clients></api-clients>
 */

const apiClients = {
    controller: controller,
    controllerAs: 'apicvm',
    templateUrl: '/App/Components/ApiClients/apiClients.html'
};

export default apiClients;