import { customAutoCompleteFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";
import createSpinner from "../../Shared/Functions/createSpinner";
import swal from "sweetalert2";

export default /*@ngInject*/ function ($scope, $uibModal, objectIdDataService, $http, helperService, dataService) {

    $scope.shouldBeOpen = true;

    objectIdDataService.getObjectID().then(function (objectId) {
        $scope.ObjectId = objectId;

        var securityObject = helperService.getSecurityObjectDetailsFromObjectId($scope.ObjectId);
        $scope.pageInfo = securityObject;
        $scope.pageDescription = securityObject.HelpLink;
        $scope.reportName = securityObject.Name;

    });

    $scope.helperService = helperService;

    var vm = $scope.vm = {};

    $scope.vm.archive = {
        id: '',
        name: ''
    };

    $scope.rowArchive = {
        id: '',
        name: ''
    };

    var columnsFromVM = [
        { field: "Name", title: "Name", filterable: customAutoCompleteFilter },
        { template: "<button class=\"btn btn-secondary\" ng-disabled='restoreArchiveInProgress || applyArchiveInProgress' ng-click=\"restoreArchive(dataItem)\">Restore <div ng-show='restoreArchiveInProgress && restoreBeingApplied === dataItem.ID' class='sm-spinner-contain'><span us-spinner spinner-theme='smallBlack'></span></div></button>", "title": "Restore", width: 100 },
        { template: "<button class=\"btn btn-secondary\" ng-disabled='restoreArchiveInProgress || applyArchiveInProgress' ng-click=\"reapplyAutoMitigationsConfirm(dataItem)\">Apply <div ng-show='applyArchiveInProgress && archiveBeingApplied === dataItem.ID' class='sm-spinner-contain'><span us-spinner spinner-theme='smallBlack'></span></div></button>", "title": "Apply", width: 100 },
        { template: "<button class=\"btn btn-danger\" ng-disabled='restoreArchiveInProgress || applyArchiveInProgress' ng-click=\"deleteArchive(#=ID#)\">Delete</button>", "title": "Delete", width: 100 }
    ];

    //Archive Grid
    $scope.mainGridOptions = helperService.setMainGridOptions(columnsFromVM, 600);
    $scope.mainGridOptions.selectable = false;



    var gridDataSourceDefault = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        transport: {
            read: function (options) {
                $http.get(apiUrl + 'api/assure/archive').then(function (response) {
                    options.success(response.data);
                });
            }
        }
    });

    $scope.vm.gridDataSource = gridDataSourceDefault;

    //Update Archive
    $scope.restoreArchive = function (dataItem) {
        $scope.restoreBeingApplied = dataItem.ID;

        $scope.restoreArchiveInProgress = true;
        var id = dataItem.ID;
        var data = { ID: id };

        $http({
            method: 'PUT',
            url: apiUrl + 'api/assure/archive/' + id + '/restore',
            data: data

        }).then(function successCallback(response) {
            $scope.restoreArchiveInProgress = false;

            helperService.showConfirmationMessage("Success", "Archive Restored");
            resetNew();
        }, function errorCallback(response) {
            $scope.restoreArchiveInProgress = false;
            helperService.showErrorMessage(response.data);
        });

    };

    //Apply Archive
    function applyArchive(id, reapplyAutoMitigations, reapplyDefaultMitigations) {
        $scope.archiveBeingApplied = id;
        $scope.applyArchiveInProgress = true;
        var data = { ID: id, ReapplyAutoMitigations: reapplyAutoMitigations, ReapplyDefaultMitigations: reapplyDefaultMitigations };

        $http({
            method: 'PUT',
            url: apiUrl + 'api/assure/archive/' + id + '/apply',
            data: data

        }).then(function successCallback(response) {
            $scope.applyArchiveInProgress = false;
            helperService.showConfirmationMessage("Success", "Archive Applied");
            resetNew();
        }, function errorCallback(response) {
            $scope.applyArchiveInProgress = false;
            helperService.showErrorMessage(response.data);
        });

    }

    $scope.reapplyAutoMitigationsConfirm = async function (dataItem) {
        let productTypes = await dataService.getProductTypes()
        let showReApplyAutoMitigations = productTypes.data.some(p => p.Type === 'OR' || p.Type === 'PS' || p.Type === 'ORFC' || p.Type === 'SAP');

        let applyModal = $uibModal.open({
            templateUrl: '/App/Components/ManageArchives/applyArchiveModal.html',
            controller: applyArchiveModalController,
            controllerAs: 'aamvm',
            backdrop: 'static',
            resolve: {
                showReApplyAutoMitigations: () => showReApplyAutoMitigations
            }
        });

        try {
            let response = await applyModal.result;
            applyArchive(dataItem.ID, response.reapplyAutoMitigations, response.reapplyDefaultMitigations);
        } catch {
            return;
        }
    };

    //Delete Archive
    $scope.deleteArchive = function (dataItem) {

        var id = dataItem;

        $http({
            method: 'DELETE',
            url: apiUrl + 'api/assure/archive/' + id
        }).then(function successCallback(response) {

            //Remove item from datasource
            var datasourcedata = $scope.vm.gridDataSource.data();

            for (var i = 0; i < datasourcedata.length; i++) {
                if (datasourcedata[i].ID === id) {
                    $scope.vm.gridDataSource.remove(datasourcedata[i]);
                    break;
                }
            }

            resetNew();
        }, function errorCallback(response) {
            helperService.showErrorMessage(response.data);

        });

    };

    $scope.openNewArchiveModal = function () {

        $uibModal.open({
            templateUrl: '/App/Shared/Partials/NewArchiveModal.html',
            controller: newArchiveModalController,
            backdrop: 'static',
            scope: $scope
        });
    };

    function resetNew() {
        $scope.vm.archive.name = '';
    }

}

function newArchiveModalController($scope, $uibModalInstance, $http, $route, helperService) {

    $scope.cancel = function () {
        $uibModalInstance.close();
    };

    //Save Archive
    $scope.saveArchive = function (e, archiveName) {

        var isValid = true;
        if (archiveName === "") {
            isValid = false;
        }
        if (isValid) {
            var data = { Name: archiveName };

            $scope.pleaseWait = new createSpinner();

            $http({
                method: 'PUT',
                url: apiUrl + 'api/assure/archive/archive',
                data: data

            }).then(async function successCallback(response) {
                $scope.killModal = true;

                await helperService.successfulSaveButton($scope.pleaseWait).then(function (result) {
                    //ID is not being returned from API so reload is required
                    $route.reload();
                    $scope.cancel();
                });

            }, function errorCallback(response) {
                $scope.pleaseWait.loadingValue = false;
                helperService.showErrorMessage(response.data);
            });
        }
    };

}

function applyArchiveModalController($scope, $uibModalInstance, showReApplyAutoMitigations) {

    $scope.showReApplyAutoMitigations = showReApplyAutoMitigations;

    $scope.applyArchiveOptions = {
        reapplyAutoMitigations: true,
        reapplyDefaultMitigations: true
    }

    $scope.dismiss = function () {
        $uibModalInstance.dismiss();
    };

    //Save Archive
    $scope.applyArchive = function () {
        $uibModalInstance.close($scope.applyArchiveOptions);
    };

}